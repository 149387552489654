import { Navigation } from '@components/navigation';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import PackoutLogo from '@img/svg/svg--packout.svg';
import { useInView } from '@hooks/useInView';
import { ApiService } from '@core/services';
import { GetPackoutInspirationDataRequest, GetPackoutInspirationDataResponse, Method } from '@core/types/api';

export interface LazyImageProps {
  url: string;
}
const LazyImage: FunctionComponent<LazyImageProps> = ({ url }) => {
  const [ref, inView] = useInView<HTMLDivElement>({ threshold: 0.2 });
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <div className={`inner ${inView ? 'in-view' : ''}`} ref={ref}>
      {inView && <img className={`${loaded ? 'loaded' : ''}`} onLoad={() => setLoaded(true)} src={url} alt="Gallery" />}
    </div>
  );
};

const SIZE = ['large', 'small-top', 'small-bottom'];
const GetInspired: FunctionComponent = () => {
  const [tab, setTab] = useState<'stack' | 'wallstorage'>('stack');
  const [inspirationImages, setInspirationImages] = useState<string[]>([]);

  useEffect(() => {
    const getInspirationImages = async () => {
      const params: GetPackoutInspirationDataRequest = {
        tab,
      };

      const response = await ApiService.request<GetPackoutInspirationDataResponse>({
        method: Method.GET,
        slug: 'get-inspiration',
        params,
      });

      if (response !== undefined && response.images !== undefined) {
        setInspirationImages(response.images);
      }
    };

    getInspirationImages();
  }, [tab]);

  const slicedImages = useMemo(
    () =>
      inspirationImages.reduce<string[][]>((resultArray, item, i) => {
        const chunkIndex = Math.floor(i / 3);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []),
    [inspirationImages],
  );

  return (
    <>
      <Navigation />
      <main className="wrapper">
        <div className="bg">
          <img src={PackoutLogo} alt="Packout Logo" />
        </div>
        <div className="content">
          <div className="header">
            <h1>Inspiration Gallery</h1>
            <div className="gallery-tabs">
              <div className={`tab ${tab === 'stack' && 'active'}`} onClick={() => setTab('stack')}>
                Storage Stack
              </div>
              <div className={`tab ${tab === 'wallstorage' && 'active'}`} onClick={() => setTab('wallstorage')}>
                Wall Storage
              </div>
            </div>
          </div>
          {slicedImages.map((slice, i) => (
            <div
              key={`GetInspired_Gallery_${i}`}
              className={`gallery ${slice.length === 1 ? 'full-width' : ''} ${i % 2 === 1 ? 'flipped' : ''}`}
            >
              {slice.map((image, y) => (
                <div key={`GetInspired_Gallery_${i}_Image_${y}`} className={`item ${SIZE[y]}`}>
                  <LazyImage url={image} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </main>
    </>
  );
};

export default GetInspired;

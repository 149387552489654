import React from "react";

const PlayButton: React.FC = () => {
  return (
    <div className="play-button">
      <svg className="play-circle" viewBox="0 0 152 152">
        <circle
          className="play-circle-01"
          fill="none"
          stroke="#fff"
          strokeWidth="3"
          strokeDasharray="343 343"
          cx="76"
          cy="76"
          r="72.7"
        ></circle>
        <circle
          className="play-circle-02"
          fill="none"
          stroke="#fff"
          strokeWidth="3"
          strokeDasharray="309 309"
          cx="76"
          cy="76"
          r="65.5"
        ></circle>
      </svg>
      <div className="play-triangle"></div>
    </div>
  );
};

export default PlayButton;

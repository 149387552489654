import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SimpleBar from 'simplebar-react';

import { Cross, Packout } from '@components/icons';
import { ProductCard } from '@components/products';
import { Translate } from '@components/translations';
import { ga } from '@core/helpers/ga';
import { CustomIntroID } from '@core/introJs';
import { BuilderService, ProductService } from '@core/services';
import { Builder, Products, States } from '@core/types';
import { StackProduct } from '@core/types/products';
import usePackoutServer from '@hooks/usePackoutServer';
import { useToast } from '@hooks/useToast';
import { AppActionCreators } from '@redux/app';
import { InventoryActionCreators } from '@redux/inventory';
import { ProductNavigationStrategies } from '../../core/strategies/ProductNavigation.strategies';

interface IProps {
  app?: States.AppState;
  products?: States.ProductState;
  inventory?: States.InventoryState;
  addProduct: Function;
  closeNav: Function;
}

const ProductNavigation: FC<IProps> = ({ app, products, inventory, addProduct, closeNav }) => {
  const { packoutServer } = usePackoutServer();
  const [activeCategories, setActiveCategories] = useState<string[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    handleCategoryClick('packout.rollers');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!products || !app || products.isLoading || !inventory) {
    return null;
  }

  const handleCategoryClick = (codeName: string, collapseOthers: boolean = false) => {
    if (collapseOthers) {
      setActiveCategories([codeName]);
      return;
    }

    const index = activeCategories.indexOf(codeName);

    if (index > -1) {
      const newCategories = [...activeCategories];
      newCategories.splice(index, 1);
      setActiveCategories(newCategories);
      return;
    }

    setActiveCategories([...activeCategories, codeName]);
  };

  const navClassNames = classNames({
    'side-nav': true,
    active: app.navOpen,
  });

  const stackType = ProductService.getStackType(inventory.layers, products.categories);

  const mapProductCategory = (x: Products.ProductCategory<StackProduct>) => {
    const isActive = activeCategories.indexOf(x.codeName) > -1;

    const productsToRender = x.products.filter(x => {
      if (!packoutServer || !packoutServer.settings.products || packoutServer.settings.products.length === 0) {
        return true;
      }

      return packoutServer.settings.products.indexOf(x.articleNumber) > -1;
    });

    if (productsToRender.length === 0) {
      return null;
    }

    return (
      <div key={x.name} className={`nav-item ${isActive ? 'active' : ''}`}>
        <h2 className="nav-item__title" onClick={() => handleCategoryClick(x.codeName)}>
          {x.name}
        </h2>
        <div className="nav-item__children">
          <TransitionGroup>
            {isActive &&
              productsToRender
                .sort((a, b) => a.order - b.order)
                .map((p, index) => {
                  return (
                    <CSSTransition key={index} timeout={200} classNames="slide-in-left">
                      <ProductCard
                        model={p}
                        site={app.site}
                        showInfoPopup={true}
                        onDragEnd={() => {
                          if (p.productType === Products.Type.base) {
                            handleCategoryClick('packout.boxes', true);
                            addProduct(p, products.categories, undefined, packoutServer?.settings ? packoutServer?.settings.preserveSession : false);
                            ga('packoutstack', 'product-added', { articleNumber: p.articleNumber });
                            if (packoutServer) {
                              packoutServer.emit('product-added', p.articleNumber);
                            }
                          }

                          addToast(p.name, 'packout.productadded');
                        }}
                        onClick={position => {
                          if (p.productType === Products.Type.base) {
                            handleCategoryClick('packout.boxes', true);
                          }

                          addProduct(p, products.categories, position, packoutServer?.settings ? packoutServer?.settings.preserveSession : false);
                          ga('packoutstack', 'product-added', { articleNumber: p.articleNumber });
                          if (packoutServer) {
                            packoutServer.emit('product-added', p.articleNumber);
                          }
                          addToast(p.name, 'packout.productadded');
                        }}
                        disabledLayers={ProductNavigationStrategies.getDisabledLayers({
                          categories: products.categories,
                          layers: inventory.layers,
                          product: p,
                          stackType,
                        })}
                      />
                    </CSSTransition>
                  );
                })}
          </TransitionGroup>
        </div>
      </div>
    );
  };

  const productCategories = products.categories.sort((a, b) => a.order - b.order).map(x => mapProductCategory(x));

  return (
    <nav className={navClassNames}>
      <div className="side-nav__header">
        <div className="side-nav__logo">
          <Packout />
          <h4>
            <Translate resourceString={'packout.storage'} />
          </h4>
        </div>
        <div className="side-nav__close" onClick={() => closeNav()}>
          <span>
            <Translate resourceString="close" />
          </span>
          <Cross />
        </div>
      </div>
      <div className="side-nav__items" data-mobile-id={CustomIntroID.TwoMobile}>
        <SimpleBar style={{ maxHeight: '100%' }}>
          <div data-id={CustomIntroID.One} data-tablet-id={CustomIntroID.OneTablet}>
            {productCategories.slice(0, 1)}
          </div>
          <div className="" data-id={CustomIntroID.Two} data-tablet-id={CustomIntroID.TwoTablet}>
            {productCategories.slice(1, productCategories.length)}
          </div>
        </SimpleBar>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
  products: state.products,
  inventory: state.inventory,
});

const mapDispatchToProps = {
  addProduct: (
    product: Products.StackProduct,
    categories: Products.ProductCategory<StackProduct>[],
    position?: Builder.LayerPosition,
    preserveSession?: boolean | undefined,
  ) => InventoryActionCreators.addProduct(product, categories, undefined, position, preserveSession),
  closeNav: () => AppActionCreators.setNav(false),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductNavigation);

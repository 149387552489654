import React from 'react';

const Edit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15.701" width={16} height={16}>
      <g id="noun-edit-343422" transform="translate(-94.309 -29.96)">
        <path
          id="Path_18745"
          data-name="Path 18745"
          d="M110.169,496.19H95.31a.559.559,0,0,0-.557.557.568.568,0,0,0,.557.557h14.872a.559.559,0,0,0,.557-.557.571.571,0,0,0-.57-.557Z"
          transform="translate(-0.431 -451.642)"
        />
        <path
          id="Path_18746"
          data-name="Path 18746"
          d="M94.322,39.617l-.014,2.648a.575.575,0,0,0,.163.407.544.544,0,0,0,.394.163l2.635-.014a.544.544,0,0,0,.394-.163l9.1-9.1a.574.574,0,0,0,0-.8l-2.608-2.635a.574.574,0,0,0-.8,0l-1.82,1.833-7.28,7.266a.6.6,0,0,0-.163.394Zm9.67-8.3,1.834,1.834-1.032,1.032-1.834-1.834Zm-8.543,8.543,6.71-6.71,1.834,1.834-6.71,6.7-1.847.014Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export default Edit;

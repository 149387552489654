import { ProductHelper } from '@core/helpers';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { GridService } from '@core/services';
import { WallStorageBuilderProductCell, WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import useArrowKeys from '@hooks/useArrowKeys';
import classNames from 'classnames';
import React from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

export interface WallStorageBuilderCellProps {
  product: WallStorageBuilderProductCell;
  mountingPlates: WallStorageBuilderProductCell[];
}

const WallStorageBuilderCell: React.FC<WallStorageBuilderCellProps> = props => {
  const dispatch = useDispatch();
  const { activeMountingPlates, gridRows, gridColumns } = useSelector((x: RootState) => x.wallStorageBuilder.present);
  const otherMountingPlates = React.useMemo(() => props.mountingPlates.filter(x => x.cellGuid !== props.product.cellGuid), [
    props.mountingPlates,
    props.product.cellGuid,
  ]);

  const dispatchMoveAndUpdateBounds = (payload: { cellGuid: string; x: number; y: number }): void => {
    dispatch<WallStorageBuilderActionTypes>({
      type: 'WALL_STORAGE_BUILDER/MOVE_MOUNTING_PLATE',
      payload,
    });

    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
  };

  const canMove = (x: number, y: number): boolean => {
    if (!gridColumns || !gridRows) {
      return false;
    }

    let isEmpty = true;

    if (x <= 0 || y <= 0) {
      return false;
    }

    if (x + (props.product.productWidth - 1) > gridColumns) {
      return false;
    }

    if (y + (props.product.productHeight - 1) > gridRows) {
      return false;
    }

    const proposedPosition = GridService.createRectangleRaw(x, y, props.product.productWidth, props.product.productHeight);

    for (let index = 0; index < otherMountingPlates.length; index++) {
      const mountingPlate = otherMountingPlates[index];
      const mountingPlateRect = GridService.createRectangle(mountingPlate);

      if (GridService.rectanglesOverlap(proposedPosition, mountingPlateRect)) {
        isEmpty = false;
        break;
      }
    }

    return isEmpty;
  };

  useArrowKeys({
    onLeft: () => {
      if (activeMountingPlates.indexOf(props.product.cellGuid) === -1) {
        return;
      }

      const newX = props.product.x - 1;
      const newY = props.product.y;

      if (!canMove(newX, newY)) {
        return;
      }

      dispatchMoveAndUpdateBounds({
        cellGuid: props.product.cellGuid,
        x: newX,
        y: newY,
      });
    },
    onUp: () => {
      if (activeMountingPlates.indexOf(props.product.cellGuid) === -1) {
        return;
      }

      const newX = props.product.x;
      const newY = props.product.y - 1;

      if (!canMove(newX, newY)) {
        return;
      }

      dispatchMoveAndUpdateBounds({
        cellGuid: props.product.cellGuid,
        x: newX,
        y: newY,
      });
    },
    onRight: () => {
      if (activeMountingPlates.indexOf(props.product.cellGuid) === -1) {
        return;
      }

      const newX = props.product.x + 1;
      const newY = props.product.y;

      if (!canMove(newX, newY)) {
        return;
      }

      dispatchMoveAndUpdateBounds({
        cellGuid: props.product.cellGuid,
        x: newX,
        y: newY,
      });
    },
    onDown: () => {
      if (activeMountingPlates.indexOf(props.product.cellGuid) === -1) {
        return;
      }

      const newX = props.product.x;
      const newY = props.product.y + 1;

      if (!canMove(newX, newY)) {
        return;
      }

      dispatchMoveAndUpdateBounds({
        cellGuid: props.product.cellGuid,
        x: newX,
        y: newY,
      });
    },
  });

  const [collectedProps, drag, preview] = useDrag({
    item: { type: `${WallStorageBuilderType.MountingPlate}_MOVE`, object: props.product },
    begin: () => {
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_SELECTED_MOUNTING_PLATE', payload: [] });
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_HOVERING_PRODUCT', payload: props.product });
    },
    end: () => {
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_SELECTED_MOUNTING_PLATE', payload: [] });
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_HOVERING_PRODUCT', payload: null });
    },
  });

  const cellStyles: React.CSSProperties = {
    gridArea: `${props.product.y} / ${props.product.x} / ${props.product.y + props.product.productHeight} / ${props.product.x +
      props.product.productWidth}`,
    gridTemplateColumns: `repeat(${props.product.productWidth}, 1fr)`,
  };

  const cellClassNames = classNames({
    wsb__cell: true,
    'wsb__cell--product': true,
    [`wsb__cell--${props.product.productWidth}`]: true,
  });

  return (
    <div
      key={`cell-${props.product.articleNumber}/${props.product.x}/${props.product.y}`}
      ref={drag}
      data-key={`cell-${props.product.articleNumber}/${props.product.x}/${props.product.y}`}
      className={cellClassNames}
      style={cellStyles}
      onClick={() =>
        dispatch<WallStorageBuilderActionTypes>({
          type: 'WALL_STORAGE_BUILDER/SET_SELECTED_MOUNTING_PLATE',
          payload: activeMountingPlates.indexOf(props.product.cellGuid) > -1 ? [] : [props.product.cellGuid],
        })
      }
    >
      <DragPreviewImage
        connect={preview}
        src={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
      ></DragPreviewImage>

      {activeMountingPlates.indexOf(props.product.cellGuid) > -1 ? <div className="wsb__cell--overlay"></div> : null}
      <img src={ProductHelper.getProductUrl(props.product, 1000)} alt={props.product.name} />
    </div>
  );
};

export default WallStorageBuilderCell;
function transformHeight(productHeight: number): number {
  throw new Error('Function not implemented.');
}

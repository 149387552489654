import Cookies from 'js-cookie';
import noop from 'lodash/noop';
import { useEffect, useState } from 'react';

const useCookie = <T>(key: string, initialValue: T): [T | undefined, (value: T, options?: Cookies.CookieAttributes) => void] => {
  const [trigger, setTrigger] = useState<number>(-1);
  const [item, setInnerValue] = useState<T | undefined>(() => {
    const value = Cookies.get(key);
    if (!value) {
      return initialValue;
    }

    return JSON.parse(value);
  });

  const setValue = (value: T, options?: Cookies.CookieAttributes): void => {
    setInnerValue(value);
    setTrigger(new Date().getTime());
    Cookies.set(key, JSON.stringify(value), options);
  };

  useEffect(() => noop(), [trigger]);

  return [item, setValue];
};

export default useCookie;

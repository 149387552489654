import React from 'react';
import { t } from '../translations/index';

const pattern = /(\[(?<browser>.*)\])/;

const LegacyWarning = () => {
  const translatedText = t('packout.legacy.error');
  let renderText = '';

  const match = translatedText.match(pattern);

  if (!match || !match.groups || match.groups.browser === undefined) {
    renderText = translatedText;
  } else {
    renderText = translatedText.replace(
      pattern,
      `<a href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">${match.groups.browser}</a>`,
    );
  }

  return (
    <div className="legacy-warning">
      <div className="legacy-warning__bg"></div>
      <div className="legacy-warning__inner">
        <p dangerouslySetInnerHTML={{ __html: renderText }}></p>
      </div>
    </div>
  );
};

export default LegacyWarning;

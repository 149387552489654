import ApiService from '@core/services/ApiService';
import { Method } from '@core/types/api';
import { useEffect, useState } from 'react';

export interface CheckPageCultureItem {
  cultureCode: string;
  url: string;
}

interface CheckPageCultureRequest {
  cultureCode: string;
  nodeId: number;
  ipAddress?: string;
}

interface CheckPageCultureResponse {
  alternateCultures: CheckPageCultureItem[];
  translations: Record<string, Record<string, string>>;
}

async function getAlternateCultures(cultureCode: string, nodeId: number): Promise<CheckPageCultureResponse> {
  /**
   * ipAddress:
   * en-GB: 84.19.38.41
   * fr-FR: 62.210.203.105
   */

  const params: CheckPageCultureRequest = {
    cultureCode,
    nodeId,
  };

  const response = await ApiService.request<CheckPageCultureResponse>({
    controller: 'culture',
    slug: 'check-page-culture',
    method: Method.POST,
    params: { ...params },
  });

  return response;
}

const useCheckPageCulture = (cultureCode: string, nodeId: number): CheckPageCultureResponse => {
  const [alternateCultures, setAlternateCultures] = useState<CheckPageCultureResponse>({
    alternateCultures: [],
    translations: {},
  });

  useEffect(() => {
    doAsyncWork();

    async function doAsyncWork(): Promise<void> {
      const result = await getAlternateCultures(cultureCode, nodeId);
      setAlternateCultures(result);
    }
  }, []);

  return alternateCultures;
};

export default useCheckPageCulture;

import { ga } from '@core/helpers/ga';
import { BuilderService, ProductService } from '@core/services';
import { Builder, Products, States } from '@core/types';
import { StackProduct, WallStorageBuilderType } from '@core/types/products';
import usePackoutServer from '@hooks/usePackoutServer';
import { InventoryActionCreators } from '@redux/inventory';
import React, { FC, Fragment, useState } from 'react';
import { useDrop } from 'react-dnd';
import { connect } from 'react-redux';

interface IProps {
  app?: States.AppState;
  inventory?: States.InventoryState;
  products?: States.ProductState;
  addProduct: Function;
}

const DropSection = ({
  addProduct,
  app,
  inventory,
  products,
  position,
  className,
}: IProps & { position: Builder.LayerPosition; className?: string }) => {
  const [dropType, setDropType] = useState(Builder.DropType.none);
  const { packoutServer } = usePackoutServer();

  const canDrop = (item: any, canDropType: Builder.DropType) => {
    if (!inventory || !products || !app) {
      return false;
    }

    // Get the product being dropped
    const product: Products.StackProduct = item.object;

    const scopedLayer = BuilderService.getLayersByType(inventory.layers, position);

    const stackType = ProductService.getStackType(inventory.layers, products.categories);

    // get if we can drop
    const canCurrentlyDrop = BuilderService.canDrop(product, products.categories, scopedLayer, canDropType, position, stackType);

    // set the drop type
    setDropType(BuilderService.getDropType(product, scopedLayer));

    if (!canCurrentlyDrop) {
      return false;
    }

    return canCurrentlyDrop;
  };

  const [{ isHoveringRight }, rightDropRef] = useDrop({
    accept: [`${WallStorageBuilderType.Product}`],
    canDrop: item => canDrop(item, Builder.DropType.right),
    drop: (item: any, monitor) => {
      if (!inventory || !products) {
        return false;
      }

      const product: Products.StackProduct = item.object;

      addProduct(
        product,
        products.categories,
        Builder.AddType.right,
        app && position,
        packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
      );
      setDropType(Builder.DropType.none);
    },
    collect: monitor => ({
      isHoveringRight: monitor.isOver(),
    }),
  });

  const [{ isHoveringLeft }, leftDropRef] = useDrop({
    accept: [`${WallStorageBuilderType.Product}`],
    canDrop: item => canDrop(item, Builder.DropType.left),
    drop: (item: any, monitor) => {
      if (!inventory || !products) {
        return false;
      }

      const product: Products.StackProduct = item.object;

      addProduct(
        product,
        products.categories,
        Builder.AddType.left,
        app && position,
        packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
      );
      setDropType(Builder.DropType.none);
    },
    collect: monitor => ({
      isHoveringLeft: monitor.isOver(),
    }),
  });

  const [{ isHovering }, dropRef] = useDrop({
    accept: [`${WallStorageBuilderType.Product}`],
    canDrop: item => canDrop(item, Builder.DropType.full),
    drop: (item: any, monitor) => {
      if (!inventory || !products) {
        return false;
      }

      const product: Products.StackProduct = item.object;

      addProduct(
        product,
        products.categories,
        Builder.AddType.full,
        app && position,
        packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
      );
      ga('packoutstack', 'product-added', { articleNumber: product.articleNumber });
      if (packoutServer) {
        packoutServer.emit('product-added', product.articleNumber);
      }
      setDropType(Builder.DropType.none);
    },

    collect: monitor => ({
      isHovering: monitor.isOver(),
    }),
  });

  switch (dropType) {
    default:
    case Builder.DropType.full:
      return (
        <div className={className}>
          <div ref={dropRef} className={`builder-drop builder-drop--full ${isHovering ? 'hovering' : ''}`}>
            <div className="builder-drop__corner builder-drop__tl"></div>
            <div className="builder-drop__corner builder-drop__tr"></div>
            <div className="builder-drop__corner builder-drop__br"></div>
            <div className="builder-drop__corner builder-drop__bl"></div>

            <div className="builder-drop__middle builder-drop__tm"></div>
            <div className="builder-drop__middle builder-drop__rm"></div>
            <div className="builder-drop__middle builder-drop__bm"></div>
            <div className="builder-drop__middle builder-drop__lm"></div>
          </div>
        </div>
      );

    case Builder.DropType.both:
      return (
        <div className={className}>
          <div ref={leftDropRef} className={`builder-drop builder-drop--half-left ${isHoveringLeft ? 'hovering' : ''}`}>
            <div className="builder-drop__corner builder-drop__tl"></div>
            <div className="builder-drop__corner builder-drop__tr"></div>
            <div className="builder-drop__corner builder-drop__br"></div>
            <div className="builder-drop__corner builder-drop__bl"></div>
          </div>
          <div ref={rightDropRef} className={`builder-drop builder-drop--half-right ${isHoveringRight ? 'hovering' : ''}`}>
            <div className="builder-drop__corner builder-drop__tl"></div>
            <div className="builder-drop__corner builder-drop__tr"></div>
            <div className="builder-drop__corner builder-drop__br"></div>
            <div className="builder-drop__corner builder-drop__bl"></div>
          </div>
        </div>
      );

    case Builder.DropType.left:
      return (
        <div className={className}>
          <div ref={leftDropRef} className={`builder-drop builder-drop--half-left ${isHoveringLeft ? 'hovering' : ''}`}>
            <div className="builder-drop__corner builder-drop__tl"></div>
            <div className="builder-drop__corner builder-drop__tr"></div>
            <div className="builder-drop__corner builder-drop__br"></div>
            <div className="builder-drop__corner builder-drop__bl"></div>
          </div>
        </div>
      );

    case Builder.DropType.right:
      return (
        <div className={className}>
          <div ref={rightDropRef} className={`builder-drop builder-drop--half-right ${isHoveringRight ? 'hovering' : ''}`}>
            <div className="builder-drop__corner builder-drop__tl"></div>
            <div className="builder-drop__corner builder-drop__tr"></div>
            <div className="builder-drop__corner builder-drop__br"></div>
            <div className="builder-drop__corner builder-drop__bl"></div>
          </div>
        </div>
      );
  }
};

const Drop: FC<IProps> = props => {
  if (!props.inventory || !props.products) {
    return <></>;
  }

  const stackType = ProductService.getStackType(props.inventory.layers, props.products.categories);

  switch (stackType) {
    default:
    case Builder.StackType.default:
      return <DropSection {...props} position={Builder.LayerPosition.front} />;
    case Builder.StackType.double:
      return (
        <div className="builder-drop-layout">
          <DropSection {...props} position={Builder.LayerPosition.back} className="builder-section--top" />
          <DropSection {...props} position={Builder.LayerPosition.front} className="builder-section--bottom" />
        </div>
      );
    case Builder.StackType.layered:
      return (
        <div className="builder-drop-layout">
          <DropSection {...props} position={Builder.LayerPosition.top} className="builder-section--top" />
          <DropSection {...props} position={Builder.LayerPosition.front} className="builder-section--bottom" />
        </div>
      );
  }
};

const mapStateToProps = (state: States.RootState) => ({
  inventory: state.inventory,
  products: state.products,
  app: state.app,
});

const mapDispatchToProps = {
  addProduct: (
    product: Products.StackProduct,
    categories: Products.ProductCategory<StackProduct>[],
    addType: Builder.AddType,
    position?: Builder.LayerPosition,
    preserveSession?: boolean | undefined,
  ) => InventoryActionCreators.addProduct(product, categories, addType, position, preserveSession),
};

export default connect(mapStateToProps, mapDispatchToProps)(Drop);

import { isInIframe } from '@core/helpers/iframe';
import InventoryActionTypes from '@core/redux/inventory/actions';
import { TranslationActionTypes } from '@core/redux/translations/actions';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { RootState } from '@core/types/states';
import { PackoutSettings } from '@packout/api';
import PackoutServer from '@packout/api/src/PackoutServer';
import React, { createContext, FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface PackoutServerContextProps {
  packoutServer: PackoutServer | null;
  isReady: boolean;
}

const initialState: PackoutServerContextProps = {
  packoutServer: null,
  isReady: false,
};

const PackoutServerContext = createContext<PackoutServerContextProps>(initialState);

export const PackoutServerProvider: FunctionComponent = ({ children }) => {
  const [packoutServer, setPackoutServer] = React.useState<PackoutServer | null>(initialState.packoutServer);
  const [isReady, setIsReady] = React.useState<boolean>(initialState.isReady);
  const dispatch = useDispatch();

  const rootState = useSelector((x: RootState) => x);

  React.useEffect(() => {
    if (packoutServer === null && isInIframe() && window.location.search.indexOf('clientid') > -1) {
      const clientId = window.location.search.split('clientid=')[1];
      const server = new PackoutServer();
      server.clientId = clientId;
      setPackoutServer(server);
    }
  }, [packoutServer]);

  React.useEffect(() => {
    if (packoutServer === null || rootState.app.sites.length === 0) {
      return;
    }

    // packoutServer.emit('server-set-height', window.outerHeight);
    packoutServer.emit('server-sites', rootState.app.sites);
    packoutServer.emit('server-loaded');

    packoutServer.on('settings', (settings: PackoutSettings) => {
      packoutServer.settings = settings;

      if (!packoutServer.settings.preserveSession) {
        dispatch<InventoryActionTypes>({ type: 'INVENTORY/CLEAR_PRODUCTS', payload: {} });
        dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RESET' });
      }

      setPackoutServer(packoutServer);
      setIsReady(true);
    });

    packoutServer.on('translations', (translations: Record<string, string | undefined>) => {
      dispatch<TranslationActionTypes>({ type: 'TRANSLATIONS/MERGE_TRANSLATIONS', payload: translations });
    });

    packoutServer.on('loaded', () => {
      setIsReady(true);
    });
  }, [dispatch, packoutServer, rootState.app.sites]);

  return (
    <PackoutServerContext.Provider
      value={{
        packoutServer,
        isReady,
      }}
    >
      {children}
    </PackoutServerContext.Provider>
  );
};

const usePackoutServer = () => useContext(PackoutServerContext);

export default usePackoutServer;

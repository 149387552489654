import { BaseClient } from './BaseClient';
class PackoutServer extends BaseClient {
    constructor() {
        super();
        Object.defineProperty(this, "settings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.setRoot(window.parent);
    }
}
export default PackoutServer;

import { CultureRouter } from '@components/routing';
import { history } from '@core/helpers/history';
import { PackoutServerProvider } from '@hooks/usePackoutServer';
import { store } from '@redux/index';
import '@scss/main.scss';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <DndProvider backend={HTML5Backend}>
          <PackoutServerProvider>
            <CultureRouter />
          </PackoutServerProvider>
        </DndProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;

import usePackoutServer from './usePackoutServer';

const useIsMasterOrigin = () => {
  const { packoutServer } = usePackoutServer();
  const masterOrigins = ['packout.local', 'packout.milwaukeetool.eu'];

  return masterOrigins.includes(packoutServer?.settings?.domain ?? '');
};

export default useIsMasterOrigin;

import React, { FunctionComponent, useEffect } from 'react';
import { useToast } from '@hooks/useToast';
import { IToast } from './models';

const Toast: FunctionComponent<IToast> = ({ children, id }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, 1200);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeToast]);

  return <div className="toast">{children}</div>;
};

export default Toast;

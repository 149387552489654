import { States, Translations } from '@core/types';
import { FETCH_TRANSLATIONS, FETCH_TRANSLATIONS_ERROR, FETCH_TRANSLATIONS_SUCCESS, MERGE_TRANSLATIONS, TranslationActionTypes } from './actions';

const initialState: States.TranslationsState = {
  isLoading: false,
  loaded: false,
  values: [],
};

function translationReducer(state: States.TranslationsState = initialState, action: TranslationActionTypes): States.TranslationsState {
  switch (action.type) {
    case FETCH_TRANSLATIONS: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    case FETCH_TRANSLATIONS_SUCCESS: {
      const newState = { ...state };

      const values: Translations.ITranslation[] = [];

      Object.keys(action.payload).forEach(key => {
        values.push({
          key,
          value: action.payload[key],
        });
      });

      newState.values = values;
      newState.isLoading = false;

      newState.loaded = values.length > 0;

      return newState;
    }

    case FETCH_TRANSLATIONS_ERROR: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    case MERGE_TRANSLATIONS: {
      const newState = { ...state };

      const values: Translations.ITranslation[] = [...newState.values];

      Object.keys(action.payload).forEach(key => {
        const translatedValue = action.payload[key];

        if (translatedValue) {
          const existingIndex = values.findIndex(x => x.key === key);

          if (existingIndex > -1) {
            values[existingIndex].value = translatedValue;
          } else {
            if (translatedValue) {
              values.push({
                key,
                value: translatedValue,
              });
            }
          }
        }
      });

      newState.values = [...values];

      return newState;
    }

    default:
      return state;
  }
}

export default translationReducer;

import Footer from '@components/builder/Footer';
import { Loader } from '@components/shared';
import { CustomIntroID } from '@core/introJs';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { getGridColumns, getGridRows } from '@core/redux/wall-storage-builder/reducer';
import GridService, { Rectangle } from '@core/services/GridService';
import { WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import WallStorageBuilderCell from './WallStorageBuilderCell';
import WallStorageBuilderHeader from './WallStorageBuilderHeader';
import { transformPosition, transformWidth } from './WallStorageBuilderHelper';
import WallStorageBuilderMount from './WallStorageBuilderMount';
import WallStorageBuilderPlaceholder from './WallStorageBuilderPlaceholder';
import WallStorageBuilderProduct from './WallStorageBuilderProduct';
import WallStorageInfoPopup from './WallStorageInfoPopup';
import WallStorageRestartPopup from './WallStorageRestartPopup';

const WallStorageBuilder = () => {
  const {
    width,
    height,
    products,
    hoveringProduct,
    gridWidthUnit,
    gridHeightUnit,
    gridRows,
    gridColumns,
    isLoading,
    showBoundary,
    boundaries,
    isSubmitting,
    hasSubmitted,
  } = useSelector((x: RootState) => x.wallStorageBuilder.present);
  const dispatch = useDispatch();
  const mountingPlates = useMemo(() => products.filter(x => x.productType === WallStorageBuilderType.MountingPlate), [products]);
  const mountedProducts = useMemo(() => products.filter(x => x.productType === WallStorageBuilderType.Product), [products]);
  const gridRef = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);
  const cameraControlsRef = useRef<ReactZoomPanPinchRef>(null);
  const [doneInitialZoom, setDoneInitialZoom] = useState<boolean>(false);
  const { packoutServer } = usePackoutServer();

  const gridStyles: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };

  const builderStyles: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    gridTemplateColumns: `repeat(${gridColumns}, ${gridWidthUnit}px)`,
    gridTemplateRows: `repeat(${gridRows}, ${gridHeightUnit}px)`,
  };

  const renderHoveringPlaceholders = () => {
    if (!hoveringProduct || hoveringProduct.productType !== WallStorageBuilderType.MountingPlate || !gridRows || !gridColumns) {
      return;
    }

    return (
      <>
        {new Array(gridColumns).fill(null).map((i, x) => (
          <>
            {new Array(gridRows).fill(null).map((j, y) => {
              const xPosition = x + 1;
              const yPosition = y + 1;

              const productInSpace = mountingPlates.find(mp => {
                const r1: Rectangle = {
                  topLeft: { x: xPosition, y: yPosition },
                  bottomLeft: { x: xPosition, y: yPosition + hoveringProduct.productHeight },
                  topRight: { x: xPosition + hoveringProduct.productWidth, y: yPosition },
                  bottomRight: { x: xPosition + hoveringProduct.productWidth, y: yPosition + hoveringProduct.productHeight },
                };

                const r2: Rectangle = {
                  topLeft: { x: mp.x, y: mp.y },
                  bottomLeft: { x: mp.x, y: mp.y + mp.productHeight },
                  topRight: { x: mp.x + mp.productWidth, y: mp.y },
                  bottomRight: { x: mp.x + mp.productWidth, y: mp.y + mp.productHeight },
                };

                return GridService.rectanglesOverlap(r1, r2);
              });

              if (productInSpace) {
                return null;
              }

              return (
                <WallStorageBuilderPlaceholder
                  key={`placeholder-${x}/${y}`}
                  x={xPosition}
                  y={yPosition}
                  gridRows={gridRows}
                  gridColumns={gridColumns}
                />
              );
            })}
          </>
        ))}
      </>
    );
  };

  const builderClasses = classNames({
    builder: true,
    'builder--center': !!width && !!height && !!gridRows && !!gridColumns,
    'builder--full': !!packoutServer,
  });

  useEffect(() => {
    dispatch<WallStorageBuilderActionTypes>({
      type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS',
    });
    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
  }, [dispatch]);

  useEffect(() => {
    if (cameraControlsRef.current && !doneInitialZoom) {
      centerRef.current && cameraControlsRef.current.zoomToElement(centerRef.current);
      setDoneInitialZoom(true);
    }
  }, [centerRef, boundaries, doneInitialZoom]);

  // Show modal spinner when we are submitting
  if (isSubmitting) {
    return (
      <div className={`builder ${packoutServer ? 'builder--full' : ''}`}>
        <Loader showContainer={true} resourceString="packout.submitting"></Loader>
      </div>
    );
  }

  // Show success after submitting
  if (hasSubmitted) {
    return (
      <div className={`builder ${packoutServer ? 'builder--full' : ''}`}>
        <Loader showContainer={true} resourceString="packout.submitted"></Loader>
      </div>
    );
  }

  return (
    <div className={builderClasses} ref={gridRef}>
      <WallStorageBuilderHeader
        onZoomIn={() => (cameraControlsRef.current ? cameraControlsRef.current.zoomIn() : null)}
        onZoomOut={() => (cameraControlsRef.current ? cameraControlsRef.current.zoomOut() : null)}
      />
      <WallStorageRestartPopup />
      <WallStorageInfoPopup />

      {isLoading ? (
        <div className="wsb__loader">
          <Loader showContainer={true} resourceString="packout.loadingproducts" />
        </div>
      ) : null}

      <div
        className="builder__inner builder__inner--hidden"
        data-id={CustomIntroID.Three}
        data-tablet-id={CustomIntroID.ThreeTablet}
        data-mobile-id={CustomIntroID.ThreeMobile}
      >
        {!!width && !!height && !!gridRows && !!gridColumns && (
          <TransformWrapper
            ref={cameraControlsRef}
            minScale={0.1}
            limitToBounds={false}
            pinch={{ step: 0.1 }}
            doubleClick={{ disabled: true }}
            panning={{ excluded: ['wsb__cell--product', 'wsb__mount', 'wsb__product'] }}
            onZoomStop={(ref, ev) => {
              dispatch<WallStorageBuilderActionTypes>({
                type: 'WALL_STORAGE_BUILDER/SHOW_BOUNDARY',
                payload: ref.state.scale === 0.1,
              });

              centerRef.current && ref.zoomToElement(centerRef.current, ref.state.scale);
            }}
          >
            <TransformComponent>
              <div id="wsb__Wrapper" className="wsb__wrapper">
                <div id="wsb" className="wsb" style={gridStyles}>
                  <div className="wsb__builder" style={builderStyles} data-show-boundary={showBoundary}>
                    <div
                      className="wsb__center"
                      ref={centerRef}
                      style={
                        boundaries.highestX === -1
                          ? {
                              width: gridWidthUnit * 3,
                              height: gridHeightUnit * 3,
                              top: height / 2 - (gridHeightUnit * 3) / 2,
                              left: width / 2 - (gridWidthUnit * 3) / 2,
                            }
                          : {
                              width: boundaries.boundsX,
                              height: boundaries.boundsY,
                              top: (boundaries.lowestY - 1) * gridHeightUnit,
                              left: (boundaries.lowestX - 1) * gridWidthUnit,
                            }
                      }
                    />
                    {boundaries.highestX > -1 && (
                      <>
                        <div
                          className="wsb__bounds wsb__bounds-left"
                          style={{
                            left: (boundaries.lowestX - 1) * gridWidthUnit - 50,
                            top: (boundaries.lowestY - 1) * gridHeightUnit,
                            width: 2,
                            height: boundaries.boundsY,
                          }}
                        >
                          <span>{boundaries.boundsY}.00mm</span>
                        </div>
                        <div
                          className="wsb__bounds wsb__bounds-bottom"
                          style={{
                            left: (boundaries.lowestX - 1) * gridWidthUnit,
                            top: boundaries.highestY * gridHeightUnit + 35,
                            width: boundaries.boundsX,
                            height: 2,
                          }}
                        >
                          <span>{boundaries.boundsX}.00mm</span>
                        </div>
                      </>
                    )}
                    {mountingPlates.map((x, index) => (
                      <WallStorageBuilderCell key={`WSB_${index}_${x.cellGuid}_${x.articleNumber}`} product={x} mountingPlates={mountingPlates} />
                    ))}
                    <div
                      className="wsb__mounts"
                      style={{
                        gridArea: `1 / 1 / ${gridRows + 1} / ${gridColumns + 1}`,
                        gridTemplateColumns: getGridTemplateColumns(gridColumns),
                        gridTemplateRows: getGridTemplateRows(gridRows),
                      }}
                    >
                      {hoveringProduct &&
                        mountingPlates.map((mountingPlate, productIndex) => (
                          <>
                            {new Array(mountingPlate.mountsX).fill(null).map((i, xPosition) => (
                              <>
                                {new Array(mountingPlate.mountsY).fill(null).map((j, yPosition) => {
                                  const { x, y } = transformPosition(xPosition + 1, yPosition + 1, mountingPlate.x, mountingPlate.y);
                                  const relativeX = xPosition + 1;
                                  const relativeY = yPosition + 1;

                                  const productInSpace = mountedProducts.find(mp => {
                                    const r1: Rectangle = {
                                      topLeft: { x, y },
                                      bottomLeft: { x, y: y + hoveringProduct.productHeight },
                                      topRight: { x: x + transformWidth(hoveringProduct.productWidth), y: y },
                                      bottomRight: {
                                        x: x + transformWidth(hoveringProduct.productWidth),
                                        y: y + hoveringProduct.productHeight,
                                      },
                                    };

                                    const r2: Rectangle = {
                                      topLeft: { x: mp.x, y: mp.y },
                                      bottomLeft: { x: mp.x, y: mp.y + mp.productHeight },
                                      topRight: { x: mp.x + transformWidth(mp.productWidth), y: mp.y },
                                      bottomRight: { x: mp.x + transformWidth(mp.productWidth), y: mp.y + mp.productHeight },
                                    };

                                    return GridService.rectanglesOverlap(r1, r2);
                                  });

                                  if (productInSpace) {
                                    return null;
                                  }

                                  // Limit placeholders on the Y axis so that nothing can overhang
                                  if (yPosition + hoveringProduct.productHeight > mountingPlate.mountsY) {
                                    return null;
                                  }

                                  // Limit placeholders on the X axis so that we can't have overhangs for
                                  // Products that have a width < 2
                                  if (hoveringProduct.productWidth <= 2 && xPosition + hoveringProduct.productWidth > mountingPlate.mountsX) {
                                    return null;
                                  }

                                  // Products with a width of 2 can only be on an even number
                                  if (hoveringProduct.productWidth === 2 && relativeX % 2 === 0) {
                                    return null;
                                  }

                                  // Very wide products with a width of 4 can only go on spaces that allow for it, and only on multiples of 2 //
                                  if (hoveringProduct.productWidth === 4) {
                                    if (relativeX + 3 > mountingPlate.mountsX) {
                                      return null;
                                    }

                                    if (relativeX % 2 === 0) {
                                      return null;
                                    }
                                  }

                                  return (
                                    <WallStorageBuilderMount
                                      key={`mount-${x}/${y}/${mountingPlate.cellGuid}`}
                                      x={x}
                                      y={y}
                                      product={mountingPlate}
                                      relativeX={relativeX}
                                      relativeY={relativeY}
                                    />
                                  );
                                })}
                              </>
                            ))}
                          </>
                        ))}

                      {mountedProducts.map((product, i) => (
                        <WallStorageBuilderProduct key={`product-${product.articleNumber}/${product.x}/${product.y}/${i}`} product={product} />
                      ))}
                    </div>
                    {renderHoveringPlaceholders()}
                  </div>
                </div>
              </div>
            </TransformComponent>
          </TransformWrapper>
        )}
      </div>
      <Footer />

      {/* HIDDEN PDF STUFF */}
      {boundaries.highestX > -1 && (
        <div style={{ position: 'fixed', top: 0, left: 0, zIndex: -1, opacity: 0, pointerEvents: 'none' }}>
          <div
            className="wsb__builder wsb__builder--pdf"
            style={{
              width: `${boundaries.boundsX}px`,
              height: `${boundaries.boundsY}px`,
              gridTemplateColumns: `repeat(${getGridColumns(boundaries.boundsX, gridWidthUnit)}, ${gridWidthUnit}px)`,
              gridTemplateRows: `repeat(${getGridRows(boundaries.boundsY, gridHeightUnit)}, ${gridHeightUnit}px)`,
            }}
          >
            {mountingPlates.map((x, index) => {
              return (
                <WallStorageBuilderCell
                  key={`WSB_${index}_${x.articleNumber}`}
                  product={{
                    ...x,
                    x: x.x - boundaries.lowestX + 1,
                    y: x.y - boundaries.lowestY + 1,
                  }}
                  mountingPlates={mountingPlates}
                />
              );
            })}

            <div
              className="wsb__mounts"
              style={{
                gridArea: `1 / 1 / ${boundaries.cellsX + 1} / ${boundaries.cellsY + 1}`,
                gridTemplateColumns: getGridTemplateColumns(boundaries.cellsX),
                gridTemplateRows: getGridTemplateRows(boundaries.cellsY),
              }}
            >
              {mountedProducts.map((product, i) => {
                return (
                  <WallStorageBuilderProduct
                    key={`product-${product.agilityId}/${product.x}/${product.y}/PDF/${i}`}
                    product={{
                      ...product,
                      x: product.x - (boundaries.lowestX - 1) * 3, // 3 for the number of divisions per mount cell horizontally //
                      y: product.y - (boundaries.lowestY - 1) * 4, // 4 for the number of divisions per mount cell vertically //
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function getGridTemplateColumns(columns: number): string {
  const leftGutter = 23;
  const cellWidth = 92;
  const rightGutter = 40;
  let template = `${leftGutter}px repeat(${columns}, ${cellWidth}px ${cellWidth}px ${rightGutter}px)`;

  return template;
}

function getGridTemplateRows(rows: number): string {
  const topGutter = 103;
  const cellHeight = 92;
  const bottomGutter = 192;
  let template = ` ${topGutter}px repeat(${rows}, ${cellHeight}px ${cellHeight}px ${cellHeight}px ${bottomGutter}px)`;

  return template;
}

export default WallStorageBuilder;

import { createBrowserHistory, createMemoryHistory } from 'history';
import { isInIframe } from './iframe';

let _history: any = null;

function getHistory() {
  if (!_history) {
    if (isInIframe()) {
      const initialUrl = window.location.pathname.replace(process.env.PUBLIC_URL, '');

      _history = createMemoryHistory({ initialEntries: [initialUrl] });
    } else {
      _history = createBrowserHistory({ basename: process.env.PUBLIC_URL });
    }
  }

  return _history;
}

export const history = getHistory();

import { Add, Chevron } from '@components/icons';
import { ProductHelper } from '@core/helpers';
import { Products } from '@core/types';
import { WallStorageBuilderProductCell } from '@core/types/products';
import React, { FC, useMemo, useState } from 'react';

interface IProps {
  model: Products.BaseProduct;
  disabled: boolean;
  onClick: () => void;
  onSubProductClick?: (product: WallStorageBuilderProductCell) => void;
  subProducts?: WallStorageBuilderProductCell[];
}

interface IPropsMain extends Omit<IProps, 'subProducts'> {
  hasSubProducts: boolean;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isSubProduct: boolean;
}

const InventoryCardInner: FC<IPropsMain> = ({ model, disabled, hasSubProducts, onClick, expanded, setExpanded, isSubProduct, onSubProductClick }) => {
  return (
    <div className={`inv-card ${disabled ? 'disabled' : ''}`}>
      <div className="inv-card__image">
        <img alt={model.name} src={ProductHelper.getProductUrl(model, 500)} />
      </div>
      <div className="inv-card__content">
        <h2 className="inv-card__title">{model.name}</h2>
        <h4 className="inv-card__sub">{model.articleNumber}</h4>
      </div>
      {!disabled ? (
        <div
          className="inv-card__remove"
          onClick={() => {
            if (isSubProduct && onSubProductClick) {
              onSubProductClick(model as WallStorageBuilderProductCell);
            } else {
              onClick();
            }
          }}
        >
          <Add />
          {hasSubProducts && !isSubProduct && (
            <span
              className="inv-card__remove--expand"
              data-expanded={expanded}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            >
              <Chevron />
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
};

const InventoryCard: FC<IProps> = props => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const hasSubProducts = useMemo(() => {
    if (props.subProducts) {
      return props.subProducts.length > 0;
    }

    return false;
  }, [props.subProducts]);

  return (
    <>
      <InventoryCardInner {...props} hasSubProducts={hasSubProducts} expanded={expanded} setExpanded={setExpanded} isSubProduct={false} />
      {hasSubProducts && (
        <div className="inv-card__subproducts" data-expanded={expanded}>
          {props.subProducts &&
            props.subProducts.map(product => (
              <InventoryCardInner
                {...props}
                data-expanded={expanded}
                model={product}
                disabled={false}
                key={`${product.cellGuid}`}
                hasSubProducts={hasSubProducts}
                expanded={expanded}
                setExpanded={setExpanded}
                isSubProduct={true}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default InventoryCard;

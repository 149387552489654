import { appReducer } from '@redux/app';
import { inventoryReducer } from '@redux/inventory';
import { productsReducer } from '@redux/products';
import { translationReducer } from '@redux/translations';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import undoable, { includeAction } from 'redux-undo';
import wallStorageBuilderReducer from '../wall-storage-builder/reducer';

function createRootReducer(history: History) {
  return combineReducers({
    app: appReducer,
    products: productsReducer,
    inventory: inventoryReducer,
    translations: translationReducer,
    wallStorageBuilder: undoable(wallStorageBuilderReducer, {
      filter: includeAction('WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT'),
    }),
    router: connectRouter(history),
  });
}

export default createRootReducer;

import React, { FunctionComponent } from 'react';
import { Translate } from '@components/translations';
import { CSSTransition } from 'react-transition-group';

interface IProps {
  showContainer: Boolean;
  resourceString?: string;
  text?: string;
}

const Loader: FunctionComponent<IProps> = ({ showContainer = false, resourceString, text }) => {
  if (showContainer) {
    return (
      <CSSTransition classNames="fade-in" timeout={200}>
        <div className="loader-container">
          <div className="loader">
          </div>
          {resourceString && (
            <p><Translate resourceString={resourceString} /></p>
          )}
          {text && (
            <p>{text}</p>
          )}
        </div>
      </CSSTransition>
    );
  }

  return (
    <CSSTransition classNames="fade-in" timeout={200}>
      <div className="loader">
      </div>
    </CSSTransition>
  );
};

export default Loader;

import React, { FunctionComponent } from 'react';
import { IToastContainer } from './models';
import Toast from './Toast';
import { Translate } from '@components/translations';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const ToastContainer: FunctionComponent<IToastContainer> = ({ toasts }) => (
  <TransitionGroup className="toast-area">
    {toasts.map(toast => (
      <CSSTransition key={toast.id} timeout={300}>
        <Toast key={toast.id} id={toast.id} content={toast.content} resourceString={toast.resourceString}>
          {toast.content} {toast.resourceString && <Translate resourceString={toast.resourceString} />}
        </Toast>
      </CSSTransition>
    ))}
  </TransitionGroup>
);
export default ToastContainer;

export enum CustomIntroID {
  One = 'step-1',
  Two = 'step-2',
  Three = 'step-3',
  Four = 'step-4',
  Five = 'step-5',

  OneTablet = 'step-1-tablet',
  TwoTablet = 'step-2-tablet',
  ThreeTablet = 'step-3-tablet',
  FourTablet = 'step-4-tablet',

  OneMobile = 'step-1-mobile',
  TwoMobile = 'step-2-mobile',
  ThreeMobile = 'step-3-mobile',
  FourMobile = 'step-4-mobile',
}

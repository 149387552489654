import React, { FC, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { Builder } from '@components/builder';
import { Introduction } from '@components/landing';
import LegacyWarning from '@components/landing/LegacyWarning';
import { InventoryNavigation, Navigation, ProductNavigation } from '@components/navigation';
import { Site, States } from '@core/types';
import { ProductType } from '@core/types/products';
import { isIE } from '@core/utils';
import { AppActionCreators } from '@redux/app';
import { ProductActionCreators } from '@redux/products';
import { TranslationActionCreators } from '@redux/translations';
import { CSSTransition } from 'react-transition-group';

interface IProps {
  app: States.AppState;
  translations: States.TranslationsState;
  site: Site.ISite;
  setSite: Function;
  fetchProducts: Function;
  fetchTranslations: Function;
  baseUrl: string;
}

const Landing: FC<IProps> = ({ app, site, translations, fetchProducts, fetchTranslations, setSite, baseUrl }) => {
  useEffect(() => {
    setSite(site);
    fetchProducts(site.cultureCode);
    fetchTranslations(site.cultureCode);
  }, []);

  if (isIE()) {
    if (!translations.loaded) {
      return null;
    }

    return <LegacyWarning />;
  }

  return (
    <CSSTransition timeout={200} classNames="fade-in" in={app.site !== undefined}>
      <Fragment>
        <Navigation />
        <main className="landing">
          <Introduction />
          <ProductNavigation />
          <InventoryNavigation />
          <Builder />
          <iframe id="pdf" src={`${window.location.origin}/${baseUrl}/pdf/`} title="PDF" />

          <div className="offscreen">
            <Builder offScreen={true} />
          </div>
        </main>
      </Fragment>
    </CSSTransition>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
  translations: state.translations,
});

const mapDispatchToProps = {
  fetchTranslations: (cultureCode: string) => TranslationActionCreators.fetchTranslations(cultureCode),
  fetchProducts: (cultureCode: string) => ProductActionCreators.fetchProducts(cultureCode, ProductType.Stack),
  setSite: (site: Site.ISite) => AppActionCreators.setSite(site),
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);

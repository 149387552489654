/**
 * Checks if the current client is IE.
 */
export function isIE() {
  /* eslint-disable */
  if (
    navigator.appName == 'Microsoft Internet Explorer' ||
    !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))
  ) {
    return true;
  }
  /* eslint-enable */
  return false;
}

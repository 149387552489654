export interface Coodinate {
  x: number;
  y: number;
}

export function transformPosition(mountX: number, mountY: number, productX: number, productY: number): Coodinate {
  const xGutterCount = 1;
  const yGutterCount = 1;

  // * 3 or * 5 is the width or height of 1 product in small grid squares
  const productXPositionOnGrid = productX * 3 - xGutterCount;
  const productYPositionOnGrid = productY * 4 - yGutterCount - 1;

  // Get the position inside the product, add spacing after every 2
  let newMountX = 0;

  for (let index = 0; index < mountX; index++) {
    if (index > 0 && index % 2 === 0) {
      newMountX += 2;
    } else {
      newMountX += 1;
    }
  }

  // Get the position inside the product, add spacing after every 2
  let newMountY = 0;
  for (let index = 0; index < mountY; index++) {
    if (index > 0 && index % 3 === 0) {
      newMountY += 2;
    } else {
      newMountY += 1;
    }
  }

  // -1 since it's already 1 indexed before
  const x = productXPositionOnGrid + (newMountX - 1);
  const y = productYPositionOnGrid + (newMountY - 1);

  return { x, y };
  // return { x: relativeProductX + (x - 1) + xGutterCount, y: productY + (y - 1) + yGutterCount };
}

export function transformWidth(width: number): number {
  let newWidth = 0;

  for (let index = 0; index < width; index++) {
    if (index > 0 && index % 2 === 0) {
      newWidth += 2;
    } else {
      newWidth += 1;
    }
  }

  return newWidth;
}

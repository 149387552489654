import { useEffect } from 'react';

const ARROW_KEYS = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

interface UseArrowKeysParams {
  onLeft?: (e: KeyboardEvent) => void;
  onUp?: (e: KeyboardEvent) => void;
  onRight?: (e: KeyboardEvent) => void;
  onDown?: (e: KeyboardEvent) => void;
}

/**
 * Hook to register event listeners for arrow keys
 * @param Callbacks
 */
function useArrowKeys({ onLeft, onUp, onRight, onDown }: UseArrowKeysParams) {
  useEffect(() => {
    const handleInput = (e: KeyboardEvent) => {
      switch (e.keyCode) {
        case ARROW_KEYS.LEFT: {
          if (onLeft) {
            e.preventDefault();
            onLeft(e);
          }
          break;
        }

        case ARROW_KEYS.UP: {
          if (onUp) {
            e.preventDefault();
            onUp(e);
          }
          break;
        }

        case ARROW_KEYS.RIGHT: {
          if (onRight) {
            e.preventDefault();
            onRight(e);
          }
          break;
        }

        case ARROW_KEYS.DOWN: {
          if (onDown) {
            e.preventDefault();
            onDown(e);
          }
          break;
        }

        default: {
          break;
        }
      }
    };

    document.addEventListener('keydown', handleInput);

    return () => document.removeEventListener('keydown', handleInput);
  }, [onDown, onLeft, onRight, onUp]);
}

export default useArrowKeys;

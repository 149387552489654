import { transformPosition } from '@components/wall-storage-builder/WallStorageBuilderHelper';
import { localKeys, LocalStorage } from '@core/storage';
import { States } from '@core/types';
import { Direction, WallStorageBuilderType } from '@core/types/products';
import { Boundaries } from '@core/types/states';
import short from 'short-uuid';
import { WallStorageBuilderActionTypes } from './actions';

const WIDTH_UNIT = 254;
const HEIGHT_UNIT = 508;
const WIDTH = WIDTH_UNIT * 20;
const HEIGHT = HEIGHT_UNIT * 10;
const blankBoundaries: Boundaries = {
  highestX: -1,
  highestY: -1,
  lowestX: -1,
  lowestY: -1,
  boundsX: WIDTH_UNIT,
  boundsY: HEIGHT_UNIT,
  cellsX: -1,
  cellsY: -1,
};
function getInitialState() {
  const initialState: States.WallStorageBuilderState = {
    isLoading: false,
    width: WIDTH,
    height: HEIGHT,
    dimensionsModal: false, // Disable modal
    gridWidthUnit: WIDTH_UNIT,
    gridHeightUnit: HEIGHT_UNIT,
    gridColumns: getGridColumns(WIDTH, WIDTH_UNIT),
    gridRows: getGridRows(HEIGHT, HEIGHT_UNIT),
    products: LocalStorage.get(localKeys.wallStorageBuilder) || [],
    inventoryProducts: LocalStorage.get(localKeys.wallStorageBuilderInventoryOnly) || [],
    hoveringProduct: null,
    activeMountingPlates: [],
    infoModal: null,
    infoModalOpen: false,
    workAreaNoticeModal: false,
    boundaries: { ...blankBoundaries },
    showBoundary: false,
    showRestartModal: false,
    isSubmitting: false,
    hasSubmitted: false,
  };

  return initialState;
}

export function getGridColumns(width: number | undefined | null, widthUnit: number) {
  if (!width) {
    return undefined;
  }

  return Math.floor(width / widthUnit);
}

export function getGridRows(height: number | undefined | null, heightUnit: number) {
  if (!height) {
    return undefined;
  }

  return Math.floor(height / heightUnit);
}

function wallStorageBuilderReducer(
  state: States.WallStorageBuilderState = getInitialState(),
  action: WallStorageBuilderActionTypes,
): States.WallStorageBuilderState {
  switch (action.type) {
    case 'WALL_STORAGE_BUILDER/SET_DIMENSIONS_MODAL': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.dimensionsModal = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_WIDTH': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.width = action.payload;
      newState.gridColumns = getGridColumns(action.payload, newState.gridWidthUnit);
      LocalStorage.set(localKeys.wallStorageBuilderWidth, action.payload);

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_HEIGHT': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.height = action.payload;
      newState.gridRows = getGridRows(action.payload, newState.gridHeightUnit);
      LocalStorage.set(localKeys.wallStorageBuilderHeight, action.payload);

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/ADD_TO_INVENTORY': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.inventoryProducts.push(action.payload);

      LocalStorage.set(localKeys.wallStorageBuilderInventoryOnly, newState.inventoryProducts);

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/REMOVE_FROM_INVENTORY': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      const index = newState.inventoryProducts.findIndex(x => x.articleNumber === action.payload.articleNumber);
      if (index !== -1) {
        newState.inventoryProducts.splice(index, 1);
      }

      LocalStorage.set(localKeys.wallStorageBuilderInventoryOnly, newState.inventoryProducts);

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/ADD': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      for (let index = 0; index < action.payload.length; index++) {
        const product = action.payload[index];

        product.cellGuid = short.uuid();
        product.addedAt = new Date().getTime();

        newState.products.push(JSON.parse(JSON.stringify(product)));
      }

      newState.hoveringProduct = null;

      LocalStorage.set(localKeys.wallStorageBuilder, newState.products);

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/REMOVE': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      const existingIndex = newState.products.findIndex(x => x.cellGuid === action.payload);

      if (existingIndex === -1) {
        return newState;
      }

      newState.products = [];

      for (let index = 0; index < state.products.length; index++) {
        const product = state.products[index];

        if (product.cellGuid === action.payload || product.mountCellGuid === action.payload) {
          continue;
        }

        newState.products.push(product);
      }

      LocalStorage.set(localKeys.wallStorageBuilder, newState.products);

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/MOVE_PRODUCT': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      const productToUpdateIndex = state.products.findIndex(x => x.cellGuid === action.payload.cellGuid);

      if (newState.products[productToUpdateIndex]) {
        newState.products[productToUpdateIndex].mountCellGuid = action.payload.mountCellGuid;

        newState.products[productToUpdateIndex].x = action.payload.x;
        newState.products[productToUpdateIndex].y = action.payload.y;

        newState.products[productToUpdateIndex].relativeX = action.payload.relativeX;
        newState.products[productToUpdateIndex].relativeY = action.payload.relativeY;
      }

      newState.hoveringProduct = null;

      LocalStorage.set(localKeys.wallStorageBuilder, newState.products);

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/MOVE_MOUNTING_PLATE': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      // Get the mounting plate
      const productToUpdateIndex = newState.products.findIndex(x => x.cellGuid === action.payload.cellGuid);
      const mountingPlate = newState.products[productToUpdateIndex];

      if (!mountingPlate) {
        return newState;
      }

      const productsToUpdate = newState.products.filter(
        x => x.productType === WallStorageBuilderType.Product && x.mountCellGuid === mountingPlate.cellGuid,
      );

      // Update the position of the mounting plate
      mountingPlate.x = action.payload.x;
      mountingPlate.y = action.payload.y;

      // Update the product positions
      for (let index = 0; index < productsToUpdate.length; index++) {
        const product = productsToUpdate[index];

        if (product.relativeX === undefined || product.relativeY === undefined) {
          continue;
        }

        const { x, y } = transformPosition(product.relativeX, product.relativeY, mountingPlate.x, mountingPlate.y);

        product.x = x;
        product.y = y;
      }

      newState.hoveringProduct = null;

      LocalStorage.set(localKeys.wallStorageBuilder, newState.products);

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_HOVERING_PRODUCT': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.hoveringProduct = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_SELECTED_MOUNTING_PLATE': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.activeMountingPlates = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/MOVE_MOUNTING_PLATE_WITH_ARROW_KEYS': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      const product = newState.products.find(x => x.cellGuid === action.payload.cellGuid);

      if (!product) {
        return newState;
      }

      switch (action.payload.direction) {
        case Direction.LEFT: {
          product.x -= 1;
          break;
        }
        case Direction.UP: {
          product.y -= 1;
          break;
        }
        case Direction.RIGHT: {
          product.x += 1;
          break;
        }
        case Direction.DOWN: {
          product.y += 1;
          break;
        }
      }

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/RESET': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.products = [];
      LocalStorage.delete(localKeys.wallStorageBuilder);

      newState.boundaries = { ...blankBoundaries };

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_INFO_MODAL': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.infoModal = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_INFO_MODAL_OPEN': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.infoModalOpen = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_WORK_AREA_NOTICE_MODAL': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.workAreaNoticeModal = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_IS_LOADING': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.isLoading = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      const mountingPlates = newState.products.filter(x => x.productType === WallStorageBuilderType.MountingPlate);

      if (mountingPlates.length > 0) {
        const lowestXElement = [...mountingPlates].sort((a, b) => a.x - b.x)[0];
        const highestXElement = [...mountingPlates].sort((a, b) => b.productWidth - a.productWidth - (a.x - b.x))[0];
        const lowestYElement = [...mountingPlates].sort((a, b) => a.y - b.y)[0];
        const highestYElement = [...mountingPlates].sort((a, b) => b.productHeight - a.productHeight - (a.y - b.y))[0];

        const [lowestX, highestX] = [lowestXElement, highestXElement].map(x => [x.x, x.productWidth]);
        const [lowestY, highestY] = [lowestYElement, highestYElement].map(x => [x.y, x.productHeight]);

        // Calculate the distance between the far left & right X values //
        const xDistance = highestX[0] + highestX[1] - lowestX[0];
        const yDistance = highestY[0] + highestY[1] - lowestY[0];

        // How many actual grid cells are we taking up overall? //
        const cellsX = highestXElement.x + highestXElement.productWidth - lowestXElement.x;
        const cellsY = highestYElement.y + highestYElement.productHeight - lowestYElement.y;

        newState.boundaries = {
          highestX: highestX[0],
          highestY: highestY[0],
          lowestX: lowestX[0],
          lowestY: lowestY[0],
          boundsX: xDistance * newState.gridWidthUnit,
          boundsY: yDistance * newState.gridHeightUnit,

          cellsX,
          cellsY,

          lowestXProduct: lowestXElement,
          lowestYProduct: lowestYElement,
          highestXProduct: highestXElement,
          highestYProduct: highestYElement,
        };
      } else {
        // No mounting plates, so just set to nothing to hide the bounds & re-center the grid //
        newState.boundaries = { ...blankBoundaries };
      }

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SHOW_BOUNDARY': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.showBoundary = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT': {
      // Technically does nothing but just something to be picked up by the redux-undo plugin //

      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SET_SHOW_RESTART_MODAL': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.showRestartModal = action.payload;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SUBMIT': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.isSubmitting = true;

      return newState;
    }

    case 'WALL_STORAGE_BUILDER/SUBMIT_SUCCESS': {
      const newState = JSON.parse(JSON.stringify(state)) as States.WallStorageBuilderState;

      newState.isSubmitting = false;
      newState.hasSubmitted = true;

      return newState;
    }

    default:
      return state;
  }
}

export default wallStorageBuilderReducer;

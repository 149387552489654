import React, { FC, Fragment, useEffect } from 'react';

import { connect } from 'react-redux';

import { Builder } from '@components/builder';
import { Introduction } from '@components/landing';
import { InventoryNavigation, Navigation, ProductNavigation } from '@components/navigation';
import Summary from '@components/summary/Summary';
import { Site, States } from '@core/types';
import { ProductType } from '@core/types/products';
import { ProductActionCreators } from '@redux/products';
import { TranslationActionCreators } from '@redux/translations';
import { CSSTransition } from 'react-transition-group';

interface IProps {
  app: States.AppState;
  translations: States.TranslationsState;
  site: Site.ISite;
  fetchProducts: Function;
  baseUrl: string;
}

const StackBuilder: FC<IProps> = ({ app, site, translations, fetchProducts, baseUrl }) => {
  useEffect(() => {
    fetchProducts(site.cultureCode);
  }, [fetchProducts, site]);

  return (
    <CSSTransition timeout={200} classNames="fade-in" in={app.site !== undefined}>
      <Fragment>
        <Navigation />
        <main>
          <Introduction />
          <ProductNavigation />
          <InventoryNavigation />
          <Summary />
          <Builder />
          <iframe id="pdf" src={`${window.location.origin}${window.location.pathname}pdf/`} title="PDF" />

          <div className="offscreen">
            <Builder offScreen={true} />
          </div>
        </main>
      </Fragment>
    </CSSTransition>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
  translations: state.translations,
});

const mapDispatchToProps = {
  fetchTranslations: (cultureCode: string) => TranslationActionCreators.fetchTranslations(cultureCode),
  fetchProducts: (cultureCode: string) => ProductActionCreators.fetchProducts(cultureCode, ProductType.Stack),
};

export default connect(mapStateToProps, mapDispatchToProps)(StackBuilder);

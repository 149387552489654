import { Routes } from '@core/helpers/Routes';
import { RootState } from '@core/types/states';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export interface SplitImageCardsProps {
  leftButtonText?: string | null;
  leftBackgroundImageUrl?: string | null;
  rightButtonText?: string | null;
  rightBackgroundImageUrl?: string | null;
}

const SplitImageCards: React.FC<SplitImageCardsProps> = props => {
  const app = useSelector((x: RootState) => x.app)

  return (
    <div className="split-image-cards__wrapper">
      {renderCard(props.leftButtonText, `/${app.site?.cultureAlias}/${Routes.StackBuilder}/`, props.leftBackgroundImageUrl)}
      {renderCard(props.rightButtonText, `/${app.site?.cultureAlias}/${Routes.WallStorageBuilder}/`, props.rightBackgroundImageUrl)}
    </div>
  );
};

function renderCard(buttonText?: string | null, buttonUrl?: string | null, background?: string | null): React.ReactNode {
  if (!buttonText || !buttonUrl || !background) {
    return null;
  }

  return (
    <div className="split-image-cards__card">
      <div className="split-image-cards__overlay" />

      <img src={background} role="presentation" alt={buttonText} />
      <Link className="btn" to={buttonUrl}>
        {buttonText}
      </Link>
    </div>
  );
}

export default SplitImageCards;

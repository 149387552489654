import { ApiService } from '@core/services';
import { CookieStorage, cookieKeys, sessionKeys } from '@core/storage';
import { Api, Site } from '@core/types';
import { Dispatch } from 'redux';
import {
  AppActionTypes,
  FETCH_SITES,
  FETCH_SITES_ERROR,
  FETCH_SITES_SUCCESS,
  SET_INTRO,
  SET_INVENTORY,
  SET_NAV,
  SET_SITE,
  SET_ZOOM,
  TOGGLE_FRONT_STACK,
  ZOOM_IN,
  ZOOM_OUT,
} from './actions';

export function setZoom(zoom: number): AppActionTypes {
  return {
    type: SET_ZOOM,
    payload: zoom,
  };
}

export function setNav(open: boolean): AppActionTypes {
  return {
    type: SET_NAV,
    payload: open,
  };
}

export function setInventory(open: boolean): AppActionTypes {
  return {
    type: SET_INVENTORY,
    payload: open,
  };
}

export function setSite(site: Site.ISite): AppActionTypes {
  return {
    type: SET_SITE,
    payload: site,
  };
}

export function setIntro(open: boolean): AppActionTypes {
  return {
    type: SET_INTRO,
    payload: open,
  };
}

export const fetchSites = () => async (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_SITES,
  });

  try {
    const requestParams = {
      method: Api.Method.GET,
      slug: 'get-sites',
      cacheKey: sessionKeys.sites,
    };

    const response = await ApiService.request<Api.IFetchSitesResponse>(requestParams);

    dispatch({
      type: FETCH_SITES_SUCCESS,
      payload: response.sites,
    });
  } catch (e) {
    dispatch({
      type: FETCH_SITES_ERROR,
      payload: e,
    });
  }
};

export function ensureIntroPopup(): AppActionTypes {
  const seenPopup = CookieStorage.get<boolean>(cookieKeys.introPopup) || false;

  // Show it if they haven't seen it
  return {
    type: SET_INTRO,
    payload: !seenPopup,
  };
}

export function zoomOut(): AppActionTypes {
  return {
    type: ZOOM_OUT,
  };
}

export function zoomIn(): AppActionTypes {
  return {
    type: ZOOM_IN,
  };
}

export function toggleFrontStack(): AppActionTypes {
  return {
    type: TOGGLE_FRONT_STACK,
  };
}

import { Cross } from '@components/icons';
import { InventoryCard } from '@components/products';
import { Translate } from '@components/translations';
import { ga } from '@core/helpers/ga';
import { AppActionTypes } from '@core/redux/app/actions';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { WallStorageBuilderProductCell, WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';

const WallStorageBuilderSummary = () => {
  const { wallStorageBuilder, app } = useSelector((x: RootState) => x);
  const dispatch = useDispatch();
  const { packoutServer } = usePackoutServer();

  const groupedProducts = useMemo(() => {
    return wallStorageBuilder.present.products
      .sort((a, b) => a.productType - b.productType)
      .reduce<Record<string, WallStorageBuilderProductCell[]>>((acc, cur) => {
        if (cur.productType === WallStorageBuilderType.MountingPlate) {
          if (!acc[cur.cellGuid]) {
            acc[cur.cellGuid] = [];
          }
        } else if (cur.productType === WallStorageBuilderType.Product && cur.mountCellGuid) {
          acc[cur.mountCellGuid].push(cur);
        }

        return acc;
      }, {});
  }, [wallStorageBuilder.present.products]);

  const submitPackoutServer = () => {
    if (!packoutServer) {
      return;
    }

    dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: false });
    packoutServer.emit(
      'submit-basket',
      wallStorageBuilder.present.products.map(x => x.articleNumber),
    );

    ga('packoutstack', 'entry');
  };

  const renderProducts = () => {
    if (wallStorageBuilder.present.products.length === 0) {
      return (
        <div className="inv-nav__empty">
          <Translate resourceString="packout.noproducts" />
        </div>
      );
    }

    return (
      <SimpleBar style={{ height: '100%' }}>
        {Object.entries(groupedProducts).map(group => {
          const mountingPlate = wallStorageBuilder.present.products.find(x => x.cellGuid === group[0]);

          return (
            <div key={mountingPlate?.cellGuid}>
              {mountingPlate && (
                <InventoryCard
                  model={mountingPlate}
                  disabled={false}
                  subProducts={group[1]}
                  onClick={() => {
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/REMOVE', payload: mountingPlate.cellGuid });
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
                    ga('packoutwall', 'product-removed', { articleNumber: mountingPlate.articleNumber });

                    if (packoutServer) {
                      packoutServer.emit('product-removed', mountingPlate.articleNumber);
                    }
                  }}
                  onSubProductClick={product => {
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/REMOVE', payload: product.cellGuid });
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
                    ga('packoutwall', 'product-removed', { articleNumber: product.articleNumber });

                    if (packoutServer) {
                      packoutServer.emit('product-removed', product.articleNumber);
                    }
                  }}
                />
              )}
            </div>
          );
        })}
      </SimpleBar>
    );
  };

  return (
    <div className={`summary ${app.summaryOpen ? 'active' : ''}`}>
      <div className="summary__inner">
        <div className="summary__close" onClick={() => dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: false })}>
          <Cross colour="#000" />
        </div>
        <div className="summary__title">
          <h2>
            <Translate resourceString="packout.yoursystem" />
          </h2>
        </div>
        <div className="summary__products">{renderProducts()}</div>
        <div className="summary__footer">
          <button type="button" className="btn btn--black btn--inline btn--small" onClick={() => submitPackoutServer()}>
            <Translate resourceString="packout.add-to-basket" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WallStorageBuilderSummary;

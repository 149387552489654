import { CookieStorage, cookieKeys } from '@core/storage';
import { States } from '@core/types';
import {
  AppActionTypes,
  FETCH_SITES,
  FETCH_SITES_SUCCESS,
  SET_INTRO,
  SET_INVENTORY,
  SET_NAV,
  SET_SITE,
  SET_SUMMARY,
  SET_ZOOM,
  TOGGLE_FRONT_STACK,
  ZOOM_IN,
  ZOOM_OUT,
} from './actions';

const initialState: States.AppState = {
  alive: true,
  navOpen: false,
  inventoryOpen: false,
  introOpen: false,
  summaryOpen: false,
  sites: [],
  sitesLoading: false,
  site: undefined,
  zoom: 1,
  zoomMin: 0.5,
  zoomMax: 2,
  zoomStep: 0.2,
  hideFrontStack: false,
};

function appReducer(state: States.AppState = initialState, action: AppActionTypes): States.AppState {
  switch (action.type) {
    case SET_ZOOM: {
      const newState = { ...state };

      newState.zoom = action.payload;

      return newState;
    }

    case SET_NAV: {
      const newState = { ...state };

      newState.navOpen = action.payload;

      return newState;
    }

    case SET_INVENTORY: {
      const newState = { ...state };

      newState.inventoryOpen = action.payload;

      return newState;
    }

    case SET_SITE: {
      const newState = { ...state };

      newState.site = action.payload;

      return newState;
    }

    case SET_INTRO: {
      const newState = { ...state };

      newState.introOpen = action.payload;

      // Only set the intro popup to stay hidden if they choose to hide it
      if (action.payload === false) {
        CookieStorage.set(cookieKeys.introPopup, true);
      }

      return newState;
    }

    case FETCH_SITES: {
      const newState = { ...state };

      newState.sitesLoading = true;

      return newState;
    }

    case FETCH_SITES_SUCCESS: {
      const newState = { ...state };

      newState.sites = [...action.payload];

      newState.sitesLoading = false;

      return newState;
    }

    case ZOOM_OUT: {
      const newState = { ...state };

      if (newState.zoom - newState.zoomStep > newState.zoomMin) {
        newState.zoom -= newState.zoomStep;
      }

      return newState;
    }

    case ZOOM_IN: {
      const newState = { ...state };

      if (newState.zoom + newState.zoomStep < newState.zoomMax) {
        newState.zoom += newState.zoomStep;
      }

      return newState;
    }

    case SET_SUMMARY: {
      const newState = { ...state };

      newState.summaryOpen = action.payload;

      return newState;
    }

    case TOGGLE_FRONT_STACK: {
      const newState = { ...state };

      newState.hideFrontStack = !newState.hideFrontStack;

      return newState;
    }

    default:
      return state;
  }
}

export default appReducer;

import { Restart } from '@components/icons';
import Edit from '@components/icons/Edit';
import { Modal } from '@components/shared';
import { Translate } from '@components/translations';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { RootState } from '@core/types/states';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const WallStorageRestartPopup = () => {
  const dispatch = useDispatch();
  const { showRestartModal } = useSelector((x: RootState) => x.wallStorageBuilder.present);

  return (
    <Modal
      visible={showRestartModal}
      hideClose={true}
      onClose={() => dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_SHOW_RESTART_MODAL', payload: false })}
    >
      <div className="notice">
        <Restart />
        <h2 className="notice__title">
          <Translate resourceString="packout.wallstoragebuilder.restart.title" />
        </h2>
        <p>
          <Translate resourceString="packout.wallstoragebuilder.restart.description" />
        </p>
        <div className="notice__buttons">
          <button
            type="button"
            className="btn btn--small btn--with-text"
            onClick={() => {
              dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_SHOW_RESTART_MODAL', payload: false });
            }}
          >
            <Edit />
            <Translate resourceString="packout.wallstoragebuilder.restart.continue-editing" />
          </button>
          <button
            type="button"
            className="btn btn--small btn--black btn--with-text"
            onClick={() => {
              dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_SHOW_RESTART_MODAL', payload: false });
              dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RESET' });
            }}
          >
            <Restart />
            <Translate resourceString="packout.wallstoragebuilder.restart.start-a-fresh-build" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WallStorageRestartPopup;

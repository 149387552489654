import querystring from 'querystring';
import { Api } from '@core/types';
import { SessionStorage } from '@core/storage';

class ApiService {
  async request<T>(request: Api.IRequest): Promise<T> {
    if (request.cacheKey) {
      const cachedValue = SessionStorage.get<T>(request.cacheKey);

      if (cachedValue) {
        return cachedValue as T;
      }
    }

    // Get the url
    const url = this.getUrl(request);

    // Headers
    const headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    });

    if (request.isFileUpload === true) {
      headers.delete('Content-Type');
    }

    // construct a request
    const requestOptions: RequestInit = {
      method: request.method,
      headers,
      mode: 'cors',
      cache: 'default',
      body: this.getBody(request),
    };

    // await the response
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw Error(`${response.status}: ${response.statusText}`);
    }

    const data = (await response.json()) as T;

    if (request.cacheKey) {
      SessionStorage.set(request.cacheKey, data);
    }

    return data;
  }

  getUrl(request: Api.IRequest) {
    const controller = request.controller ? request.controller : 'packout';

    let url = `${process.env.REACT_APP_BASE_URL}/api/${controller}/${request.slug}`;

    if ((request.method === Api.Method.GET || request.method === Api.Method.DELETE) && request.params) {
      url += '?';
      url += querystring.stringify({ ...request.params });
    }

    return url;
  }

  getBody(request: Api.IRequest) {
    if (request.method === Api.Method.POST || request.method === Api.Method.DELETE) {
      if (request.isFileUpload) {
        const formData = new FormData();

        // Append all the param fields as keys in the form data
        Object.keys(request.params).forEach(key => {
          formData.append(key, request.params[key]);
        });

        return formData;
      }

      return JSON.stringify(request.params);
    }

    return null;
  }
}

export default new ApiService();

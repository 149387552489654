import { Products, Translations, Site } from '@core/types';
import { BaseProduct, ProductAsset } from './products';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}

export interface IRequest {
  method: Method;
  slug: string;
  params?: any;
  controller?: string;
  isFileUpload?: boolean;
  cacheKey?: string;
}

export interface IFetchProductsResponse {
  categories: Products.ProductCategory<BaseProduct>[];
}

export interface IFetchTranslationsResponse {
  Localisations: any;
}

export interface IFetchSitesResponse {
  sites: Site.ISite[];
}

export interface ISubmitInventoryRequest {
  cultureCode: string;
  redirectUrl: string;
  inventoryData: string;
}

export interface ISubmitInventoryResponse {
  entryGuid: string;
  imageUrl: string;
}

export interface ISubmitPDFRequest {
  entryGuid: string;
  pdfHtml: string;
  pdfBaseUrl: string;
}

export interface ISubmitPDFResponse {
  pdfUrl: string;
}

export interface GetPackoutPopupDataRequest {
  cultureCode: string;
  agilityId: number;
  includeUnpublished: boolean;
}

export interface PackoutProductPopup {
  productName: string;
  articleNumber: string;
  assets: ProductAsset[];
  videoUrl: string | null;
  productUrl: string;
  isNew: boolean;
  tableData: PackoutProductPopupTableData[];
}

export interface PackoutProductPopupTableData {
  label: string;
  value: string;
}

export interface GetPackoutProductDataResponse {
  popupData: PackoutProductPopup;
}

export interface GetPackoutInspirationDataRequest {
  tab: string;
}

export interface GetPackoutInspirationDataResponse {
  images: string[];
}

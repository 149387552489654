import { transformPosition, transformWidth } from '@components/wall-storage-builder/WallStorageBuilderHelper';
import { Point, WallStorageBuilderProduct, WallStorageBuilderProductCell } from '@core/types/products';
import { Boundaries } from '@core/types/states';

export interface Rectangle {
  topLeft: Point;
  topRight: Point;
  bottomLeft: Point;
  bottomRight: Point;
}

class GridService {
  rectanglesOverlap(r1: Rectangle, r2: Rectangle) {
    return !(
      r2.topLeft.x >= r1.bottomRight.x ||
      r2.bottomRight.x <= r1.topLeft.x ||
      r2.topLeft.y >= r1.bottomRight.y ||
      r2.bottomRight.y <= r1.topLeft.y
    );
  }

  productsOverlap(p1: WallStorageBuilderProductCell, p2: WallStorageBuilderProductCell) {
    const r1 = this.createRectangle(p1);

    const r2 = this.createRectangle(p2);

    return this.rectanglesOverlap(r1, r2);
  }

  createRectangle(product: WallStorageBuilderProductCell, shouldTransformWidth?: boolean): Rectangle {
    const transformedWidth = shouldTransformWidth ? transformWidth(product.productWidth) : product.productWidth;

    return {
      topLeft: { x: product.x, y: product.y },
      bottomLeft: { x: product.x, y: product.y + product.productHeight },
      topRight: { x: product.x + transformedWidth, y: product.y },
      bottomRight: { x: product.x + transformedWidth, y: product.y + product.productHeight },
    };
  }

  createRectangleRaw(x: number, y: number, width: number, height: number, shouldTransformWidth?: boolean): Rectangle {
    const transformedWidth = shouldTransformWidth ? transformWidth(width) : width;

    return {
      topLeft: { x, y },
      bottomLeft: { x, y: y + height },
      topRight: { x: x + transformedWidth, y },
      bottomRight: { x: x + transformedWidth, y: y + height },
    };
  }

  getNextMountingPlateAvailablePosition(
    mountingPlates: WallStorageBuilderProductCell[],
    productToAdd: WallStorageBuilderProduct,
    boundaries: Boundaries,
    gridColumns?: number,
    gridRows?: number,
  ): Point | null {
    if (!gridColumns || !gridRows) {
      return null;
    }

    if (productToAdd.productWidth > gridColumns) {
      return null;
    }

    let xStart = 1;
    let yStart = 1;
    if (boundaries.lowestXProduct && boundaries.lowestYProduct) {
      xStart = boundaries.lowestXProduct.x;
      yStart = boundaries.lowestYProduct.y;
    } else if (mountingPlates.length === 0) {
      xStart = Math.round(gridColumns / 2);
      yStart = Math.round(gridRows / 2);
    }

    for (let y = yStart; y <= gridRows; y++) {
      for (let x = xStart; x <= gridColumns; x++) {
        const r1 = this.createRectangleRaw(x, y, productToAdd.productWidth, productToAdd.productHeight);

        if (mountingPlates.length === 0) {
          return { x, y };
        }

        let doOverlap = false;

        for (const mountingPlate of mountingPlates) {
          const r2 = this.createRectangle(mountingPlate);

          if (this.rectanglesOverlap(r1, r2)) {
            doOverlap = true;
          }
        }

        const xWillFit = x - 1 + productToAdd.productWidth <= gridColumns;
        const yWillFit = y - 1 + productToAdd.productHeight <= gridRows;

        if (!doOverlap && xWillFit && yWillFit) {
          return { x, y };
        }
      }
    }

    return null;
  }

  getNextProductAvailablePosition(
    mountingPlates: WallStorageBuilderProductCell[],
    mountedProducts: WallStorageBuilderProductCell[],
    productToAdd: WallStorageBuilderProduct,
  ): { x: number; y: number; relativeX: number; relativeY: number; mountCellGuid: string } | null {
    if (mountingPlates.length === 0) {
      return null;
    }

    mountingPlates.sort((a, b) => {
      if (a.y < b.y) {
        return -1;
      } else if (a.y > b.y) {
        return 1;
      }

      if (a.x < b.x) {
        return -1;
      } else if (a.x > b.x) {
        return 1;
      }

      return 0;
    });

    for (const mountingPlate of mountingPlates) {
      for (let xPosition = 0; xPosition < mountingPlate.mountsX; xPosition++) {
        for (let yPosition = 0; yPosition < mountingPlate.mountsY; yPosition++) {
          const { x, y } = transformPosition(xPosition + 1, yPosition + 1, mountingPlate.x, mountingPlate.y);
          const relativeX = xPosition + 1;
          const relativeY = yPosition + 1;

          const productInSpace = mountedProducts.find(mp => {
            const r1: Rectangle = {
              topLeft: { x, y },
              bottomLeft: { x, y: y + productToAdd.productHeight },
              topRight: { x: x + transformWidth(productToAdd.productWidth), y: y },
              bottomRight: {
                x: x + transformWidth(productToAdd.productWidth),
                y: y + productToAdd.productHeight,
              },
            };

            const r2: Rectangle = {
              topLeft: { x: mp.x, y: mp.y },
              bottomLeft: { x: mp.x, y: mp.y + mp.productHeight },
              topRight: { x: mp.x + transformWidth(mp.productWidth), y: mp.y },
              bottomRight: { x: mp.x + transformWidth(mp.productWidth), y: mp.y + mp.productHeight },
            };

            return this.rectanglesOverlap(r1, r2);
          });

          if (productInSpace) {
            continue;
          }

          // Limit placeholders on the Y axis so that nothing can overhang
          if (yPosition + productToAdd.productHeight > mountingPlate.mountsY) {
            continue;
          }

          // Limit placeholders on the X axis so that we can't have overhangs for
          // Products that have a width < 2
          if (productToAdd.productWidth <= 2 && xPosition + productToAdd.productWidth > mountingPlate.mountsX) {
            continue;
          }

          // Products with a width of 2 can only be on an even number
          if (productToAdd.productWidth === 2 && relativeX % 2 === 0) {
            continue;
          }

          // Very wide products with a width of 4 can only go on spaces that allow for it, and only on multiples of 2 //
          if (productToAdd.productWidth === 4) {
            if (relativeX + 3 > mountingPlate.mountsX) {
              continue;
            }

            if (relativeX % 2 === 0) {
              continue;
            }
          }

          return {
            x,
            y,
            relativeX,
            relativeY,
            mountCellGuid: mountingPlate.cellGuid,
          };
        }
      }
    }

    return null;
  }
}

export default new GridService();

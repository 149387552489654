import { t } from '@components/translations';
import { CustomIntroID } from '@core/introJs';
import { States } from '@core/types';
import { RootState } from '@core/types/states';
import PackoutServer from '@packout/api/src/PackoutServer';
import introJs, { Options, Step } from 'intro.js';
import React, { FunctionComponent, createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usePackoutServer from './usePackoutServer';
import useWallStorageBuilder from './useWallStorageBuilder';

const steps = {
  desktop: [
    {
      intro: 'packout.introjs.desktop.step1',
      element: `[data-id='${CustomIntroID.One}']`,
      position: 'right',
    } as Step,
    {
      intro: 'packout.introjs.desktop.step2',
      element: `[data-id='${CustomIntroID.Two}']`,
      position: 'right',
    } as Step,
    {
      intro: 'packout.introjs.desktop.step3',
      element: `[data-id='${CustomIntroID.Three}']`,
      position: 'bottom',
    } as Step,
    {
      intro: 'packout.introjs.desktop.step4',
      element: `[data-id='${CustomIntroID.Four}']`,
      position: 'left',
    } as Step,
    {
      intro: 'packout.introjs.desktop.step5',
      element: `[data-id='${CustomIntroID.Five}']`,
      position: 'top',
      tooltipClass: 'introjs-tooltip--no-next',
    } as Step,
  ],
  tablet: [
    {
      intro: 'packout.introjs.desktop.step1',
      element: `[data-tablet-id='${CustomIntroID.OneTablet}']`,
      position: 'right',
    } as Step,
    {
      intro: 'packout.introjs.desktop.step2',
      element: `[data-tablet-id='${CustomIntroID.TwoTablet}']`,
      position: 'right',
    } as Step,
    {
      intro: 'packout.introjs.desktop.step3',
      element: `[data-tablet-id='${CustomIntroID.ThreeTablet}']`,
      position: 'bottom',
    } as Step,
    {
      intro: 'packout.introjs.mobile.step4',
      element: `[data-tablet-id='${CustomIntroID.FourTablet}']`,
      position: 'left',
      tooltipClass: 'introjs-tooltip--no-next',
    } as Step,
  ],
  mobile: [
    {
      intro: 'packout.introjs.mobile.step1',
      element: `[data-mobile-id='${CustomIntroID.OneMobile}']`,
      position: 'auto',
    } as Step,
    {
      intro: 'packout.introjs.mobile.step2',
      element: `[data-mobile-id='${CustomIntroID.TwoMobile}']`,
      position: 'auto',
    } as Step,
    {
      intro: 'packout.introjs.mobile.step3',
      element: `[data-mobile-id='${CustomIntroID.ThreeMobile}']`,
      position: 'auto',
    } as Step,
    {
      intro: 'packout.introjs.mobile.step4',
      element: `[data-mobile-id='${CustomIntroID.FourMobile}']`,
      position: 'auto',
      tooltipClass: 'introjs-tooltip--no-next',
    } as Step,
  ],
};

enum Device {
  Mobile = 0,
  Tablet = 1,
  Desktop = 3,
}

const isDevice = (device: Device) => {
  switch (device) {
    case Device.Desktop:
      return window.matchMedia('(min-width: 1200px)').matches;

    case Device.Tablet:
      return window.matchMedia('(min-width: 768px)').matches;

    case Device.Mobile:
      return !window.matchMedia('(min-width: 768px)').matches;
  }
};

const baseOptions = {
  showStepNumbers: false,
  nextLabel: 'packout.introjs.next',
  prevLabel: 'packout.introjs.prev',
  doneLabel: 'packout.introjs.done',
  skipLabel: 'packout.introjs.skip',
};

const getOptions = (app: States.AppState, packoutServer: PackoutServer | null, device: Device): Options => {
  const options: Options = {
    ...baseOptions,
  };

  switch (device) {
    case Device.Mobile: {
      options.steps = [...steps.mobile];
      break;
    }

    case Device.Tablet: {
      options.steps = [...steps.tablet];
      break;
    }

    case Device.Desktop: {
      options.steps = [...steps.desktop];
      break;
    }
  }

  if (packoutServer) {
    options.steps.splice(3, 1);

    return options;
  }

  if (app.site !== undefined && app.site.submissionPageUrl && app.site.submissionPageUrl.length === 0) {
    // Remove the last item from the options
    options.steps.splice(options.steps.length - 1, 1);

    // Update the new last item to have the correct css class
    options.steps[options.steps.length - 1].tooltipClass = 'introjs-tooltip--no-next';

    return options;
  }

  return options;
};

interface IntroJSProps {
  app: States.AppState;
}

interface IntroJSContext {
  instance: introJs.IntroJs;
  index: CustomIntroID;
  hasTranslated: boolean;
}

const initialState: IntroJSContext = {
  instance: introJs(),
  index: CustomIntroID.One,
  hasTranslated: false,
};

const IntroContext = createContext<IntroJSContext>(initialState);

export const IntroJSProvider: FunctionComponent<IntroJSProps> = ({ app, children }) => {
  const [state, setState] = useState(initialState);
  const [hasTranslated, setHasTranslated] = useState(false);
  const hasLoaded = useSelector((state: RootState) => state.translations.loaded);
  const { packoutServer } = usePackoutServer();
  const { isWSB } = useWallStorageBuilder();

  let options: introJs.Options;

  if (isDevice(Device.Desktop)) {
    options = getOptions(app, packoutServer, Device.Desktop);
  } else if (isDevice(Device.Tablet)) {
    options = getOptions(app, packoutServer, Device.Tablet);
  } else if (isDevice(Device.Mobile)) {
    options = getOptions(app, packoutServer, Device.Mobile);
  }

  if (isDevice(Device.Mobile)) {
  }

  const _initialise = () => {
    if (options.steps === undefined || packoutServer?.settings.hideInteractiveGuide) {
      return;
    }

    if (hasTranslated === false) {
      // Now that translations are in scope we can replace restrings on the options object
      // First do the option values
      options.steps.forEach(option => {
        option.intro = t(option.intro + (isWSB ? '.wall-storage-builder' : ''));
      });

      // Then the button labels
      options.nextLabel = t(options.nextLabel || '');
      options.prevLabel = t(options.prevLabel || '');
      options.doneLabel = t(options.doneLabel || '');
      options.skipLabel = t(options.skipLabel || '');

      setHasTranslated(true);
    }

    const introJSInstance = introJs()
      .onafterchange(() => {
        // Do not recommend doing this.. I was forced to :(
        window.setTimeout(() => {
          const event = document.createEvent('UIEvent');
          event.initEvent('resize', true, true);
          window.dispatchEvent(event);
        }, 1);
      })
      .onchange(element => {
        let selector = '';

        if (isDevice(Device.Desktop)) {
          selector = 'data-id';
        } else if (isDevice(Device.Tablet)) {
          selector = 'data-tablet-id';
        } else if (isDevice(Device.Mobile)) {
          selector = 'data-mobile-id';
        }

        const id = element.getAttribute(selector);
        let elementStep;

        if (id !== null) {
          elementStep = id as CustomIntroID;
        } else {
          elementStep = CustomIntroID.One;
        }

        setState({
          ...state,
          index: elementStep,
        });
      })
      .setOptions(options);

    setState({
      ...state,
      instance: introJSInstance,
    });
  };

  useEffect(() => {
    if (hasLoaded === false) {
      return;
    }

    if (
      state.index === CustomIntroID.Five ||
      state.index === CustomIntroID.One ||
      state.index === CustomIntroID.FourTablet ||
      state.index === CustomIntroID.FourMobile
    ) {
      _initialise();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded, state.index]);

  return (
    <IntroContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </IntroContext.Provider>
  );
};

const useIntroJs = () => useContext(IntroContext);

export default useIntroJs;

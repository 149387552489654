import { Packout, Plus } from '@components/icons';
import { Translate } from '@components/translations';
import * as React from 'react';

export interface ProductBannerProps {
  product1ImageUrl?: string | null;
  product2ImageUrl?: string | null;
  product3ImageUrl?: string | null;
  product4ImageUrl?: string | null;
}

const ProductBanner: React.FC<ProductBannerProps> = props => {
  return (
    <div className="product-banner__wrapper">
      <div className="product-banner__background">
        <Packout />
      </div>
      <div className="product-banner__grid">
        <div className="product-banner__product product-banner__product--1">
          {props.product1ImageUrl && <img role="presentation" src={props.product1ImageUrl} />}
        </div>
        {props.product1ImageUrl && props.product2ImageUrl ? (
          <div className="product-banner__plus">
            <Plus />
          </div>
        ) : null}
        <div className="product-banner__product product-banner__product--2">
          {props.product2ImageUrl && <img role="presentation" src={props.product2ImageUrl} />}
        </div>
        <div className="product-banner__main-logo">
          <Packout />
          <h1>
            <Translate resourceString="packout.build.your.system" />
          </h1>
        </div>
        <div className="product-banner__product product-banner__product--3">
          {props.product3ImageUrl && <img role="presentation" src={props.product3ImageUrl} />}
        </div>
        {props.product3ImageUrl && props.product4ImageUrl ? (
          <div className="product-banner__plus">
            <Plus />
          </div>
        ) : null}
        <div className="product-banner__product product-banner__product--4">
          {props.product4ImageUrl && <img role="presentation" src={props.product4ImageUrl} />}
        </div>
      </div>
    </div>
  );
};

export default ProductBanner;

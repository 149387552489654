import { Products } from '.';

/**
 * Used to describe the type of layer
 */
export enum LayerType {
  full = 0,
  half = 1,
}

/**
 * Used to describe the drop type
 */
export enum DropType {
  none = 0,
  full = 1,
  left = 2,
  right = 3,
  both = 4,
}

/**
 * Used to described the type of beind added
 */
export enum AddTypeHalf {
  left = 0,
  right = 1,
}

/**
 * Position being added to the inventory
 */
export interface IPosition {
  index: number;
  type: AddTypeHalf;
}

/**
 * When adding to the inventory
 */
export enum AddType {
  auto = 0,
  left = 1,
  right = 2,
  full = 3,
}

/**
 * Removal from the inventory
 */
export enum RemoveType {
  full = 0,
  left = 1,
  right = 2,
}

export enum LayerPosition {
  front = 0,
  back = 1,
  top = 2,
}

export enum StackType {
  default = 0,
  double = 1,
  layered = 2,
}

/**
 * Base layer in the inventory
 */
export interface BaseLayer {
  type: LayerType;
  position?: LayerPosition;
}

/**
 * Full Layer
 */
export interface FullLayer extends BaseLayer {
  agilityId: number;
}

/**
 * Half layer in the inventory, extends BaseLayer
 */
export interface HalfLayer extends BaseLayer {
  leftAgilityIds: number[];
  rightAgilityIds: number[];
}

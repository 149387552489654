import { WallStorageBuilderCellProps } from '@components/wall-storage-builder/WallStorageBuilderCell';
import { States } from '@core/types';
import { ProductCategory, ProductType, StackProduct, WallStorageBuilderProduct, WallStorageBuilderType } from '@core/types/products';
import uniqueId from 'lodash/uniqueId';
import { FETCH_PRODUCTS, FETCH_PRODUCTS_ERROR, FETCH_PRODUCTS_SUCCESS, ProductActionTypes } from './actions';

const initialState: States.ProductState = {
  isLoading: false,
  categories: [],
  wallStorageCategories: [],
  infoModal: null,
  infoModalOpen: false,
};

function productsReducer(state: States.ProductState = initialState, action: ProductActionTypes): States.ProductState {
  switch (action.type) {
    case FETCH_PRODUCTS: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    case FETCH_PRODUCTS_SUCCESS: {
      const newState = { ...state };

      const categories = [...action.payload.categories];

      for (const category of categories) {
        category.products.sort((a, b) => (a.order > b.order ? 1 : -1));

        for (const product of category.products) {
          product.id = uniqueId('product_');
        }
      }

      categories.sort((a, b) => (a.order > b.order ? 1 : -1));

      switch (action.payload.type) {
        case ProductType.Stack: {
          newState.categories = action.payload.categories as ProductCategory<StackProduct>[];
          newState.isLoading = false;
          break;
        }

        case ProductType.WallStorage: {
          newState.wallStorageCategories = action.payload.categories as ProductCategory<WallStorageBuilderProduct>[];
          newState.isLoading = false;
          break;
        }
      }

      return newState;
    }

    case FETCH_PRODUCTS_ERROR: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    case 'PRODUCT/SET_INFO_MODAL': {
      const newState = { ...state };

      newState.infoModal = action.payload;

      return newState;
    }

    case 'PRODUCT/SET_INFO_MODAL_OPEN': {
      const newState = { ...state };

      newState.infoModalOpen = action.payload;

      return newState;
    }

    default:
      return state;
  }
}

export default productsReducer;

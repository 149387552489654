import React, { FunctionComponent } from 'react';

interface IProps {
  className?: string;
}

const Packout: FunctionComponent<IProps> = ({ className }) => (
  <svg id="Group_6110" className={className} data-name="Group 6110" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.895 30.016">
    <g id="Group_6092" data-name="Group 6092" transform="translate(0)">
      <g id="Group_6090" data-name="Group 6090">
        <g id="Group_6087" data-name="Group 6087" transform="translate(30.908)">
          <path
            id="Path_681"
            data-name="Path 681"
            d="M44.825,1.04H58.413L70.358,29.083H58.573L57.4,25.859H45.849l-1.175,3.223H32.89Zm6.841,8.486h-.075l-3.3,9.5h6.682Z"
            transform="translate(-32.89 -0.063)"
            fill="#fff"
          />
          <path
            id="Path_682"
            data-name="Path 682"
            d="M110.26,18.109c-.7,3.693-1.964,6.682-4.717,8.721-2.706,2.039-6.954,3.186-13.711,3.186-6.719,0-19.161-.47-19.161-15C72.67.47,85.122,0,91.841,0,98.522,0,108.8.865,110.26,11.982H97.771c-.31-2-1.654-4.906-5.93-4.906-4.52,0-7.386,2.237-7.386,7.932s2.791,7.932,6.832,7.932c3.5,0,5.582-1.457,6.484-4.83Z"
            transform="translate(-35.287 0)"
            fill="#fff"
          />
          <path
            id="Path_683"
            data-name="Path 683"
            d="M131.575,18.322l-3.656,3.223v7.537H116.84V1.04h11.08v9.9l10.29-9.9H152.9L140.371,11.885,153.96,29.092H139.234Z"
            transform="translate(-37.949 -0.063)"
            fill="#fff"
          />
          <path
            id="Path_684"
            data-name="Path 684"
            d="M175.421,0c6.719,0,19.161.47,19.161,15.008s-12.452,15-19.161,15-19.161-.47-19.161-15S168.7,0,175.421,0Zm0,22.94c4.52,0,7.386-2.237,7.386-7.932s-2.866-7.932-7.386-7.932-7.386,2.237-7.386,7.932S170.9,22.94,175.421,22.94Z"
            transform="translate(-40.324 0)"
            fill="#fff"
          />
          <path
            id="Path_685"
            data-name="Path 685"
            d="M200.59,1.04h11.08V18.012c0,3.618,2.077,4.755,5.695,4.755s5.695-1.137,5.695-4.755V1.04h11.08V18.435c0,8.918-5.385,11.625-16.774,11.625S200.59,27.353,200.59,18.435Z"
            transform="translate(-42.995 -0.063)"
            fill="#fff"
          />
        </g>
        <g id="Group_6088" data-name="Group 6088" transform="translate(0 0.987)">
          <path
            id="Path_686"
            data-name="Path 686"
            d="M9.209,26.33l-.329.141H2.124l-.329-.141L0,24.525V30.5H11.08V24.45Z"
            transform="translate(0 -2.46)"
            fill="#fff"
          />
          <path
            id="Path_687"
            data-name="Path 687"
            d="M22.855,1.05H0V20.409l2.321,2.34H8.683l2.359-2.378h11.9c7.856,0,10.685-4.163,10.685-9.548C33.624,4.584,30.711,1.05,22.855,1.05ZM18.222,13.539H11.07V7.647h7.151c2.791,0,3.853.865,3.853,2.988C22.075,12.675,21.013,13.539,18.222,13.539Z"
            transform="translate(0 -1.05)"
            fill="#fff"
          />
        </g>
        <g id="Group_6089" data-name="Group 6089" transform="translate(224.007 0.987)">
          <path
            id="Path_688"
            data-name="Path 688"
            d="M238.37,1.05V8.117H248.6l.019.019,2.34,2.34h6.4l2.359-2.359h10.224V1.05Z"
            transform="translate(-238.37 -1.05)"
            fill="#fff"
          />
          <path
            id="Path_689"
            data-name="Path 689"
            d="M258.546,13.274l-.329.141h-6.794l-.329-.141-1.814-1.8V29.72h11.07V11.47Z"
            transform="translate(-239.027 -1.678)"
            fill="#fff"
          />
        </g>
      </g>
      <g id="Group_6091" data-name="Group 6091" transform="translate(256.456 1.381)">
        <path
          id="Path_690"
          data-name="Path 690"
          d="M276.086,2.006h-1.259V5.5h-.667v-3.5H272.9V1.47h3.2v.536Zm5.253,3.5h-.667V2.137h-.019L279.328,5.5H278.9L277.58,2.137h-.019V5.5h-.667V1.47h1.024l1.2,3.064,1.184-3.064h1.015V5.5Z"
          transform="translate(-272.9 -1.47)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default Packout;

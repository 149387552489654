import { BuilderService, ProductService } from '@core/services';
import { Builder, Products, States } from '@core/types';
import { Add } from '@components/icons';
import React from 'react';
import { Translate, t } from '@components/translations';
import { ProductHelper } from '@core/helpers';

// A 'strategy' is a design pattern in which a piece of logic needs multiple ways to do something //
// but the way in which it does it might change depending on data. We need to define a common function //
// signature which multiple child functions can inherit, and the overall component will call it //
// and get a result which will be of the same return type across all possible permutations //

// #region GET PRODUCT COUNTS
export type GetProductCountsReturnType = React.ReactFragment;
export type GetProductCountsProps = {
  model: Products.StackProduct | Products.WallStorageBuilderProduct;
  stackType: Builder.StackType;
  layers: Builder.BaseLayer[];
  wallStorageBuilder?: States.WallStorageBuilderState;
};
export const getProductCounts = (props: GetProductCountsProps): GetProductCountsReturnType => {
  switch (props.stackType) {
    case Builder.StackType.default:
      return getProductCountsDefault(props);
    case Builder.StackType.double:
      return getProductCountsDouble(props);
    case Builder.StackType.layered:
      return getProductCountsLayered(props);
  }
};

const getProductCountsDefault = (props: GetProductCountsProps): GetProductCountsReturnType => {
  let count = 0;
  if (ProductHelper.isWallStorageProduct(props.model) && props.wallStorageBuilder) {
    count = props.wallStorageBuilder.products.filter(x => x.articleNumber === props.model.articleNumber).length;
  } else {
    count = ProductService.getCount(BuilderService.getLayersByType(props.layers, Builder.LayerPosition.front), props.model.agilityId);
  }
  return (
    <>
      {count !== 0 && (
        <div className="product-card__count">
          <span>X{count}</span> <Translate resourceString="packout.added" />
        </div>
      )}
    </>
  );
};

const getProductCountsDouble = (props: GetProductCountsProps): GetProductCountsReturnType => {
  const count = ProductService.getCount(BuilderService.getLayersByType(props.layers, Builder.LayerPosition.front), props.model.agilityId);
  const countBack = ProductService.getCount(BuilderService.getLayersByType(props.layers, Builder.LayerPosition.back), props.model.agilityId);
  return (
    <>
      {count !== 0 && (
        <div className="product-card__count">
          <span>X{count}</span> {props.model.productType !== Products.Type.base ? t('packout.addded-to-front') : t('packout.added')}
        </div>
      )}
      {countBack !== 0 && (
        <div className="product-card__count">
          <span>X{countBack}</span> {props.model.productType !== Products.Type.base ? t('packout.added-to-back') : t('packout.added')}
        </div>
      )}
    </>
  );
};

const getProductCountsLayered = (props: GetProductCountsProps): GetProductCountsReturnType => {
  const countBottom = ProductService.getCount(BuilderService.getLayersByType(props.layers, Builder.LayerPosition.front), props.model.agilityId);
  const countTop = ProductService.getCount(BuilderService.getLayersByType(props.layers, Builder.LayerPosition.top), props.model.agilityId);
  return (
    <>
      {countTop !== 0 && (
        <div className="product-card__count">
          <span>X{countTop}</span> {props.model.productType !== Products.Type.base ? t('packout.added-to-top') : t('packout.added')}
        </div>
      )}
      {countBottom !== 0 && (
        <div className="product-card__count">
          <span>X{countBottom}</span> {props.model.productType !== Products.Type.base ? t('packout.added-to-bottom') : t('packout.added')}
        </div>
      )}
    </>
  );
};
// #endregion

// #region GET PRODUCT 'ADD' BUTTONS
export type GetProductButtonsReturnType = React.ReactFragment;
export type GetProductButtonsProps = {
  model: Products.StackProduct | Products.WallStorageBuilderProduct;
  stackType: Builder.StackType;
  layers: Builder.BaseLayer[];
  onClick: (position: Builder.LayerPosition) => void;
  disabledLayers?: Builder.LayerPosition[];
};
export const getProductButtons = (props: GetProductButtonsProps): GetProductButtonsReturnType => {
  switch (props.stackType) {
    case Builder.StackType.default:
      return getProductButtonsDefault(props);
    case Builder.StackType.double:
      return getProductButtonsDouble(props);
    case Builder.StackType.layered:
      return getProductButtonsLayered(props);
  }
};

const getProductButtonsDefault = (props: GetProductButtonsProps): GetProductButtonsReturnType => {
  return (
    <>
      <div
        className={`product-card__add-wrapper ${props.disabledLayers && props.disabledLayers.length > 0 ? 'disabled' : ''}`}
        onClick={() => props.onClick(Builder.LayerPosition.front)}
      >
        <div className="product-card__add">
          <Add />
        </div>
        <span>
          <Translate resourceString={'packout.add-to-build'} />
        </span>
      </div>
    </>
  );
};

const getProductButtonsDouble = (props: GetProductButtonsProps): GetProductButtonsReturnType => {
  const renderNormalButton = props.model.productType === Products.Type.base;

  if (renderNormalButton) {
    return getProductButtonsDefault(props);
  }

  return (
    <>
      <div
        className={`product-card__add-wrapper ${
          props.disabledLayers && props.disabledLayers.indexOf(Builder.LayerPosition.front) > -1 ? 'disabled' : ''
        }`}
        onClick={() => props.onClick(Builder.LayerPosition.front)}
      >
        <div className="product-card__add">
          <Add />
        </div>
        <span>
          <Translate resourceString={'packout.add-to-front-stack'} />
        </span>
      </div>
      <div
        className={`product-card__add-wrapper ${
          props.disabledLayers && props.disabledLayers.indexOf(Builder.LayerPosition.back) > -1 ? 'disabled' : ''
        }`}
        onClick={() => props.onClick(Builder.LayerPosition.back)}
      >
        <div className="product-card__add">
          <Add />
        </div>
        <span>
          <Translate resourceString={'packout.add-to-back-stack'} />
        </span>
      </div>
    </>
  );
};

const getProductButtonsLayered = (props: GetProductButtonsProps): GetProductButtonsReturnType => {
  const renderNormalButton = props.model.productType === Products.Type.base;

  if (renderNormalButton) {
    return getProductButtonsDefault(props);
  }

  return (
    <>
      <div
        className={`product-card__add-wrapper ${
          props.disabledLayers && props.disabledLayers.indexOf(Builder.LayerPosition.top) > -1 ? 'disabled' : ''
        }`}
        onClick={() => props.onClick(Builder.LayerPosition.top)}
      >
        <div className="product-card__add">
          <Add />
        </div>
        <span>
          <Translate resourceString={'packout.add-to-top-stack'} />
        </span>
      </div>
      <div
        className={`product-card__add-wrapper ${
          props.disabledLayers && props.disabledLayers.indexOf(Builder.LayerPosition.front) > -1 ? 'disabled' : ''
        }`}
        onClick={() => props.onClick(Builder.LayerPosition.front)}
      >
        <div className="product-card__add">
          <Add />
        </div>
        <span>
          <Translate resourceString={'packout.add-to-bottom-stack'} />
        </span>
      </div>
    </>
  );
};
// #endregion

export const ProductCardStrategies = {
  getProductCounts,
  getProductButtons,
};

import { Products } from '../types';
import * as Builder from '../types/builder';
import BuilderService from '../services/BuilderService';

// #region GET DISABLED LAYERS
export type GetDisabledLayersReturnType = Builder.LayerPosition[];
export type GetProductLayersProps = {
  stackType: Builder.StackType;
  product: Products.StackProduct;
  categories: Products.ProductCategory<Products.StackProduct>[];
  layers: Builder.BaseLayer[];
};
export const getDisabledLayers = (props: GetProductLayersProps): GetDisabledLayersReturnType => {
  switch (props.stackType) {
    default:
    case Builder.StackType.default:
      return getDisabledLayersDefault(props);
    case Builder.StackType.double:
      return getDisabledLayersDouble(props);
    case Builder.StackType.layered:
      return getDisabledLayersLayered(props);
  }
};

// Simplified version to cut down on bulky code //
const canDrop = (
  product: Products.StackProduct,
  categories: Products.ProductCategory<Products.StackProduct>[],
  layers: Builder.BaseLayer[],
  dropType: Builder.DropType,
  position: Builder.LayerPosition,
  stackType?: Builder.StackType,
): boolean => {
  return BuilderService.canDrop(product, categories, BuilderService.getLayersByType(layers, position), dropType, position, stackType);
};

const getDisabledLayersDefault = ({ categories, layers, product }: GetProductLayersProps): GetDisabledLayersReturnType => {
  const disabledLayers: Builder.LayerPosition[] = [];

  if (product.productWidth === Products.Width.full) {
    if (!canDrop(product, categories, layers, Builder.DropType.full, Builder.LayerPosition.front)) {
      disabledLayers.push(Builder.LayerPosition.front);
    }
  } else {
    const canDropLeft = canDrop(product, categories, layers, Builder.DropType.left, Builder.LayerPosition.front);
    const canDropRight = canDrop(product, categories, layers, Builder.DropType.right, Builder.LayerPosition.front);
    if (!(canDropLeft || canDropRight)) {
      disabledLayers.push(Builder.LayerPosition.front);
    }
  }

  return disabledLayers;
};

const getDisabledLayersDouble = ({ categories, layers, product }: GetProductLayersProps): GetDisabledLayersReturnType => {
  const disabledLayers: Builder.LayerPosition[] = [];

  if (product.productWidth === Products.Width.full) {
    if (!canDrop(product, categories, layers, Builder.DropType.full, Builder.LayerPosition.front)) {
      disabledLayers.push(Builder.LayerPosition.front);
    }

    if (!canDrop(product, categories, layers, Builder.DropType.full, Builder.LayerPosition.back)) {
      disabledLayers.push(Builder.LayerPosition.back);
    }
  } else {
    const canDropLeftFront = canDrop(product, categories, layers, Builder.DropType.left, Builder.LayerPosition.front);
    const canDropRightFront = canDrop(product, categories, layers, Builder.DropType.right, Builder.LayerPosition.front);
    const canDropLeftBack = canDrop(product, categories, layers, Builder.DropType.left, Builder.LayerPosition.back);
    const canDropRightBack = canDrop(product, categories, layers, Builder.DropType.right, Builder.LayerPosition.back);
    if (!(canDropLeftFront || canDropRightFront)) {
      disabledLayers.push(Builder.LayerPosition.front);
    }

    if (!(canDropLeftBack || canDropRightBack)) {
      disabledLayers.push(Builder.LayerPosition.back);
    }
  }

  return disabledLayers;
};

const getDisabledLayersLayered = ({ categories, layers, product, stackType }: GetProductLayersProps): GetDisabledLayersReturnType => {
  const disabledLayers: Builder.LayerPosition[] = [];

  if (product.productWidth === Products.Width.full) {
    if (!canDrop(product, categories, layers, Builder.DropType.full, Builder.LayerPosition.front, stackType)) {
      disabledLayers.push(Builder.LayerPosition.front);
    }

    if (!canDrop(product, categories, layers, Builder.DropType.full, Builder.LayerPosition.top, stackType)) {
      disabledLayers.push(Builder.LayerPosition.top);
    }
  } else {
    const canDropLeftFront = canDrop(product, categories, layers, Builder.DropType.left, Builder.LayerPosition.front, stackType);
    const canDropRightFront = canDrop(product, categories, layers, Builder.DropType.right, Builder.LayerPosition.front, stackType);
    const canDropLeftTop = canDrop(product, categories, layers, Builder.DropType.left, Builder.LayerPosition.top, stackType);
    const canDropRightTop = canDrop(product, categories, layers, Builder.DropType.right, Builder.LayerPosition.top, stackType);

    if (!(canDropLeftFront || canDropRightFront)) {
      disabledLayers.push(Builder.LayerPosition.front);
    }

    if (!(canDropLeftTop || canDropRightTop)) {
      disabledLayers.push(Builder.LayerPosition.top);
    }
  }

  return disabledLayers;
};
// #endregion

export const ProductNavigationStrategies = {
  getDisabledLayers,
};

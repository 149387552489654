import { Site } from '@core/types';

export const SET_ZOOM = 'APP/SET_ZOOM';
export const SET_NAV = 'APP/SET_NAV';
export const SET_INVENTORY = 'APP/SET_INVENTORY';
export const SET_SITE = 'APP/SET_SITE';
export const SET_INTRO = 'APP/SET_INTRO';
export const FETCH_SITES = 'APP/FETCH_SITES';
export const FETCH_SITES_SUCCESS = 'APP/FETCH_SITES_SUCCESS';
export const FETCH_SITES_ERROR = 'APP/FETCH_SITES_ERROR';
export const ZOOM_OUT = 'APP/ZOOM_OUT';
export const ZOOM_IN = 'APP/ZOOM_IN';
export const SET_SUMMARY = 'APP/SET_SUMMARY';
export const TOGGLE_FRONT_STACK = 'APP/TOGGLE_FRONT_STACK';

export interface SetZoom {
  type: typeof SET_ZOOM;
  payload: number;
}

export interface SetNav {
  type: typeof SET_NAV;
  payload: boolean;
}

export interface SetInventory {
  type: typeof SET_INVENTORY;
  payload: boolean;
}

export interface SetSite {
  type: typeof SET_SITE;
  payload: Site.ISite;
}

export interface SetIntro {
  type: typeof SET_INTRO;
  payload: boolean;
}

export interface FetchSites {
  type: typeof FETCH_SITES;
}

export interface FetchSitesSuccess {
  type: typeof FETCH_SITES_SUCCESS;
  payload: Site.ISite[];
}

export interface FetchSitesError {
  type: typeof FETCH_SITES;
  payload: string;
}

export interface ZoomOut {
  type: typeof ZOOM_OUT;
}

export interface ZoomIn {
  type: typeof ZOOM_IN;
}

export interface SetSummary {
  type: typeof SET_SUMMARY;
  payload: boolean;
}

export interface ToggleFrontStack {
  type: typeof TOGGLE_FRONT_STACK;
}

export type AppActionTypes =
  | SetNav
  | SetZoom
  | SetInventory
  | SetSite
  | SetIntro
  | FetchSites
  | FetchSitesSuccess
  | FetchSitesError
  | ZoomOut
  | ZoomIn
  | SetSummary
  | ToggleFrontStack;

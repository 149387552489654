const keys: string[] = [
  'quantity',
  'back',
  'packout.yoursystem',
  'packout.yoursystem.wall',
  'packout.noproducts',
  'SubmitEntry',
  'packout.dreampackout',
  'restart',
  'packout',
  'AddProducts',
  'packout.productadded',
  'packout.firstload',
  'packout.loadingproducts',
  'packout.rollers',
  'packout.bags',
  'packout.boxes',
  'packout.tools',
  'packout.organisers',
  'packout.submitting',
  'packout.submitted',
  'packout.submit.error.title',
  'packout.submit.error.description',
  'close',
  'undo',
  'redo',
  'packout.storage',
  'packout.introjs.desktop.step1',
  'packout.introjs.desktop.step2',
  'packout.introjs.desktop.step3',
  'packout.introjs.desktop.step4',
  'packout.introjs.desktop.step5',
  'packout.introjs.mobile.step1',
  'packout.introjs.mobile.step2',
  'packout.introjs.mobile.step3',
  'packout.introjs.mobile.step4',
  'packout.introjs.mobile.step5',
  'packout.introjs.desktop.step1.wall-storage-builder',
  'packout.introjs.desktop.step2.wall-storage-builder',
  'packout.introjs.desktop.step3.wall-storage-builder',
  'packout.introjs.desktop.step4.wall-storage-builder',
  'packout.introjs.desktop.step5.wall-storage-builder',
  'packout.introjs.mobile.step1.wall-storage-builder',
  'packout.introjs.mobile.step2.wall-storage-builder',
  'packout.introjs.mobile.step3.wall-storage-builder',
  'packout.introjs.mobile.step4.wall-storage-builder',
  'packout.introjs.mobile.step5.wall-storage-builder',
  'packout.introjs.next',
  'packout.introjs.prev',
  'packout.introjs.done',
  'packout.introjs.skip',
  'packout.legacy.error',
  'custom.yes',
  'custom.dismiss',
  'custom.culturewarning.copy',
  'packout.stack-builder',
  'packout.wall-storage-builder',
  'packout.dimensions.modal.title',
  'packout.dimensions.modal.description',
  'packout.dimensions.modal.cta',
  'packout.dimensions.edit',
  'packout.download.wall-storage-builder',
  'packout.wallstoragebuilder.restart.title',
  'packout.wallstoragebuilder.restart.description',
  'packout.wallstoragebuilder.restart.continue-editing',
  'packout.wallstoragebuilder.restart.start-a-fresh-build',
  'packout.dimensions.revise',
  'packout.mounting-plates.remove',
  'packout.build.your.system',
  'packout.infopopup.title',
  'packout.infopopup.features.title',
  'packout.infopopup.video.title',
  'packout.infopopup.product.add',
  'packout.new',
  'packout.addproduct.noroom',
  'packout.width',
  'packout.height',
  'packout.dimensions.invalid',
  'packout.add-to-build',
  'packout.dimensions',
  'packout.capacity',
  'packout.type',
  'packout.storage.wallstorage',
  'packout.get-inspired',
  'packout.add-to-basket',
  'packout.front-layer',
  'packout.back-layer',
  'packout.base-layer',
  'packout.top-layer',
  'packout.bottom-layer',
  'packout.add-to-front-stack',
  'packout.add-to-back-stack',
  'packout.add-to-top-stack',
  'packout.add-to-bottom-stack',
  'packout.hide-front-stack',
  'packout.show-front-stack',
  'packout.infopopup.product.add-to-back',
  'packout.infopopup.product.add-to-front',
  'packout.add-to-inventory',
  'packout.inventory-only',
  'packout.added',
  'packout.added-to-front',
  'packout.added-to-back',
  'packout.added-to-top',
  'packout.added-to-bottom',
];

export default keys;

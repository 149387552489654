import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SimpleBar from 'simplebar-react';

import { Cross, Packout } from '@components/icons';
import { ProductCard } from '@components/products';
import { Translate } from '@components/translations';
import { ga } from '@core/helpers/ga';
import { CustomIntroID } from '@core/introJs';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { GridService } from '@core/services';
import { Builder, Products, States } from '@core/types';
import { WallStorageBuilderProduct, WallStorageBuilderProductCell, WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import { useToast } from '@hooks/useToast';
import { AppActionCreators } from '@redux/app';
import short from 'short-uuid';

interface IProps {
  app?: States.AppState;
  products?: States.ProductState;
  inventory?: States.InventoryState;
  closeNav: Function;
}

const WallStorageProductNavigation: FC<IProps> = ({ app, products, inventory, closeNav }) => {
  const { addToast } = useToast();
  const { wallStorageBuilder } = useSelector((x: RootState) => x);
  const { packoutServer } = usePackoutServer();
  const [activeCategories, setActiveCategories] = useState<string[]>(['packout.category.mountingplates']);
  const dispatch = useDispatch();
  const mountingPlates = React.useMemo(
    () => wallStorageBuilder.present.products.filter(x => x.productType === WallStorageBuilderType.MountingPlate),
    [wallStorageBuilder],
  );
  const mountedProducts = React.useMemo(() => wallStorageBuilder.present.products.filter(x => x.productType === WallStorageBuilderType.Product), [
    wallStorageBuilder,
  ]);

  const handleCategoryClick = useCallback(
    (codeName: string, collapseOthers: boolean = false) => {
      if (collapseOthers) {
        setActiveCategories([codeName]);
        return;
      }

      const index = activeCategories.indexOf(codeName);

      if (index > -1) {
        const newCategories = [...activeCategories];
        newCategories.splice(index, 1);
        setActiveCategories(newCategories);
        return;
      }

      setActiveCategories([...activeCategories, codeName]);
    },
    [activeCategories],
  );

  if (!products || !app || products.isLoading || !inventory) {
    return null;
  }

  const navClassNames = classNames({
    'side-nav': true,
    active: app.navOpen,
  });

  const mapProductCategory = (x: Products.ProductCategory<WallStorageBuilderProduct>) => {
    const isActive = activeCategories.indexOf(x.codeName) > -1;

    const productsToRender = x.products.filter(x => {
      if (!packoutServer || !packoutServer.settings.products || packoutServer.settings.products.length === 0) {
        return true;
      }

      return packoutServer.settings.products.indexOf(x.articleNumber) > -1;
    });

    if (productsToRender.length === 0) {
      return null;
    }

    return (
      <div key={x.name} className={`nav-item ${isActive ? 'active' : ''}`}>
        <h2 className="nav-item__title" onClick={() => handleCategoryClick(x.codeName)}>
          {x.name}
        </h2>
        <div className="nav-item__children">
          <TransitionGroup>
            {isActive &&
              productsToRender
                .sort((a, b) => a.order - b.order)
                .map((p, index) => {
                  let canDrop = false;

                  switch (p.productType) {
                    case WallStorageBuilderType.MountingPlate: {
                      canDrop = !!wallStorageBuilder.present.width && !!wallStorageBuilder.present.height;
                      break;
                    }

                    case WallStorageBuilderType.Product: {
                      const position = GridService.getNextProductAvailablePosition(mountingPlates, mountedProducts, p);
                      canDrop = position != null;
                      break;
                    }
                  }

                  return (
                    <CSSTransition key={index} timeout={200} classNames="slide-in-left">
                      <ProductCard
                        disabledLayers={!canDrop ? [Builder.LayerPosition.front] : undefined}
                        model={p}
                        site={app.site}
                        showInfoPopup={true}
                        wallStorageBuilder={wallStorageBuilder.present}
                        onDragStart={() => {
                          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_HOVERING_PRODUCT', payload: p });
                        }}
                        onDragEnd={() => {
                          dispatch<WallStorageBuilderActionTypes>({
                            type: 'WALL_STORAGE_BUILDER/SET_HOVERING_PRODUCT',
                            payload: null,
                          });
                        }}
                        onClick={() => {
                          let cell: WallStorageBuilderProductCell | null = null;

                          if (p.isInventoryOnly) {
                            dispatch<WallStorageBuilderActionTypes>({
                              type: 'WALL_STORAGE_BUILDER/ADD_TO_INVENTORY',
                              payload: p,
                            });

                            return;
                          }

                          switch (p.productType) {
                            case WallStorageBuilderType.MountingPlate: {
                              const position = GridService.getNextMountingPlateAvailablePosition(
                                mountingPlates,
                                p,
                                wallStorageBuilder.present.boundaries,
                                wallStorageBuilder.present.gridColumns,
                                wallStorageBuilder.present.gridRows,
                              );

                              if (!position) {
                                dispatch<WallStorageBuilderActionTypes>({
                                  type: 'WALL_STORAGE_BUILDER/SET_WORK_AREA_NOTICE_MODAL',
                                  payload: true,
                                });
                                break;
                              }

                              cell = {
                                ...p,
                                cellGuid: short.uuid(),
                                x: position.x,
                                y: position.y,
                              };

                              break;
                            }

                            case WallStorageBuilderType.Product: {
                              const result = GridService.getNextProductAvailablePosition(mountingPlates, mountedProducts, p);

                              if (!result) {
                                break;
                              }

                              cell = {
                                ...p,
                                cellGuid: short.uuid(),
                                x: result.x,
                                y: result.y,
                                relativeX: result.relativeX,
                                relativeY: result.relativeY,
                                mountCellGuid: result.mountCellGuid,
                              };

                              break;
                            }
                          }

                          if (cell === null) {
                            addToast('', 'packout.addproduct.noroom');
                            return;
                          }

                          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/ADD', payload: [cell] });
                          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
                          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
                          addToast(p.name, 'packout.productadded');
                          ga('packoutwall', 'product-added', { articleNumber: cell.articleNumber });
                          if (packoutServer) {
                            packoutServer.emit('product-added', cell.articleNumber);
                          }
                        }}
                      />
                    </CSSTransition>
                  );
                })}
          </TransitionGroup>
        </div>
      </div>
    );
  };

  const productCategories = products.wallStorageCategories.sort((a, b) => a.order - b.order).map(x => mapProductCategory(x));

  return (
    <nav className={navClassNames}>
      <div className="side-nav__header">
        <div className="side-nav__logo">
          <Packout />
          <h4>
            <Translate resourceString={'packout.storage.wallstorage'} />
          </h4>
        </div>
        <div className="side-nav__close" onClick={() => closeNav()}>
          <span>
            <Translate resourceString="close" />
          </span>
          <Cross />
        </div>
      </div>
      <div className="side-nav__items" data-mobile-id={CustomIntroID.TwoMobile}>
        <SimpleBar style={{ maxHeight: '100%' }}>
          <div data-id={CustomIntroID.One} data-tablet-id={CustomIntroID.OneTablet}>
            {productCategories.slice(0, 1)}
          </div>
          <div className="" data-id={CustomIntroID.Two} data-tablet-id={CustomIntroID.TwoTablet}>
            {productCategories.slice(1, productCategories.length)}
          </div>
        </SimpleBar>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
  products: state.products,
  inventory: state.inventory,
});

const mapDispatchToProps = {
  closeNav: () => AppActionCreators.setNav(false),
};

export default connect(mapStateToProps, mapDispatchToProps)(WallStorageProductNavigation);

import { Introduction } from '@components/landing';
import { Navigation } from '@components/navigation';
import { Loader } from '@components/shared';
import WallStorageBuilder from '@components/wall-storage-builder/WallStorageBuilder';
import WallStorageBuilderSummary from '@components/wall-storage-builder/WallStorageBuilderSummary';
import WallStorageInventory from '@components/wall-storage-builder/WallStorageInventory';
import WallStorageProductNavigation from '@components/wall-storage-builder/WallStorageProductNavigation';
import { fetchProducts } from '@core/redux/products/actioncreators';
import { Site } from '@core/types';
import { ProductType } from '@core/types/products';
import { RootState } from '@core/types/states';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

interface Props {
  site: Site.ISite;
  baseUrl: string;
}

const WallStorageBuilderPage: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const site = useSelector((x: RootState) => x.app.site, _.isEqual);
  const { isLoading, wallStorageCategories } = useSelector((x: RootState) => x.products, _.isEqual);

  useEffect(() => {
    dispatch(fetchProducts(props.site.cultureCode, ProductType.WallStorage));
  }, [dispatch, props.site.cultureCode]);

  if (isLoading || wallStorageCategories.length === 0) {
    return <Loader showContainer={true} />;
  }

  return (
    <CSSTransition timeout={200} classNames="fade-in" in={site !== undefined}>
      <>
        <Navigation />
        <main>
          <Introduction />
          <WallStorageProductNavigation />
          <WallStorageBuilderSummary />
          <WallStorageBuilder />
          <WallStorageInventory />

          <iframe id="pdf" src={`${window.location.origin}${window.location.pathname}pdf/`} title="PDF" />
        </main>
      </>
    </CSSTransition>
  );
};

export default WallStorageBuilderPage;

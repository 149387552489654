import { Builder, Products } from '@core/types';
import { StackType } from '@core/types/builder';
import { StackProduct } from '@core/types/products';

class ProductService {
  getByAgilityId(agilityId: number, categories: Products.ProductCategory<StackProduct>[]): Products.StackProduct | undefined {
    let product;

    for (const category of categories) {
      const lookup = category.products.find(x => x.agilityId === agilityId);

      if (lookup) {
        product = lookup;
      }
    }

    return product;
  }

  getByAgilityIds(agilityIds: number[], categories: Products.ProductCategory<StackProduct>[]): Products.StackProduct[] {
    const products: Products.StackProduct[] = [];

    for (const category of categories) {
      for (const agilityId of agilityIds) {
        const product = category.products.find(x => x.agilityId === agilityId);
        if (product !== undefined) {
          products.push(product);
        }
      }
    }

    products.sort((a, b) => {
      const aIndex = agilityIds.indexOf(a.agilityId);
      const bIndex = agilityIds.indexOf(b.agilityId);

      if (aIndex > bIndex) {
        return 1;
      }

      if (bIndex > aIndex) {
        return -1;
      }

      return 0;
    });

    return products;
  }

  getByFullLayer(layer: Builder.FullLayer, categories: Products.ProductCategory<StackProduct>[]): Products.StackProduct {
    return this.getByAgilityIds([layer.agilityId], categories)[0];
  }

  getByHalfLayer(layer: Builder.HalfLayer, categories: Products.ProductCategory<StackProduct>[]): Products.StackProduct[] {
    return this.getByAgilityIds([...layer.leftAgilityIds, ...layer.rightAgilityIds], categories);
  }

  getByLayers(layers: Builder.BaseLayer[], categories: Products.ProductCategory<StackProduct>[]): Products.StackProduct[] {
    let agilityIds: number[] = [];

    for (const layer of layers) {
      switch (layer.type) {
        case Builder.LayerType.full: {
          const fullLayer = layer as Builder.FullLayer;

          agilityIds.push(fullLayer.agilityId);
          break;
        }

        case Builder.LayerType.half: {
          const halfLayer = layer as Builder.HalfLayer;

          agilityIds = agilityIds.concat(halfLayer.leftAgilityIds);
          agilityIds = agilityIds.concat(halfLayer.rightAgilityIds);
          break;
        }

        default:
          break;
      }
    }

    return this.getByAgilityIds(agilityIds, categories);
  }

  getCount(layers: Builder.BaseLayer[], agilityId: number): number {
    let count = 0;

    for (const layer of layers) {
      switch (layer.type) {
        case Builder.LayerType.full: {
          const fullLayer = layer as Builder.FullLayer;

          if (fullLayer.agilityId === agilityId) {
            count++;
          }
          break;
        }

        case Builder.LayerType.half: {
          const halfLayer = layer as Builder.HalfLayer;

          halfLayer.leftAgilityIds.forEach(element => {
            if (element === agilityId) {
              count++;
            }
          });

          halfLayer.rightAgilityIds.forEach(element => {
            if (element === agilityId) {
              count++;
            }
          });

          break;
        }

        default:
          break;
      }
    }

    return count;
  }

  getStackType(layers: Builder.BaseLayer[], categories: Products.ProductCategory<Products.StackProduct>[]): StackType {
    // Check for double base products //
    const anyDoubleBase = this.getByLayers(layers, categories).some(x => x.productBaseType === Products.BaseType.double);
    if (anyDoubleBase) {
      return StackType.double;
    }

    const anyLayeredBase = this.getByLayers(layers, categories).some(x => x.productBaseType === Products.BaseType.layered);
    if (anyLayeredBase) {
      return StackType.layered;
    }

    return StackType.default;
  }

  isLayerDisabled(disabledLayers: Builder.LayerPosition[] | undefined, position: Builder.LayerPosition): boolean {
    return disabledLayers ? disabledLayers.indexOf(position) > -1 : true;
  }

  getAllLayers(layers: Builder.BaseLayer[]): Record<Builder.LayerPosition, Builder.BaseLayer[]> {
    return Object.entries(Builder.LayerPosition)
      .filter(x => !isNaN(Number(x[1])))
      .reduce((acc, cur) => {
        const layerPosition = cur[1] as Builder.LayerPosition;
        acc[layerPosition] = layers.filter(x => x.position === layerPosition);
        return acc;
      }, {} as Record<Builder.LayerPosition, Builder.BaseLayer[]>);
  }

  hasOnlyFrontLayers(layers: Builder.BaseLayer[], categories: Products.ProductCategory<Products.StackProduct>[]): boolean {
    const allLayers = this.getAllLayers(layers);
    let hasMoreThanFrontLayers = false;

    Object.entries(Builder.LayerPosition)
      .filter(x => !isNaN(Number(x[1])))
      .forEach(map => {
        const position = map[1] as Builder.LayerPosition;
        if (position !== Builder.LayerPosition.front && this.getByLayers(allLayers[position], categories).length > 0) {
          hasMoreThanFrontLayers = true;
        }
      });

    return !hasMoreThanFrontLayers;
  }
}

export default new ProductService();

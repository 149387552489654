import { ProductHelper } from '@core/helpers';
import { ga } from '@core/helpers/ga';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { WallStorageBuilderProductCell, WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import { useToast } from '@hooks/useToast';
import classNames from 'classnames';
import React from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

interface WallStorageBuilderPlaceholderProps {
  gridColumns: number;
  gridRows: number;
  x: number;
  y: number;
}

const WallStorageBuilderPlaceholder: React.FC<WallStorageBuilderPlaceholderProps> = props => {
  const { hoveringProduct } = useSelector((x: RootState) => x.wallStorageBuilder.present);
  const { addToast } = useToast();
  const { packoutServer } = usePackoutServer();

  const dispatch = useDispatch();
  const [{ isHovering }, dropRef] = useDrop({
    accept: [`${WallStorageBuilderType.MountingPlate}`, `${WallStorageBuilderType.MountingPlate}_MOVE`],
    canDrop: (item, monitor) => {
      return true;
    },
    drop: (item, monitor) => {
      const product = (item as any).object as WallStorageBuilderProductCell;

      switch (item.type) {
        case `${WallStorageBuilderType.MountingPlate}`: {
          product.x = props.x;
          product.y = props.y;

          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/ADD', payload: [product] });
          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
          addToast(product.name, 'packout.productadded');
          ga('packoutwall', 'product-added', { articleNumber: product.articleNumber });
          if (packoutServer) {
            packoutServer.emit('product-added', product.articleNumber);
          }
          break;
        }

        case `${WallStorageBuilderType.MountingPlate}_MOVE`: {
          dispatch<WallStorageBuilderActionTypes>({
            type: 'WALL_STORAGE_BUILDER/MOVE_MOUNTING_PLATE',
            payload: {
              cellGuid: product.cellGuid,
              x: props.x,
              y: props.y,
            },
          });
          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
          break;
        }
      }
    },
    collect: monitor => ({
      isHovering: monitor.isOver(),
    }),
  });

  const cellClassNames = classNames({
    wsb__cell: true,
    'wsb__cell--placeholder': true,
    active: isHovering,
    hovering: hoveringProduct !== null,
  });

  if (!hoveringProduct || hoveringProduct.productType !== WallStorageBuilderType.MountingPlate) {
    return null;
  }

  if (props.x + hoveringProduct.productWidth > props.gridColumns + 1) {
    return null;
  }

  if (props.y + hoveringProduct.productHeight > props.gridRows + 1) {
    return null;
  }

  return (
    <div
      key={`placeholder-${props.x}/${props.y}`}
      ref={dropRef}
      className={cellClassNames}
      style={{
        gridArea: `${props.y} / ${props.x} / ${props.y + hoveringProduct.productHeight} / ${props.x + hoveringProduct.productWidth}`,
      }}
    >
      <img src={ProductHelper.transformImageUrl(hoveringProduct.placeholderImageUrl)} />
    </div>
  );
};

export default WallStorageBuilderPlaceholder;

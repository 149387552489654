import React from "react";

const QuestonMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.981"
    height="13.991"
    viewBox="0 0 7.981 13.991"
  >
    <path
      id="e29b"
      d="M13.052,9.249A3.073,3.073,0,0,0,13.991,7a3.842,3.842,0,0,0-1.174-2.817A3.842,3.842,0,0,0,10,3,3.842,3.842,0,0,0,7.183,4.178,3.842,3.842,0,0,0,6.009,7H7.981a1.935,1.935,0,0,1,.61-1.408,1.93,1.93,0,0,1,2.817,0,1.93,1.93,0,0,1,0,2.817L10.188,9.671a4.052,4.052,0,0,0-1.174,2.817V13h1.972a4.052,4.052,0,0,1,1.174-2.817ZM10.986,17V15.023H9.014V17Z"
      transform="translate(-6.009 -3.005)"
      fill="currentcolor"
    />
  </svg>
);

export default QuestonMark;

export enum ProductType {
  Stack = 0,
  WallStorage = 1,
}

export enum Type {
  base = 0,
  middle = 1,
  top = 2,
}

export enum BaseType {
  single = 0,
  double = 1,
  layered = 2,
}

export enum Width {
  half = 1,
  full = 2,
}

export enum Height {
  thin = 1,
  normal = 2,
  tall = 3,
  medium = 4,
}

export interface Point {
  x: number;
  y: number;
}

export interface BaseProduct {
  id: string;
  name: string;
  imageUrl: string;
  imageTopUrl?: string;
  listImageUrl: string;
  agilityId: number;
  articleNumber: string;
  productUrl: string;
  order: number;
  isInventoryOnly?: boolean;
}

export interface StackProduct extends BaseProduct {
  productType: Type;
  productBaseType: BaseType;
  productHeight: Height;
  productWidth: Width;
  pixelHeight: number;
  pixelHeightBottom?: number;
  pixelLimitBottom?: number;
  lockToHeight?: boolean;
  pixelOffset?: number;
}

export interface WallStorageBuilderProduct extends BaseProduct {
  placeholderImageUrl: string;
  productType: WallStorageBuilderType;
  productHeight: number;
  productWidth: number;
  mountsX: number;
  mountsY: number;
  offsetX: number;
  offsetY: number;
  scale: number;
}

export interface PdfProductQuantity {
  product: BaseProduct;
  quantity: number;
}

export enum WallStorageBuilderType {
  MountingPlate = 0,
  Product = 1,
}

export enum PackoutEntryType {
  Stack = 0,
  WallStorageBuilder = 1,
}

export interface ProductCategory<T extends BaseProduct> {
  products: T[];
  name: string;
  codeName: string;
  order: number;
}

export interface WallStorageBuilderProductCell extends WallStorageBuilderProduct {
  cellGuid: string;
  mountCellGuid?: string;
  x: number;
  y: number;
  relativeX?: number;
  relativeY?: number;
  addedAt?: number;
}

export enum Direction {
  LEFT = 0,
  UP = 1,
  RIGHT = 2,
  DOWN = 3,
}

export interface ProductAsset {
  assetUrl: string;
  assetNumber: string;
  assetType: AgilityAssetType;
  associatedAgilityId: number;
}

export enum AgilityAssetType {
  WebHero,
  WebApplication,
  WebFeature,
  WebPack,
  MasterPack,
}

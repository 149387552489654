import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import Logo from '@img/svg/svg--logo-text.svg';
import PackoutLogo from '@img/svg/svg--packout.svg';
import { Site, Products, Constants } from '@core/types';
import { LocalStorage, localKeys, SessionStorage, sessionKeys } from '@core/storage';
import { IFetchTranslationsResponse } from '@core/types/api';
import { WallStorageBuilderProductCell } from '@core/types/products';

interface Props {
  site: Site.ISite;
}

const ItemWithForcedOldCSSProperty: FunctionComponent = ({ children }) => {
  const liRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    if (liRef.current) {
      liRef.current.setAttribute('style', 'page-break-inside: avoid;');
    }
  }, [liRef]);

  return (
    <>
      <li ref={liRef} className="product-item product-item-alt">
        {children}
      </li>
    </>
  );
};

const PdfWall: FunctionComponent<Props> = ({ site }) => {
  const [triggerChange, setTriggerChange] = useState<boolean>(false);
  const hasChanged = useRef<boolean>(false);
  const [translations, setTranslations] = useState<Record<string, string>>();
  const products = LocalStorage.get<WallStorageBuilderProductCell[]>(localKeys.wallStorageBuilder);

  // Used to bind events which trigger re-renders when the local storage is updated
  useEffect(() => {
    window.addEventListener('storage', () => {
      if (hasChanged.current === false) {
        hasChanged.current = true;
        setTriggerChange(true);
      } else {
        hasChanged.current = false;
        setTriggerChange(false);
      }
    });
  }, []);

  // Used to update our local state when the local storage has updated
  useEffect(() => {
    const translationsCacheKey = `${sessionKeys.translations}|${site.cultureCode}`;
    const response = SessionStorage.get<IFetchTranslationsResponse>(translationsCacheKey);

    setTranslations(response?.Localisations);
  }, [triggerChange]);

  useEffect(() => {}, []);

  // Checks for data
  if (products === undefined || products.length === 0 || translations === undefined) {
    return null;
  }

  const productsWithQuantities: Products.PdfProductQuantity[] = [];

  for (const p of products) {
    const existingIndex = productsWithQuantities.findIndex(x => x.product.agilityId === p.agilityId);

    if (existingIndex === -1) {
      productsWithQuantities.push({
        product: p,
        quantity: 1,
      });
    } else {
      productsWithQuantities[existingIndex].quantity += 1;
    }
  }

  return (
    <div className="a4 pdf">
      <div className="pdf-wide pdf-header pdf-header-alt">
        <img className="pdf-header__logo" alt="" src={Logo} />
        <img className="pdf-header__logo" alt="" src={PackoutLogo} />
      </div>
      <div className="pdf-wide">
        <div
          className="product__image product__image-alt product__image-alt--bg"
          style={{ backgroundImage: `url("${Constants.PDF_IMAGE_REPLACEMENT}")` }}
        ></div>
        <div className="product product-alt">
          <h2>{translations['packout.yoursystem.wall']}</h2>
          <div className="product__split">
            <ul className="product__rows">
              {productsWithQuantities.map(group => (
                <ItemWithForcedOldCSSProperty key={group.product.articleNumber}>
                  <div className="product-item__row">
                    <div className="product-item__article">
                      {group.product.articleNumber} - {group.product.name}
                    </div>
                  </div>
                  <div className="product-item__row">
                    <div className="product-item__quantity">
                      {translations['quantity']} - <span>{group.quantity}</span>
                    </div>
                  </div>
                </ItemWithForcedOldCSSProperty>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfWall;

import { FETCH_TRANSLATIONS, FETCH_TRANSLATIONS_SUCCESS, FETCH_TRANSLATIONS_ERROR } from './actions';
import { ApiService } from '@core/services';
import { Api } from '@core/types';
import { Dispatch } from 'redux';
import { keys } from '@core/translations';
import { sessionKeys } from '@core/storage';

export const fetchTranslations = (cultureCode: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_TRANSLATIONS,
  });

  try {
    const requestParams = {
      method: Api.Method.POST,
      controller: 'localisation',
      slug: 'localisations',
      params: {
        cultureCode,
        keys,
      },
      cacheKey: `${sessionKeys.translations}|${cultureCode}`,
    };

    const response = await ApiService.request<Api.IFetchTranslationsResponse>(requestParams);

    dispatch({
      type: FETCH_TRANSLATIONS_SUCCESS,
      payload: response.Localisations,
    });
  } catch (e) {
    dispatch({
      type: FETCH_TRANSLATIONS_ERROR,
      payload: e,
    });
  }
};

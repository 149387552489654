import React, { createContext, FunctionComponent, useContext } from 'react';

interface WallStorageBuilderContextProps {
  isWSB?: boolean;
}

const initialState: WallStorageBuilderContextProps = {
  isWSB: false,
};

const WallStorageBuilderContext = createContext<WallStorageBuilderContextProps>(initialState);

export const WallStorageBuilderProvider: FunctionComponent<WallStorageBuilderContextProps> = ({ isWSB = true, children }) => {
  return (
    <WallStorageBuilderContext.Provider
      value={{
        isWSB,
      }}
    >
      {children}
    </WallStorageBuilderContext.Provider>
  );
};

const useWallStorageBuilder = () => useContext(WallStorageBuilderContext);

export default useWallStorageBuilder;

import { Products, Builder } from '@core/types';
import { StackProduct } from '@core/types/products';

export const ADD_PRODUCT = 'INVENTORY/ADD_PRODUCTS';
export const REMOVE_PRODUCT = 'INVENTORY/REMOVE_PRODUCTS';
export const CLEAR_PRODUCTS = 'INVENTORY/CLEAR_PRODUCTS';
export const SUBMIT = 'INVENTORY/SUBMIT';
export const SUBMIT_SUCCESS = 'INVENTORY/SUBMIT_SUCCESS';
export const SUBMIT_ERROR = 'INVENTORY/SUBMIT_ERROR';

interface AddProducts {
  type: typeof ADD_PRODUCT;
  payload: {
    product: Products.StackProduct;
    addType: Builder.AddType;
    categories: Products.ProductCategory<StackProduct>[];
    position?: Builder.LayerPosition;
    preserveSession?: boolean | undefined;
  };
}

interface RemoveProducts {
  type: typeof REMOVE_PRODUCT;
  payload: {
    layerIndex: number;
    productIndex: number;
    removeType: Builder.RemoveType;
    position?: Builder.LayerPosition;
    isInventoryOnly?: boolean;
    preserveSession?: boolean | undefined;
  };
}

interface ClearProducts {
  type: typeof CLEAR_PRODUCTS;
  payload: {
    preserveSession?: boolean | undefined;
  };
}

interface Submit {
  type: typeof SUBMIT;
}

interface SubmitSuccess {
  type: typeof SUBMIT_SUCCESS;
}

interface SubmitError {
  type: typeof SUBMIT_ERROR;
  payload: string;
}

type InventoryActionTypes = AddProducts | RemoveProducts | ClearProducts | Submit | SubmitSuccess | SubmitError;

export default InventoryActionTypes;

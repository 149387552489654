type GA4Event = 'entry' | 'product-added' | 'product-removed';

type GA4EventType = 'packoutstack' | 'packoutwall';

interface BaseGA4Event {
  event: `${GA4EventType}_${GA4Event}`;
}

export function ga(type: GA4EventType, event: GA4Event, parameters?: Record<string, string | number>): void {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  const request: BaseGA4Event = {
    event: `${type}_${event}`,
    ...parameters,
  };

  window.dataLayer.push(request);
}

import { Cross } from '@components/icons';
import { InventoryCard } from '@components/products';
import { Translate } from '@components/translations';
import { ProductHelper } from '@core/helpers';
import { ga } from '@core/helpers/ga';
import { AppActionTypes } from '@core/redux/app/actions';
import { removeProduct } from '@core/redux/inventory/actioncreators';
import InventoryActionTypes from '@core/redux/inventory/actions';
import { ProductService } from '@core/services';
import { Builder } from '@core/types';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';

const Summary = () => {
  const { products, inventory, app } = useSelector((x: RootState) => x);
  const dispatch = useDispatch();
  const { packoutServer } = usePackoutServer();

  const submitPackoutServer = () => {
    ProductHelper.collectArticleNumbers(packoutServer, products, inventory, articleNumbers => {
      dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: false });
      if (packoutServer) {
        packoutServer.emit('submit-basket', articleNumbers);
      }
      ga('packoutstack', 'entry');
    });
  };

  const renderProducts = () => {
    const layers = inventory.layers.slice().reverse();

    if (layers.length === 0) {
      return (
        <div className="inv-nav__empty">
          <Translate resourceString="packout.noproducts" />
        </div>
      );
    }

    return (
      <SimpleBar style={{ height: '100%' }}>
        {layers.map((x, index) => {
          switch (x.type) {
            case Builder.LayerType.full: {
              const layer = x as Builder.FullLayer;
              const product = ProductService.getByAgilityId(layer.agilityId, products.categories);
              const canRemove = index === 0;
              // Get the actual index as we looping backwards
              const actualIndex = layers.length - 1 - index;

              if (product === undefined) {
                return null;
              }

              return (
                <InventoryCard
                  key={index}
                  model={product}
                  disabled={!canRemove}
                  onClick={() => {
                    dispatch<InventoryActionTypes>(
                      removeProduct(
                        actualIndex,
                        0,
                        Builder.RemoveType.full,
                        undefined,
                        undefined,
                        packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                      ),
                    );
                    ga('packoutstack', 'product-removed', { articleNumber: product.articleNumber });

                    if (packoutServer) {
                      packoutServer.emit('product-removed', product.articleNumber);
                    }
                  }}
                />
              );
            }

            case Builder.LayerType.half: {
              const layer = x as Builder.HalfLayer;
              const leftProducts = layer.leftAgilityIds
                .slice()
                .reverse()
                .map(z => ProductService.getByAgilityId(z, products.categories));

              const rightProducts = layer.rightAgilityIds
                .slice()
                .reverse()
                .map(z => ProductService.getByAgilityId(z, products.categories));

              const canRemove = index === 0;
              // Get the actual index as we looping backwards
              const actualIndex = layers.length - 1 - index;

              return (
                <Fragment key={index}>
                  {leftProducts.map((leftProduct, leftIndex) => {
                    if (leftProduct === undefined) {
                      return null;
                    }

                    const actualLeftIndex = leftProducts.length - 1 - leftIndex;

                    return (
                      <InventoryCard
                        key={`${index}:${leftProduct.agilityId}:${leftIndex}`}
                        model={leftProduct}
                        onClick={() => {
                          dispatch<InventoryActionTypes>(
                            removeProduct(
                              actualIndex,
                              actualLeftIndex,
                              Builder.RemoveType.left,
                              undefined,
                              undefined,
                              packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                            ),
                          );
                        }}
                        disabled={!canRemove}
                      />
                    );
                  })}

                  {rightProducts.map((rightProduct, rightIndex) => {
                    if (rightProduct === undefined) {
                      return null;
                    }

                    const actualRightIndex = rightProducts.length - 1 - rightIndex;

                    return (
                      <InventoryCard
                        key={`${index}:${rightProduct.agilityId}:${rightIndex}`}
                        model={rightProduct}
                        onClick={() => {
                          dispatch<InventoryActionTypes>(
                            removeProduct(
                              actualIndex,
                              actualRightIndex,
                              Builder.RemoveType.right,
                              undefined,
                              undefined,
                              packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                            ),
                          );
                        }}
                        disabled={!canRemove}
                      />
                    );
                  })}
                </Fragment>
              );
            }

            default: {
              return null;
            }
          }
        })}
      </SimpleBar>
    );
  };

  return (
    <div className={`summary ${app.summaryOpen ? 'active' : ''}`}>
      <div className="summary__inner">
        <div className="summary__close" onClick={() => dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: false })}>
          <Cross colour="#000" />
        </div>
        <div className="summary__title">
          <h2>
            <Translate resourceString="packout.yoursystem" />
          </h2>
        </div>
        <div className="summary__products">{renderProducts()}</div>
        <div className="summary__footer">
          <button type="button" className="btn btn--black btn--inline btn--small" onClick={() => submitPackoutServer()}>
            <Translate resourceString="packout.add-to-basket" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Summary;

import { ApiService } from '@core/services';
import { Api, Builder, Constants, Products } from '@core/types';
import { PackoutEntryType, StackProduct } from '@core/types/products';
import html2canvas from 'html2canvas';
import { Dispatch } from 'redux';
import InventoryActionTypes, { ADD_PRODUCT, CLEAR_PRODUCTS, REMOVE_PRODUCT, SUBMIT, SUBMIT_ERROR, SUBMIT_SUCCESS } from './actions';

export function addProduct(
  product: Products.StackProduct,
  categories: Products.ProductCategory<StackProduct>[],
  addType: Builder.AddType = Builder.AddType.auto,
  position: Builder.LayerPosition = Builder.LayerPosition.front,
  preserveSession: boolean = false,
): InventoryActionTypes {
  return {
    type: ADD_PRODUCT,
    payload: { product, addType, categories, position, preserveSession },
  };
}

export function removeProduct(
  layerIndex: number,
  productIndex: number,
  removeType: Builder.RemoveType,
  position: Builder.LayerPosition = Builder.LayerPosition.front,
  isInventoryOnly?: boolean,
  preserveSession: boolean = false,
): InventoryActionTypes {
  return {
    type: REMOVE_PRODUCT,
    payload: {
      layerIndex,
      productIndex,
      removeType,
      position,
      isInventoryOnly,
      preserveSession,
    },
  };
}

export function clearProducts(preserveSession: boolean = false): InventoryActionTypes {
  return {
    type: CLEAR_PRODUCTS,
    payload: { preserveSession },
  };
}

export const submit = (request: Api.ISubmitInventoryRequest) => async (dispatch: Dispatch) => {
  dispatch({
    type: SUBMIT,
  });

  // Create an API request model
  const inventoryRequest: Api.IRequest = {
    method: Api.Method.POST,
    slug: 'submit-inventory',
    isFileUpload: true,
    params: {
      cultureCode: request.cultureCode,
      inventoryData: request.inventoryData,
      logs: getLogs(),
      type: PackoutEntryType.Stack,
    },
  };

  try {
    // add the blob to the params
    const blob = await getPackoutBlob();

    if (blob === null) {
      throw new Error('Unable to convert blob');
    }

    // Update the request params
    inventoryRequest.params.file = blob;

    // submit the inventory data with the image blob
    const inventoryResponse = await ApiService.request<Api.ISubmitInventoryResponse>(inventoryRequest);

    // Add the iframe PDF HTML
    const iFrame = document.querySelector('#pdf') as HTMLIFrameElement;

    // Get the HTML
    let pdfHtml = iFrame.contentDocument?.documentElement.outerHTML || '';

    // Replace the image
    pdfHtml = pdfHtml.replace(Constants.PDF_IMAGE_REPLACEMENT, inventoryResponse.imageUrl);

    // Create a PDF Request
    const pdfRequest: Api.IRequest = {
      method: Api.Method.POST,
      slug: 'submit-pdf',
      params: {
        entryGuid: inventoryResponse.entryGuid,
        pdfHtml,
        pdfBaseUrl: window.origin,
      },
    };

    // Then submit the PDF data
    const pdfResponse = await ApiService.request<Api.ISubmitPDFRequest>(pdfRequest);

    // dispatch a success
    dispatch({
      type: SUBMIT_SUCCESS,
    });
    const url = `${request.redirectUrl}?id=${inventoryResponse.entryGuid}`;
    window.location.href = url;
  } catch (e) {
    dispatch({
      type: SUBMIT_ERROR,
      payload: e,
    });
  }
};

function getPackoutBlob(): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    // Get the offscreen stack element
    const stack = document.querySelector('#stack-offscreen') as HTMLElement;

    if (stack !== null) {
      // initialise html2canvas
      html2canvas(stack, { backgroundColor: 'transparent', useCORS: true }).then((canvas: HTMLCanvasElement) => {
        canvas.classList.add('canvas-offscreen');
        document.body.appendChild(canvas);

        // create a blob from the canvas
        canvas.toBlob(blob => {
          if (blob === null) {
            reject(null);
          }

          // return the blob
          resolve(blob);

          // const url = window.URL.createObjectURL(blob);
          // const link = document.createElement('a'); // Or maybe get it from the current document
          // link.href = url;
          // link.download = 'test.png';
          // link.click();
        });
      });
    }
  });
}

export function getWallBuilderBlob(): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    // Get the offscreen stack element
    const stack = document.querySelector('.wsb__builder--pdf') as HTMLElement;

    if (stack !== null) {
      // initialise html2canvas
      html2canvas(stack, { backgroundColor: 'transparent', useCORS: true }).then((canvas: HTMLCanvasElement) => {
        canvas.classList.add('canvas-offscreen');
        document.body.appendChild(canvas);

        // create a blob from the canvas
        canvas.toBlob(blob => {
          if (blob === null) {
            reject(null);
          }

          // return the blob
          resolve(blob);

          // const url = window.URL.createObjectURL(blob);
          // const link = document.createElement('a'); // Or maybe get it from the current document
          // link.href = url;
          // link.download = 'test.png';
          // link.click();
        });
      });
    }
  });
}

export function getLogs() {
  const navigator = window.navigator as any;
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

  const screen = window.screen as any;
  const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;

  const logs: Record<string, string | number> = {
    deviceWidth: window.screen.width,
    deviceHeight: window.screen.height,
    devicePixelRatio: window.devicePixelRatio,
    userAgent: navigator.userAgent,
    webdriver: navigator.webdriver,
    appName: navigator.appName,
    appCodeName: navigator.appCodeName,
    appVersion: navigator.appVersion,
    platform: navigator.platform,
  };

  if (orientation) {
    logs['orientation'] = orientation.type;
  }

  if (connection) {
    logs['networkSpeed'] = connection.effectiveType;
  }

  return JSON.stringify(logs);
}

import { Loader } from '@components/shared';
import { States } from '@core/types';
import { IntroJSProvider } from '@hooks/useIntroJs';
import usePackoutServer from '@hooks/usePackoutServer';
import { ToastProvider } from '@hooks/useToast';
import { WallStorageBuilderProvider } from '@hooks/useWallStorageBuilder';
import { AppActionCreators } from '@redux/app';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import PageRouter from './PageRouter';

interface IProps {
  app?: States.AppState;
  fetchSites: Function;
}

const CultureRouter: FC<IProps> = ({ app, fetchSites }) => {
  const { packoutServer, isReady } = usePackoutServer();

  useEffect(() => {
    fetchSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!app || app.sitesLoading || app.sites.length === 0 || (packoutServer && !isReady)) {
    return <Loader showContainer={true} />;
  }

  return (
    <ToastProvider>
      {/* Grim check but I don't know how else to check we're on the WSB page at this high of a level */}
      <WallStorageBuilderProvider isWSB={typeof window !== 'undefined' && window.location.pathname.includes('wall-storage-builder')}>
        <IntroJSProvider app={app}>
          <Switch>
            {app.sites.map(x => {
              const url = x.cultureAlias ? x.cultureAlias.toLowerCase() : x.cultureCode.toLowerCase();

              return (
                <Route key={url} path={`/${url}/`}>
                  <PageRouter site={x} baseUrl={url} />
                </Route>
              );
            })}
            <Redirect to={'/eu/'}></Redirect>
          </Switch>
        </IntroJSProvider>
      </WallStorageBuilderProvider>
    </ToastProvider>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
});

const mapDispatchToProps = {
  fetchSites: () => AppActionCreators.fetchSites(),
};

export default connect(mapStateToProps, mapDispatchToProps)(CultureRouter);

import { history } from '@core/helpers/history';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import createRootReducer from '../reducers';

const composeEnhancers = composeWithDevTools || compose;

const rootReducer = createRootReducer(history);

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(reduxThunk)));

export default store;

import { Storage } from '@core/types';

export const localKeys = {
  builder: process.env.REACT_APP_BUILDER_KEY || '',
  builderBack: process.env.REACT_APP_BUILDER_KEY + '_BACK' || '',
  inventoryOnly: process.env.REACT_APP_BUILDER_KEY + '_INVENTORY_ONLY' || '',
  wallStorageBuilder: process.env.REACT_APP_WALL_STORAGE_BUILDER_KEY || '',
  wallStorageBuilderInventoryOnly: process.env.REACT_APP_WALL_STORAGE_BUILDER_KEY + '_INVENTORY_ONLY' || '',
  wallStorageBuilderWidth: process.env.REACT_APP_WALL_STORAGE_BUILDER_WIDTH_KEY || '',
  wallStorageBuilderHeight: process.env.REACT_APP_WALL_STORAGE_BUILDER_HEIGHT_KEY || '',
};

class LocalStorage implements Storage.IStorage {
  /**
   * Gets a value using the given key from local storage
   * @param {string} key
   * @returns undefined if not found
   */
  get<T>(key: string): T | undefined {
    const value = localStorage.getItem(key);

    if (value === null || value === undefined) {
      return undefined;
    }

    return JSON.parse(value) as T;
  }

  /**
   * Sets a value in local storage.
   * @param {string} key
   * @param {*} value
   */
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Removes an item from local storage
   * @param key key
   */
  delete(key: string) {
    localStorage.removeItem(key);
  }
}

export default new LocalStorage();

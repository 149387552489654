import { AppActionCreators } from '@redux/app';
import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SimpleBar from 'simplebar-react';

import { BuilderService, ProductService } from '@core/services';
import { Builder as BuilderTypes, Products, States } from '@core/types';

import { Loader } from '@components/shared';
import { Translate } from '@components/translations';
import { ProductHelper } from '@core/helpers';
import usePackoutServer from '@hooks/usePackoutServer';
import Drop from './Drop';
import Footer from './Footer';
import Header from './Header';
import InfoPopup from '@components/products/InfoPopup';

interface IProps {
  app?: States.AppState;
  products?: States.ProductState;
  inventory: States.InventoryState;
  offScreen?: boolean;
  translations?: States.TranslationsState;
  setZoom: Function;
}

const Builder: FC<IProps> = ({ offScreen = false, app, translations, inventory, products, setZoom }) => {
  const builderScrollRef = useRef<any>();
  const { packoutServer } = usePackoutServer();
  const [imageSizes, setImageSizes] = useState<Record<number, number>>({});
  const [baseSize, setBaseSize] = useState<number>(0);
  const scaleFactor = offScreen === true ? 2 : 1;

  useEffect(() => {
    if (!builderScrollRef || !builderScrollRef.current) {
      return;
    }

    builderScrollRef.current.recalculate();
  }, [app && app.zoom]);

  useEffect(() => {
    if (window.matchMedia('(max-height: 800px)').matches) {
      setZoom(0.6);
    }
  }, [setZoom]);

  // Show Loader when we don't have any products
  if (!inventory || !products || products.isLoading || !app || !translations || translations.isLoading) {
    return <Loader showContainer={true} resourceString="packout.loadingproducts" />;
  }

  // Show modal spinner when we are submitting
  if (inventory.isSubmitting && offScreen === false) {
    return (
      <div className={`builder ${packoutServer ? 'builder--full' : ''}`}>
        <Loader showContainer={true} resourceString="packout.submitting"></Loader>
      </div>
    );
  }

  // Show success after submitting
  if (inventory.hasSubmitted && offScreen === false) {
    return (
      <div className={`builder ${packoutServer ? 'builder--full' : ''}`}>
        <Loader showContainer={true} resourceString="packout.submitted"></Loader>
      </div>
    );
  }

  // Slice first to create a copy
  const allLayers = ProductService.getAllLayers(inventory.layers);

  const stackStyles = {
    transform: `scale(${app.zoom})`,
  };

  const offscreenStackStyles = {
    minHeight: `${baseSize}px`,
  };

  const handleImageLoad = (image: HTMLImageElement, product: Products.StackProduct) => {
    const newImageSizes = { ...imageSizes };

    // If we're base then handle separately
    if (product.productType === Products.Type.base) {
      setBaseSize(image.height);
    }

    newImageSizes[product.agilityId] = image.height;

    setImageSizes(newImageSizes);
  };

  const stackType = ProductService.getStackType(inventory.layers, products.categories);

  const renderBuilder = () => {
    const productCount = ProductService.getByLayers(inventory.layers, products.categories).length;

    if (productCount === 0) {
      return (
        <div className="builder__inner builder__inner--no-items">
          <p>
            <Translate resourceString="packout.noproducts" />
          </p>
        </div>
      );
    }

    const getBuilderInner = (offscreen?: boolean) => {
      return (
        <>
          {Object.keys(allLayers).map(position => {
            const castPosition = Number(position) as BuilderTypes.LayerPosition;
            const layers = allLayers[castPosition];
            const baseLayer = BuilderService.getLayersByType(inventory.layers, BuilderTypes.LayerPosition.front)[0];
            const baseProduct = baseLayer ? ProductService.getByFullLayer(baseLayer as BuilderTypes.FullLayer, products.categories) : undefined;

            if (layers.length === 0) {
              return <></>;
            }

            const Inner = (
              <>
                {layers.reverse().map((x, index) => {
                  const actualIndex = layers.length - index;

                  switch (x.type) {
                    case BuilderTypes.LayerType.full:
                      return renderFullLayer(
                        x as BuilderTypes.FullLayer,
                        actualIndex,
                        layers.length,
                        products,
                        imageSizes,
                        handleImageLoad,
                        scaleFactor,
                        castPosition === BuilderTypes.LayerPosition.front ? app.hideFrontStack : undefined,
                        offscreen,
                        false,
                        castPosition === BuilderTypes.LayerPosition.front && stackType === BuilderTypes.StackType.layered,
                      );

                    case BuilderTypes.LayerType.half:
                      return renderHalfLayer(
                        x as BuilderTypes.HalfLayer,
                        actualIndex,
                        layers.length,
                        products,
                        imageSizes,
                        handleImageLoad,
                        scaleFactor,
                        castPosition === BuilderTypes.LayerPosition.front ? app.hideFrontStack : undefined,
                        offscreen,
                        castPosition === BuilderTypes.LayerPosition.front && stackType === BuilderTypes.StackType.layered,
                      );

                    default:
                      return null;
                  }
                })}
                {castPosition === BuilderTypes.LayerPosition.top && stackType === BuilderTypes.StackType.layered && baseLayer && (
                  <>
                    {/* Need to display the top slice graphic to get the right overlap */}
                    {renderFullLayer(
                      baseLayer as BuilderTypes.FullLayer,
                      0,
                      layers.length,
                      products,
                      imageSizes,
                      handleImageLoad,
                      scaleFactor,
                      undefined,
                      offscreen,
                      true,
                    )}
                  </>
                )}
                {castPosition === BuilderTypes.LayerPosition.front &&
                  stackType === BuilderTypes.StackType.layered &&
                  baseLayer &&
                  baseProduct &&
                  allLayers[BuilderTypes.LayerPosition.top].length === 0 && (
                    <div
                      style={{
                        zIndex: 100,
                        order: -2,
                        marginBottom: baseProduct.pixelLimitBottom
                          ? (offscreen ? baseProduct.pixelLimitBottom * scaleFactor : baseProduct.pixelLimitBottom) -
                            BuilderService.getHeightOfBottomStack(inventory.layers, products.categories, scaleFactor, imageSizes, offscreen)
                          : 0,
                      }}
                    >
                      {/* Need to display the top slice graphic to get the right overlap */}
                      {renderFullLayer(
                        baseLayer as BuilderTypes.FullLayer,
                        0,
                        layers.length,
                        products,
                        imageSizes,
                        handleImageLoad,
                        scaleFactor,
                        undefined,
                        offscreen,
                        true,
                      )}
                    </div>
                  )}
              </>
            );

            switch (castPosition) {
              default:
              case BuilderTypes.LayerPosition.front:
                return <>{Inner}</>;
              case BuilderTypes.LayerPosition.back:
                return (
                  <TransitionGroup className="stack stack--back" id="stackBack">
                    {Inner}
                  </TransitionGroup>
                );
              case BuilderTypes.LayerPosition.top:
                return (
                  <TransitionGroup
                    className="stack stack--top"
                    id="stackTop"
                    style={{
                      marginBottom: baseProduct
                        ? baseProduct.pixelLimitBottom
                          ? (offscreen ? baseProduct.pixelLimitBottom * scaleFactor : baseProduct.pixelLimitBottom) -
                            BuilderService.getHeightOfBottomStack(inventory.layers, products.categories, scaleFactor, imageSizes, offscreen)
                          : 0
                        : 0,
                    }}
                  >
                    {Inner}
                  </TransitionGroup>
                );
            }
          })}
        </>
      );
    };

    if (offScreen) {
      return (
        <div className="builder__inner">
          <div
            className={`stack ${stackType === BuilderTypes.StackType.double ? 'offscreen-double-base' : ''}`}
            id="stack-offscreen"
            style={offscreenStackStyles}
          >
            {getBuilderInner(true)}
          </div>
        </div>
      );
    }

    return (
      <SimpleBar
        data-is-double-base={stackType === BuilderTypes.StackType.double}
        data-is-layered-base={stackType === BuilderTypes.StackType.layered}
        ref={builderScrollRef}
        className="builder__inner"
      >
        <TransitionGroup className="stack" id="stack" style={stackStyles}>
          {getBuilderInner()}
        </TransitionGroup>
        <Drop />
      </SimpleBar>
    );
  };

  return (
    <div id="builder" className={`builder ${packoutServer ? 'builder--full' : ''}`}>
      <Header />
      {renderBuilder()}
      {!offScreen && <InfoPopup />}
      <Footer />
    </div>
  );
};

function renderFullLayer(
  layer: BuilderTypes.FullLayer,
  layerIndex: number,
  totalIndex: number,
  products: States.ProductState,
  imageSizes: Record<number, number>,
  onLoad: (image: HTMLImageElement, product: Products.StackProduct) => void,
  scaleFactor: number,
  hideFrontStack?: boolean,
  isOffscreen?: boolean,
  isTopHalf?: boolean,
  isBottomHalf?: boolean,
) {
  const product = ProductService.getByFullLayer(layer, products.categories);

  if (product === undefined) {
    return null;
  }

  const imageClassNames = classNames({
    'stack__full-wrapper': product.productType !== Products.Type.base,
    stack__image: true,
    'stack__image--base': product.productType === Products.Type.base,
    'stack__image--base--double': product.productType === Products.Type.base && product.productBaseType === Products.BaseType.double,
    stack__transparent: hideFrontStack && product.productType !== Products.Type.base,
  });

  const imageHeight =
    product.pixelHeight * (isOffscreen ? (product.productType === Products.Type.base ? scaleFactor : scaleFactor * 0.75) : scaleFactor);

  const wrapperStyles: React.CSSProperties = {
    zIndex: layerIndex,
    minHeight: `${imageHeight}px`,
  };

  if (layerIndex === totalIndex) {
    wrapperStyles.marginTop = isBottomHalf ? `0px` : `${imageSizes[product.agilityId] - imageHeight}px`;
  }

  return (
    <CSSTransition key={`${layerIndex}:${product.agilityId}`} timeout={400} classNames="slide-in-down">
      <div className={imageClassNames} style={wrapperStyles}>
        <img
          alt={product.name}
          src={ProductHelper.getProductUrl(product, undefined, true, isTopHalf)}
          onLoad={e => onLoad(e.currentTarget, product)}
        />
      </div>
    </CSSTransition>
  );
}

function renderHalfLayer(
  layer: BuilderTypes.HalfLayer,
  layerIndex: number,
  totalIndex: number,
  products: States.ProductState,
  imageSizes: Record<number, number>,
  onLoad: (image: HTMLImageElement, product: Products.StackProduct) => void,
  scaleFactor: number,
  hideFrontStack?: boolean,
  isOffscreen?: boolean,
  isBottomHalf?: boolean,
) {
  const leftProducts: Products.StackProduct[] = [];
  let leftHeight = 0;
  const rightProducts: Products.StackProduct[] = [];
  let rightHeight = 0;

  // Pre-fetch all the left products so we can calculate the height
  // Do this in reverse so they're displayed in the correct order
  layer.leftAgilityIds
    .slice()
    .reverse()
    .forEach((x, index) => {
      const p = ProductService.getByAgilityId(x, products.categories);
      if (p !== undefined) {
        leftProducts.push(p);

        const factor = isOffscreen ? scaleFactor * 0.75 : scaleFactor;

        if (index === layer.leftAgilityIds.length - 1 && layerIndex === totalIndex) {
          leftHeight += imageSizes[p.agilityId] + 10 * factor;
        } else {
          leftHeight += p.pixelHeight * factor;
        }

        if (p.pixelOffset) {
          leftHeight += p.pixelOffset;
        }
      }
    });

  // Pre-fetch all the right products so we can calculate the height
  // Do this in reverse so they're displayed in the correct order
  layer.rightAgilityIds
    .slice()
    .reverse()
    .forEach((x, index) => {
      const p = ProductService.getByAgilityId(x, products.categories);
      if (p !== undefined) {
        rightProducts.push(p);

        const factor = isOffscreen ? scaleFactor * 0.75 : scaleFactor;

        if (index === layer.rightAgilityIds.length - 1 && layerIndex === totalIndex) {
          rightHeight += imageSizes[p.agilityId];
        } else {
          rightHeight += p.pixelHeight * factor;
        }

        if (p.pixelOffset) {
          rightHeight += p.pixelOffset;
        }
      }
    });

  const wrapperStyles: React.CSSProperties = {
    height: Math.max(leftHeight, rightHeight),
    zIndex: layerIndex,
  };

  const key = `${layerIndex}:${leftProducts.map(x => x.agilityId).join(':')}:${rightProducts.map(x => x.agilityId).join(':')}`;

  return (
    <CSSTransition key={key} classNames="fade-in" timeout={400}>
      <div className={`stack__split-wrapper ${hideFrontStack ? 'stack__transparent' : ''}`} style={wrapperStyles}>
        <div className="stack__split stack__split--left">
          {leftProducts.map((p, index) => {
            const leftIndex = leftProducts.length - index;

            const factor = isOffscreen ? scaleFactor * 0.75 : scaleFactor;

            const productStyles: React.CSSProperties = {
              height: `${p.pixelHeight * factor + (p.pixelOffset ?? 0)}px`,
              zIndex: leftIndex,
            };

            if (leftIndex === leftProducts.length) {
              const marginTop = isBottomHalf ? 0 : imageSizes[p.agilityId] - p.pixelHeight;
              productStyles.marginTop = `${marginTop > 0 && !p.lockToHeight ? marginTop : 0}px`;

              if (p.pixelOffset) {
                productStyles.marginBottom = `${p.pixelOffset * factor}px`;
              }
            }

            return (
              <div key={`${layerIndex}:${index}:${p.agilityId}:0`} className="stack__image stack__image--left" style={productStyles}>
                <img
                  alt={p.name}
                  data-lock-height={p.lockToHeight}
                  src={ProductHelper.getProductUrl(p, undefined, true)}
                  onLoad={e => onLoad(e.currentTarget, p)}
                />
              </div>
            );
          })}
        </div>

        <div className="stack__split stack__split--right">
          {rightProducts.map((p, index) => {
            const rightIndex = rightProducts.length - index;
            const factor = isOffscreen ? scaleFactor * 0.75 : scaleFactor;
            const productStyles: React.CSSProperties = {
              height: `${p.pixelHeight * factor + (p.pixelOffset ?? 0)}px`,
              zIndex: rightIndex,
            };

            if (rightIndex === rightProducts.length) {
              const marginTop = isBottomHalf ? 0 : imageSizes[p.agilityId] - p.pixelHeight;
              productStyles.marginTop = `${marginTop > 0 && !p.lockToHeight ? marginTop : 0}px`;

              if (p.pixelOffset) {
                productStyles.marginBottom = `${p.pixelOffset}px`;
              }
            }

            return (
              <div key={`${layerIndex}:${index}:${p.agilityId}:1`} className="stack__image stack__image--right" style={productStyles}>
                <img
                  alt={p.name}
                  data-lock-height={p.lockToHeight}
                  src={ProductHelper.getProductUrl(p, undefined, true)}
                  onLoad={e => onLoad(e.currentTarget, p)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </CSSTransition>
  );
}

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
  products: state.products,
  inventory: state.inventory,
  translations: state.translations,
});

const mapDispatchToProps = {
  setZoom: (zoom: number) => AppActionCreators.setZoom(zoom),
};

export default connect(mapStateToProps, mapDispatchToProps)(Builder);

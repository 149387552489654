import { ProductService } from '@core/services';
import { Products, Builder } from '@core/types';
import { LayerPosition } from '@core/types/builder';
import { StackProduct } from '@core/types/products';

class BuilderService {
  private heightLimit: number;

  constructor() {
    this.heightLimit = 16;
  }

  private getPixelHeight(layers: Builder.BaseLayer[], categories: Products.ProductCategory<StackProduct>[], countLastHalfLayer?: boolean): number {
    let height = 0;
    const filtered = layers.filter(x =>
      x.type === Builder.LayerType.full ? ProductService.getByFullLayer(x as Builder.FullLayer, categories).productType !== Products.Type.base : true,
    );

    for (let i = 0; i < filtered.length; i++) {
      const layer = filtered[i];

      if (layer.type === Builder.LayerType.full) {
        const fullLayer = layer as Builder.FullLayer;
        const product = ProductService.getByAgilityId(fullLayer.agilityId, categories);
        if (product) {
          height += product.pixelHeight;
        }
      } else {
        if (i === filtered.length - 1 && countLastHalfLayer) {
          const halfLayer = layer as Builder.HalfLayer;
          const products = ProductService.getByHalfLayer(halfLayer, categories);
          const tallestHeight = products.map(x => x.pixelHeight).sort((a, b) => b - a)[0];
          height += tallestHeight;
        }
      }
    }

    return height;
  }

  private getHeight(canDropType: Builder.DropType, layers: Builder.BaseLayer[], categories: Products.ProductCategory<StackProduct>[]): number {
    let leftCount = 0;
    let rightCount = 0;

    for (const layer of layers) {
      if (layer.type === Builder.LayerType.full) {
        const fullLayer = layer as Builder.FullLayer;
        const product = ProductService.getByAgilityId(fullLayer.agilityId, categories);

        if (!product) {
          continue;
        }

        switch (product.productHeight) {
          case Products.Height.thin: {
            leftCount += 1;
            rightCount += 1;
            break;
          }

          case Products.Height.normal: {
            leftCount += 2;
            rightCount += 2;
            break;
          }

          case Products.Height.medium: {
            leftCount += 4;
            rightCount += 4;
            break;
          }

          case Products.Height.tall: {
            leftCount += 6;
            rightCount += 6;
            break;
          }
        }

        continue;
      }

      const halfLayer = layer as Builder.HalfLayer;

      const leftProducts = halfLayer.leftAgilityIds.map(x => ProductService.getByAgilityId(x, categories));
      const rightProducts = halfLayer.rightAgilityIds.map(x => ProductService.getByAgilityId(x, categories));

      for (const product of leftProducts) {
        if (!product) {
          continue;
        }

        switch (product.productHeight) {
          case Products.Height.thin: {
            leftCount += 1;
            break;
          }

          case Products.Height.normal: {
            leftCount += 2;
            break;
          }

          case Products.Height.medium: {
            leftCount += 4;
            break;
          }

          case Products.Height.tall: {
            leftCount += 6;
            break;
          }
        }
      }

      for (const product of rightProducts) {
        if (!product) {
          continue;
        }

        switch (product.productHeight) {
          case Products.Height.thin: {
            rightCount += 1;
            break;
          }

          case Products.Height.normal: {
            rightCount += 2;
            break;
          }

          case Products.Height.medium: {
            rightCount += 4;
            break;
          }

          case Products.Height.tall: {
            rightCount += 6;
            break;
          }
        }
      }
    }

    if (canDropType === Builder.DropType.left) {
      return leftCount;
    }

    if (canDropType === Builder.DropType.right) {
      return rightCount;
    }

    if (canDropType === Builder.DropType.both) {
      return Math.min(leftCount, rightCount);
    }

    if (canDropType === Builder.DropType.full) {
      return Math.max(leftCount, rightCount);
    }

    return 0;
  }

  private getHalfHeight(layer: Builder.HalfLayer, categories: Products.ProductCategory<StackProduct>[]): number[] {
    // Check Heights
    let leftHeight = 0;
    let rightHeight = 0;

    for (const agilityId of layer.leftAgilityIds) {
      const leftProduct = ProductService.getByAgilityId(agilityId, categories);

      if (leftProduct === undefined) {
        continue;
      }

      switch (leftProduct.productHeight) {
        case Products.Height.thin: {
          leftHeight += 1;
          break;
        }

        case Products.Height.normal: {
          leftHeight += 2;
          break;
        }

        case Products.Height.medium: {
          leftHeight += 4;
          break;
        }

        case Products.Height.tall: {
          leftHeight += 6;
          break;
        }
      }
    }

    for (const agilityId of layer.rightAgilityIds) {
      const rightProduct = ProductService.getByAgilityId(agilityId, categories);

      if (rightProduct === undefined) {
        continue;
      }

      switch (rightProduct.productHeight) {
        case Products.Height.thin: {
          rightHeight += 1;
          break;
        }

        case Products.Height.normal: {
          rightHeight += 2;
          break;
        }

        case Products.Height.medium: {
          rightHeight += 4;
          break;
        }

        case Products.Height.tall: {
          rightHeight += 6;
          break;
        }
      }
    }

    return [leftHeight, rightHeight];
  }

  private canDropOnHalf(
    product: Products.StackProduct,
    lastLayer: Builder.HalfLayer,
    layers: Builder.BaseLayer[],
    categories: Products.ProductCategory<StackProduct>[],
    canDropType: Builder.DropType,
  ): Boolean {
    const bagLeft = this.getLastBagRecursive(layers, categories, Builder.DropType.left);
    const bagRight = this.getLastBagRecursive(layers, categories, Builder.DropType.right);

    // are we dropping a full?
    switch (product.productWidth) {
      case Products.Width.full: {
        const [leftHeight, rightHeight] = this.getHalfHeight(lastLayer, categories);

        if (leftHeight !== rightHeight) {
          return false;
        }

        if (bagLeft || bagRight) {
          return false;
        }

        return true;
      }

      case Products.Width.half: {
        // Check dropping left ontop of a bag
        if (canDropType === Builder.DropType.left && bagLeft) {
          return false;
        }

        // Check dropping right ontop of a bag
        if (canDropType === Builder.DropType.right && bagRight) {
          return false;
        }

        // check height
        if (this.canFitInHalfLayer(product, lastLayer, canDropType, categories)) {
          return true;
        }

        return true;
      }

      default:
        return false;
    }
  }

  private canDropOnFull(product: Products.StackProduct, lastLayer: Builder.FullLayer, categories: Products.ProductCategory<StackProduct>[]): Boolean {
    // is it full
    if (lastLayer.agilityId === -1) {
      return false;
    }

    const lastProduct = ProductService.getByAgilityId(lastLayer.agilityId, categories);
    if (lastProduct === undefined) {
      return false;
    }

    // is it a bag
    if (lastProduct.productType === Products.Type.top) {
      return false;
    }

    // is it a base
    if (lastProduct.productType === Products.Type.base && product.productType === Products.Type.base) {
      return false;
    }

    return true;
  }

  private canFitInHalfLayer(
    product: Products.StackProduct,
    layer: Builder.HalfLayer,
    canDropType: Builder.DropType,
    categories: Products.ProductCategory<StackProduct>[],
  ): boolean {
    // const leftProduct = ProductService.getByAgilityId(layer.leftAgilityIds, categories);
    // const rightProduct = ProductService.getByAgilityId(layer.rightAgilityIds, categories);

    // if (!leftProduct || !rightProduct) {
    //   return true;
    // }

    // if (leftProduct.productHeight === rightProduct.productHeight) {
    //   return false;
    // }

    // switch (canDropType) {
    //   case Builder.DropType.left: {
    //     if (
    //       leftProduct.productHeight === Products.Height.thin &&
    //       rightProduct.productHeight === Products.Height.normal &&
    //       product.productHeight === Products.Height.thin
    //     ) {
    //       return true;
    //     }

    //     break;
    //   }

    //   case Builder.DropType.right: {
    //     if (
    //       rightProduct.productHeight === Products.Height.thin &&
    //       leftProduct.productHeight === Products.Height.normal &&
    //       product.productHeight === Products.Height.thin
    //     ) {
    //       return true;
    //     }

    //     break;
    //   }

    //   default:
    //     break;
    // }

    return false;
  }

  getLastBagRecursive(
    layers: Builder.BaseLayer[],
    categories: Products.ProductCategory<StackProduct>[],
    canDropType: Builder.DropType,
  ): Products.StackProduct | undefined {
    let foundBag: Products.StackProduct | undefined;

    // Loop through the layers in reverse (top to bottom)
    for (let index = layers.length - 1; index > 0; index -= 1) {
      const layer = layers[index] as Builder.HalfLayer;

      // still need to make sure we check the type
      if (layer.type === Builder.LayerType.full) {
        break;
      }

      // get the agility id based on the layer we are on
      const agilityIds = canDropType === Builder.DropType.left ? layer.leftAgilityIds : layer.rightAgilityIds;

      for (const agilityId of agilityIds) {
        // check if its a bag
        const productLookup = ProductService.getByAgilityId(agilityId, categories);

        if (foundBag === undefined && productLookup && productLookup.productType === Products.Type.top) {
          foundBag = productLookup;
        }
      }
    }

    return foundBag;
  }

  canDrop(
    product: Products.StackProduct,
    categories: Products.ProductCategory<StackProduct>[],
    layers: Builder.BaseLayer[],
    dropType: Builder.DropType,
    position?: Builder.LayerPosition,
    stackType?: Builder.StackType,
  ) {
    // Is it inventory only? If so always allow it, they don't go on the stack //
    if (product.isInventoryOnly) {
      return true;
    }

    let lastLayer = layers[layers.length - 1];

    // Check empty layer and product type base
    if (lastLayer === undefined) {
      if (product.productType === Products.Type.base || position === Builder.LayerPosition.back || position === Builder.LayerPosition.top) {
        return true;
      }

      return false;
    }

    // fallback check for bases if the first layer isn't empty
    if (product.productType === Products.Type.base) {
      return false;
    }

    // Height logic
    if (stackType === Builder.StackType.layered && position === LayerPosition.front) {
      // Get the pixel height of the front stack & compare to the base limit //
      const base = ProductService.getByLayers(layers, categories).find(x => x.productType === Products.Type.base);
      if (base && base.pixelLimitBottom) {
        const stackPixelHeight = this.getPixelHeight(layers, categories, false);

        // Is the last layer an unfinished half layer? //
        if (lastLayer.type === Builder.LayerType.half) {
          const productsOnHalfLayer = ProductService.getByHalfLayer(lastLayer as Builder.HalfLayer, categories);
          if (productsOnHalfLayer.length === 1) {
            // We only have half a stack, just use the height up until this point //
            if (product.pixelHeight + stackPixelHeight > base.pixelLimitBottom) {
              return false;
            }
          } else {
            // We have a full stack, find the max height product //
            const maxHeight = productsOnHalfLayer.map(x => x.pixelHeight).sort((a, b) => b - a)[0];
            if (product.pixelHeight + (stackPixelHeight + maxHeight) > base.pixelLimitBottom) {
              return false;
            }
          }
        }

        if (product.pixelHeight + stackPixelHeight > base.pixelLimitBottom) {
          return false;
        }
      }
    } else {
      const height = this.getHeight(dropType, layers, categories);
      const productHeight = product.productHeight === Products.Height.normal ? 1 : 0.5;
      if (height + productHeight > this.heightLimit) {
        return false;
      }
    }

    switch (lastLayer.type) {
      // are we dropping on a full?
      case Builder.LayerType.full: {
        if (this.canDropOnFull(product, lastLayer as Builder.FullLayer, categories) === false) {
          return false;
        }

        return true;
      }

      // are we dropping on a half?
      case Builder.LayerType.half: {
        if (this.canDropOnHalf(product, lastLayer as Builder.HalfLayer, layers, categories, dropType) === false) {
          return false;
        }

        return true;
      }

      default:
        return false;
    }
  }

  getDropType(product: Products.StackProduct, layers: Builder.BaseLayer[]): Builder.DropType {
    const lastLayer = layers[layers.length - 1];

    // Check base
    if (product.productType === Products.Type.base) {
      return Builder.DropType.full;
    }

    // Check full width
    if (product.productWidth === Products.Width.full) {
      return Builder.DropType.full;
    }

    // Check for both
    switch (lastLayer.type) {
      case Builder.LayerType.full: {
        const lastFullLayer = lastLayer as Builder.FullLayer;

        if (lastFullLayer.agilityId !== -1) {
          return Builder.DropType.both;
        }
      }

      case Builder.LayerType.half: {
        const lastHalfLayer = lastLayer as Builder.HalfLayer;
        return Builder.DropType.both;
      }

      default:
        return Builder.DropType.none;
    }
  }

  getLastAvailableSpace(
    product: Products.StackProduct,
    layers: Builder.BaseLayer[],
    categories: Products.ProductCategory<StackProduct>[],
    addType: Builder.AddType,
  ): Builder.IPosition | undefined {
    const bagLeft = this.getLastBagRecursive(layers, categories, Builder.DropType.left);
    const bagRight = this.getLastBagRecursive(layers, categories, Builder.DropType.right);

    if (layers.length === 0) {
      return undefined;
    }

    // get the last layer

    // if its a full layer, we want to create a new one

    // else we want to insert into the left or the right

    const index = layers.length - 1;
    let layer = layers[index];

    // If we hit a full layer
    if (layer.type === Builder.LayerType.full) {
      return undefined;
    }

    switch (addType) {
      case Builder.AddType.left: {
        return {
          index,
          type: Builder.AddTypeHalf.left,
        };
      }

      case Builder.AddType.right: {
        return {
          index,
          type: Builder.AddTypeHalf.right,
        };
      }

      case Builder.AddType.auto: {
        const halfLayer = layer as Builder.HalfLayer;
        const [leftHeight, rightHeight] = this.getHalfHeight(halfLayer, categories);

        // prefer to auto go to the left first
        if (leftHeight <= rightHeight || bagRight) {
          if (bagLeft === undefined) {
            return {
              index,
              type: Builder.AddTypeHalf.left,
            };
          }
        }

        // else we fall out to here, where we check right
        if (bagRight === undefined) {
          return {
            index,
            type: Builder.AddTypeHalf.right,
          };
        }

        return undefined;
      }

      default:
        break;
    }

    return undefined;
  }

  getLayersByType(layers: Builder.BaseLayer[], position: LayerPosition): Builder.BaseLayer[] {
    return layers.filter(x => x.position === position) ?? [];
  }

  getHeightOfBottomStack(
    _layers: Builder.BaseLayer[],
    categories: Products.ProductCategory<StackProduct>[],
    scaleFactor: number,
    imageSizes: Record<number, number>,
    isOffscreen?: boolean,
  ): number {
    // Get the bottom layer //
    const layers = this.getLayersByType(_layers, Builder.LayerPosition.front);
    let layeredBaseMinHeight = 0;
    layers
      .filter(x =>
        x.type === Builder.LayerType.full
          ? ProductService.getByFullLayer(x as Builder.FullLayer, categories).productType !== Products.Type.base
          : true,
      )
      .forEach((layer, i) => {
        const isLastLayer = i === layers.length - 2; // 2 to offset the base being in there which we don't count //
        if (layer.type === Builder.LayerType.full) {
          const product = ProductService.getByFullLayer(layer as Builder.FullLayer, categories);
          const height =
            product.pixelHeight * (isOffscreen ? (product.productType === Products.Type.base ? scaleFactor : scaleFactor * 0.75) : scaleFactor);
          // Only one product on this layer, just add the pixel height //
          layeredBaseMinHeight += height;
        } else {
          const castLayer = layer as Builder.HalfLayer;

          let leftHeight = 0;
          let rightHeight = 0;

          // Pre-fetch all the left products so we can calculate the height
          // Do this in reverse so they're displayed in the correct order
          castLayer.leftAgilityIds
            .slice()
            .reverse()
            .forEach((x, index) => {
              const p = ProductService.getByAgilityId(x, categories);
              if (p !== undefined) {
                const factor = isOffscreen ? scaleFactor * 0.75 : scaleFactor;

                if (index === castLayer.leftAgilityIds.length - 1 && isLastLayer) {
                  leftHeight += imageSizes[p.agilityId] + 10 * factor;
                } else {
                  leftHeight += p.pixelHeight * factor;
                }

                if (p.pixelOffset) {
                  leftHeight += p.pixelOffset;
                }
              }
            });

          // Pre-fetch all the right products so we can calculate the height
          // Do this in reverse so they're displayed in the correct order
          castLayer.rightAgilityIds
            .slice()
            .reverse()
            .forEach((x, index) => {
              const p = ProductService.getByAgilityId(x, categories);
              if (p !== undefined) {
                const factor = isOffscreen ? scaleFactor * 0.75 : scaleFactor;

                if (index === castLayer.rightAgilityIds.length - 1 && isLastLayer) {
                  rightHeight += imageSizes[p.agilityId];
                } else {
                  rightHeight += p.pixelHeight * factor;
                }

                if (p.pixelOffset) {
                  rightHeight += p.pixelOffset;
                }
              }
            });

          layeredBaseMinHeight += Math.max(leftHeight, rightHeight);
        }
      });

    return layeredBaseMinHeight;
  }
}

export default new BuilderService();

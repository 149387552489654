import React from 'react';

const Plus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.288 14.288">
    <path
      id="e880"
      d="M14.288,8.567H8.567v5.721H5.721V8.567H0V5.721H5.721V0H8.567V5.721h5.721Z"
      transform="translate(0 14.288) rotate(-90)"
      fill="currentColor"
    />
  </svg>
);

export default Plus;

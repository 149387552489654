import ProductBanner from '@components/landing/ProductBanner';
import SplitImageCards from '@components/landing/SplitImageCards';
import { Navigation } from '@components/navigation';
import { Site } from '@core/types';
import React from 'react';

interface Props {
  site: Site.ISite;
}

const Landing: React.FC<Props> = props => {
  return (
    <main className='landing'>
      <Navigation />

      {props.site.landingPage && (
        <ProductBanner
          product1ImageUrl={props.site.landingPage.bannerImages[0]}
          product2ImageUrl={props.site.landingPage.bannerImages[1]}
          product3ImageUrl={props.site.landingPage.bannerImages[2]}
          product4ImageUrl={props.site.landingPage.bannerImages[3]}
        />
      )}

      {props.site.landingPage && (
        <SplitImageCards
          leftButtonText={props.site.landingPage.stackButtonText}
          leftBackgroundImageUrl={props.site.landingPage.stackBackgroundImage}
          rightButtonText={props.site.landingPage.wallStorageButtonText}
          rightBackgroundImageUrl={props.site.landingPage.wallStorageBackgroundImage}
        />
      )}
    </main>
  );
};

export default Landing;

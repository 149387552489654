import { Chevron } from '@components/icons';
import { InventoryCard } from '@components/products';
import { Translate } from '@components/translations';
import { ga } from '@core/helpers/ga';
import { CustomIntroID } from '@core/introJs';
import { AppActionTypes } from '@core/redux/app/actions';
import { getLogs, getWallBuilderBlob } from '@core/redux/inventory/actioncreators';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { ApiService } from '@core/services';
import { Api, Constants } from '@core/types';
import { PackoutEntryType, WallStorageBuilderProductCell, WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import useIsMasterOrigin from '@hooks/useIsMasterOrigin';
import usePackoutServer from '@hooks/usePackoutServer';
import React, { useMemo } from 'react';
import SimpleBar from 'simplebar-react';
import { useDispatch, useSelector } from 'react-redux';

const WallStorageInventory = () => {
  const dispatch = useDispatch();
  const { width, height, gridWidthUnit, gridHeightUnit, gridRows, gridColumns, products, inventoryProducts, isSubmitting } = useSelector(
    (x: RootState) => x.wallStorageBuilder.present,
  );
  const { navOpen, inventoryOpen, site } = useSelector((x: RootState) => x.app);
  const productCount = products.length;
  const showFooter = true;
  const { packoutServer } = usePackoutServer();
  const isMasterOrigin = useIsMasterOrigin();

  const onSubmit = async () => {
    if (!site) {
      return;
    }

    // Display loader
    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SUBMIT' });

    try {
      // Generate image for the output
      const blob = await getWallBuilderBlob();

      if (blob === null) {
        console.log('Unable to convert blob');
        return;
      }

      // Create an API request model
      const inventoryRequest: Api.IRequest = {
        method: Api.Method.POST,
        slug: 'submit-inventory',
        isFileUpload: true,
        params: {
          cultureCode: site.cultureCode,
          inventoryData: JSON.stringify({ width, height, gridWidthUnit, gridHeightUnit, gridRows, gridColumns, products, inventoryProducts }),
          logs: getLogs(),
          type: PackoutEntryType.WallStorageBuilder,
        },
      };

      // Update the request params
      inventoryRequest.params.file = blob;

      // Submit inventory API
      const inventoryResponse = await ApiService.request<Api.ISubmitInventoryResponse>(inventoryRequest);

      // Submit PDF
      // Add the iframe PDF HTML
      const iFrame = document.querySelector<HTMLIFrameElement>('#pdf');

      if (!iFrame || !iFrame.contentDocument) {
        return;
      }

      // Get the HTML
      let pdfHtml = iFrame.contentDocument.documentElement.outerHTML || '';

      // Replace the image
      pdfHtml = pdfHtml.replace(Constants.PDF_IMAGE_REPLACEMENT, inventoryResponse.imageUrl);

      // Create a PDF Request
      const pdfRequest: Api.IRequest = {
        method: Api.Method.POST,
        slug: 'submit-pdf',
        params: {
          entryGuid: inventoryResponse.entryGuid,
          pdfHtml,
          pdfBaseUrl: window.origin,
        },
      };

      // Then submit the PDF data
      await ApiService.request<Api.ISubmitPDFResponse>(pdfRequest);

      // Fire GA event
      ga('packoutwall', 'entry');

      if (site.wallStorageSubmissionPageUrl) {
        dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SUBMIT_SUCCESS' });
        setTimeout(() => {
          const url = `${site.wallStorageSubmissionPageUrl}?id=${inventoryResponse.entryGuid}`;
          window.location.href = url;
        }, 1000);
      }

      throw new Error('No wall storage submission page set');
    } catch (e) {
      // Clear loader
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_IS_LOADING', payload: false });

      // Reset
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RESET' });
    }
  };

  const groupedProducts = useMemo(() => {
    return products
      .sort((a, b) => a.productType - b.productType)
      .reduce<Record<string, WallStorageBuilderProductCell[]>>((acc, cur) => {
        if (cur.productType === WallStorageBuilderType.MountingPlate) {
          if (!acc[cur.cellGuid]) {
            acc[cur.cellGuid] = [];
          }
        } else if (cur.productType === WallStorageBuilderType.Product && cur.mountCellGuid) {
          acc[cur.mountCellGuid].push(cur);
        }

        return acc;
      }, {});
  }, [products]);

  const renderProducts = () => {
    if (products.length === 0) {
      return (
        <div className="inv-nav__empty">
          <Translate resourceString="packout.noproducts" />
        </div>
      );
    }

    return (
      <SimpleBar style={{ maxHeight: '100%', overflowX: 'hidden' }}>
        {Object.entries(groupedProducts).map(group => {
          const mountingPlate = products.find(x => x.cellGuid === group[0]);
          return (
            <div key={mountingPlate?.cellGuid}>
              {mountingPlate && (
                <InventoryCard
                  model={mountingPlate}
                  disabled={false}
                  subProducts={group[1]}
                  onClick={() => {
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/REMOVE', payload: mountingPlate.cellGuid });
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
                    ga('packoutwall', 'product-removed', { articleNumber: mountingPlate.articleNumber });

                    if (packoutServer) {
                      packoutServer.emit('product-removed', mountingPlate.articleNumber);
                    }
                  }}
                  onSubProductClick={product => {
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/REMOVE', payload: product.cellGuid });
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
                    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
                    ga('packoutwall', 'product-removed', { articleNumber: product.articleNumber });

                    if (packoutServer) {
                      packoutServer.emit('product-removed', product.articleNumber);
                    }
                  }}
                />
              )}
            </div>
          );
        })}
        {inventoryProducts.length > 0 && (
          <>
            <div className={`inv-nav__title`}>
              <h2>
                <Translate resourceString="packout.inventory-only" />
              </h2>
            </div>
            {inventoryProducts.map(product => (
              <InventoryCard
                onClick={() => {
                  dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/REMOVE_FROM_INVENTORY', payload: product });
                  ga('packoutwall', 'product-removed', { articleNumber: product.articleNumber });

                  if (packoutServer) {
                    packoutServer.emit('product-removed', product.articleNumber);
                  }
                }}
                model={product}
                disabled={false}
              />
            ))}
          </>
        )}
      </SimpleBar>
    );
  };

  const handleSetInventory = () => {
    if (packoutServer) {
      return;
    }

    dispatch<AppActionTypes>({ type: 'APP/SET_INVENTORY', payload: !inventoryOpen });
  };

  return (
    <nav
      className={`inv-nav ${inventoryOpen ? 'active' : ''} ${navOpen ? 'hidden' : ''} ${packoutServer ? 'inv-nav--transparent' : ''} ${
        isSubmitting ? 'inv-nav--submitting' : ''
      }`}
      data-id={CustomIntroID.Four}
      data-tablet-id={CustomIntroID.FourTablet}
      data-mobile-id={CustomIntroID.FourMobile}
    >
      <div className={`${packoutServer !== null ? 'until-tablet' : ''}`}>
        <div className={`inv-nav__title ${packoutServer ? 'inv-nav__title--grid' : ''}`} onClick={() => handleSetInventory()}>
          <h2>
            <Translate resourceString="packout.yoursystem" />
          </h2>
          {!packoutServer ? (
            <div className="inv-nav__icon">
              <Chevron />
            </div>
          ) : (
            <button
              type="button"
              className="btn btn--black btn--inline btn--small"
              onClick={() => {
                if (packoutServer.settings.skipSummaryModal) {
                  packoutServer.emit(
                    'submit-basket',
                    products.map(x => x.articleNumber),
                  );

                  ga('packoutstack', 'entry');
                } else {
                  dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: true });
                }
              }}
            >
              <Translate resourceString="packout.add-to-basket" />
            </button>
          )}
        </div>
        <div className={`inv-nav__title inv-nav__title--mobile ${packoutServer ? 'inv-nav__title--grid' : ''}`} onClick={() => handleSetInventory()}>
          <h2>
            <Translate resourceString="packout" /> {`(${productCount})`}
          </h2>
          {!packoutServer ? (
            <div className="inv-nav__icon">
              <Chevron />
            </div>
          ) : (
            <button
              type="button"
              className="btn btn--black btn--inline btn--small"
              onClick={() => {
                if (packoutServer.settings.skipSummaryModal) {
                  packoutServer.emit(
                    'submit-basket',
                    products.map(x => x.articleNumber),
                  );

                  ga('packoutstack', 'entry');
                } else {
                  dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: true });
                }
              }}
            >
              <Translate resourceString="packout.add-to-basket" />
            </button>
          )}
        </div>
        <div className={`inv-nav__items ${showFooter ? '' : 'inv-nav__items--full'}`}>{renderProducts()}</div>
      </div>
      {showFooter && (
        <div className={`inv-nav__footer ${packoutServer && !isMasterOrigin ? 'inv-nav__footer--small' : ''}`} data-id={CustomIntroID.Five}>
          {!packoutServer || isMasterOrigin ? (
            <>
              <h2>
                <Translate resourceString="packout.download.wall-storage-builder" />
              </h2>
              <button type="button" className="btn btn--black btn--inline btn--small" onClick={onSubmit}>
                <Translate resourceString="SubmitEntry" />
              </button>
            </>
          ) : (
            <button
              type="button"
              className="btn btn--black btn--inline btn--small"
              onClick={() => {
                if (packoutServer.settings.skipSummaryModal) {
                  packoutServer.emit(
                    'submit-basket',
                    products.map(x => x.articleNumber),
                  );

                  ga('packoutstack', 'entry');
                } else {
                  dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: true });
                }
              }}
            >
              <Translate resourceString="packout.add-to-basket" />
            </button>
          )}
        </div>
      )}
    </nav>
  );
};

export default WallStorageInventory;

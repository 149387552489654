import { ApiService } from '@core/services';
import { Api } from '@core/types';
import { ProductType } from '@core/types/products';
import { Dispatch } from 'redux';
import { FETCH_PRODUCTS, FETCH_PRODUCTS_ERROR, FETCH_PRODUCTS_SUCCESS } from './actions';
import SessionStorage, { sessionKeys } from '@core/storage/SessionStorage';

export const fetchProducts = (cultureCode: string, productType: ProductType) => async (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_PRODUCTS,
  });

  try {
    const requestParams = {
      method: Api.Method.GET,
      slug: 'get-products',
      params: {
        cultureCode,
        productType,
        includeUnpublished: true,
      },
      // cacheKey: `${sessionKeys.products}|${cultureCode}|${productType}`,
    };

    const response = await ApiService.request<Api.IFetchProductsResponse>(requestParams);

    SessionStorage.set(`${sessionKeys.products}|${cultureCode}`, response.categories);

    dispatch({
      type: FETCH_PRODUCTS_SUCCESS,
      payload: {
        categories: response.categories,
        type: productType,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_PRODUCTS_ERROR,
      payload: e,
    });
  }
};

import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Arrow, Logo, Packout } from '@components/icons';
import Idea from '@components/icons/Idea';
import { Translate } from '@components/translations';
import { Routes } from '@core/helpers/Routes';
import { States } from '@core/types';
import usePackoutServer from '@hooks/usePackoutServer';

interface IProps {
  app?: States.AppState;
}

const Navigation: FC<IProps> = ({ app }) => {
  const location = useLocation();
  const { packoutServer } = usePackoutServer();

  if (!app || !app.site) {
    return null;
  }

  const renderLogo = () => {
    if (!app || !app.site) {
      return null;
    }

    if (!packoutServer || !packoutServer.settings.hideNavigationLinks || packoutServer.settings.layout === 'landing') {
      return (
        <Link to={`/${app.site.cultureAlias}/`}>
          <Logo />
        </Link>
      );
    }

    return <Logo />;
  };

  return (
    <nav className="nav">
      <div className="nav__logo">{renderLogo()}</div>
      {app.site.landingPage ? (
        <div className="nav__links">
          {!packoutServer || !packoutServer.settings.hideNavigationLinks ? (
            <>
              <div className={`nav__link ${location.pathname === `/${app.site.cultureAlias}/${Routes.StackBuilder}/` ? 'active' : ''}`}>
                <Link to={`/${app.site.cultureAlias}/${Routes.StackBuilder}/`}>
                  <Translate resourceString="packout.stack-builder" />
                </Link>
              </div>
              <div className={`nav__link ${location.pathname === `/${app.site.cultureAlias}/${Routes.WallStorageBuilder}/` ? 'active' : ''}`}>
                <Link to={`/${app.site.cultureAlias}/${Routes.WallStorageBuilder}/`}>
                  <Translate resourceString="packout.wall-storage-builder" />
                </Link>
              </div>
            </>
          ) : null}

          {!packoutServer ? (
            <div className={`nav__link ${location.pathname === `/${app.site.cultureAlias}/${Routes.GetInspired}/` ? 'active' : ''}`}>
              <Link to={`/${app.site.cultureAlias}/${Routes.GetInspired}/`}>
                <Idea /> <Translate resourceString="packout.get-inspired" />
              </Link>
            </div>
          ) : null}
        </div>
      ) : null}

      {!packoutServer && app.site.landingPageUrl ? (
        <div className="nav__link">
          <a href={app.site.landingPageUrl}>
            <Translate resourceString="back" /> <Arrow />
          </a>
        </div>
      ) : (
        <div className="nav__logo nav__logo--end">
          <Packout />
          <h4>
            {location.pathname === `/${app.site.cultureAlias}/${Routes.WallStorageBuilder}/` ? (
              <Translate resourceString={'packout.storage.wallstorage'} />
            ) : null}

            {location.pathname === `/${app.site.cultureAlias}/${Routes.StackBuilder}/` ? <Translate resourceString={'packout.storage'} /> : null}
          </h4>
        </div>
      )}
    </nav>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
});

export default connect(mapStateToProps, null)(Navigation);

import SiteHelmet from '@components/app/SiteHelmet';
import GeolocationSnackbar from '@components/geolocation/GeolocationSnackbar';
import GetInspired from '@components/get-inspired/GetInspired';
import LegacyWarning from '@components/landing/LegacyWarning';
import { Pdf } from '@components/pdf';
import PdfWall from '@components/pdf/PdfWall';
import { Routes } from '@core/helpers/Routes';
import { AppActionCreators } from '@core/redux/app';
import { TranslationActionCreators } from '@core/redux/translations';
import { Site } from '@core/types';
import { RootState } from '@core/types/states';
import { isIE } from '@core/utils';
import usePackoutServer from '@hooks/usePackoutServer';
import Landing from '@pages/Landing';
import StackBuilder from '@pages/StackBuilder';
import WallStorageBuilderPage from '@pages/WallStorageBuilderPage';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

interface Props {
  site: Site.ISite;
  baseUrl: string;
}

const PageRouter: FunctionComponent<Props> = ({ site, baseUrl }) => {
  const urlSlug = useSelector((x: RootState) => x.router.location.pathname);
  const translations = useSelector((x: RootState) => x.translations);
  const dispatch = useDispatch();
  const { packoutServer } = usePackoutServer();
  const [mounted, setMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(AppActionCreators.setSite(site));
    dispatch(TranslationActionCreators.fetchTranslations(site.cultureCode));
  }, [dispatch, site]);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  React.useEffect(() => {
    if (
      mounted &&
      packoutServer &&
      (urlSlug === `/${baseUrl}/` ||
        urlSlug.indexOf(Routes.GetInspired) > -1 ||
        urlSlug.indexOf(Routes.WallStorageBuilder) > -1 ||
        urlSlug.indexOf(Routes.StackBuilder) > -1)
    ) {
      packoutServer.emit('route-changed', urlSlug);
    }
  }, [urlSlug, packoutServer]);

  if (isIE()) {
    if (!translations.loaded) {
      return null;
    }

    return <LegacyWarning />;
  }

  return (
    <>
      <Switch>
        <Route exact path={`/${baseUrl}/${Routes.StackBuilder}/`}>
          <SiteHelmet site={site} />
          <StackBuilder site={site} baseUrl={baseUrl} />
          <GeolocationSnackbar site={site} />
        </Route>

        {site.landingPage ? (
          <>
            <Route exact path={`/${baseUrl}/`}>
              <SiteHelmet site={site} />
              <Landing site={site} />
              <GeolocationSnackbar site={site} />
            </Route>

            <Route exact path={`/${baseUrl}/${Routes.WallStorageBuilder}/`}>
              <SiteHelmet site={site} />
              <WallStorageBuilderPage site={site} baseUrl={baseUrl} />
              <GeolocationSnackbar site={site} />
            </Route>

            <Route exact path={`/${baseUrl}/${Routes.GetInspired}/`}>
              <SiteHelmet site={site} />
              <GetInspired />
              <GeolocationSnackbar site={site} />
            </Route>

            <Route exact path={`/${baseUrl}/${Routes.StackBuilder}/pdf/`}>
              <Pdf site={site} />
            </Route>

            <Route exact path={`/${baseUrl}/${Routes.WallStorageBuilder}/pdf/`}>
              <PdfWall site={site} />
            </Route>
          </>
        ) : null}

        <Route exact path={`/${baseUrl}/${Routes.WallStorageBuilder}/`}>
          <SiteHelmet site={site} />
          <WallStorageBuilderPage site={site} baseUrl={baseUrl} />
          <GeolocationSnackbar site={site} />
        </Route>

        <Route exact path={`/${baseUrl}/${Routes.GetInspired}/`}>
          <SiteHelmet site={site} />
          <GetInspired />
          <GeolocationSnackbar site={site} />
        </Route>

        <Route exact path={`/${baseUrl}/${Routes.StackBuilder}/pdf/`}>
          <Pdf site={site} />
        </Route>

        <Route exact path={`/${baseUrl}/${Routes.WallStorageBuilder}/pdf/`}>
          <PdfWall site={site} />
        </Route>

        {site.landingPage ? <Redirect to={`/${baseUrl}/`} /> : <Redirect to={`/${baseUrl}/${Routes.StackBuilder}/`} />}
      </Switch>
    </>
  );
};

export default PageRouter;

export class BaseClient {
    constructor() {
        Object.defineProperty(this, "clientId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "root", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "callbacks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "setRoot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (root) => {
                if (!root) {
                    return;
                }
                this.root = root;
            }
        });
        Object.defineProperty(this, "setupListen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                window.addEventListener('message', e => {
                    if (this.clientId !== undefined && this.clientId !== e.data.clientId) {
                        return;
                    }
                    this.call(e.data.type, e.data.message);
                });
            }
        });
        Object.defineProperty(this, "call", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event, data) => {
                for (const mapping of this.callbacks) {
                    if (mapping.event === event || mapping.event === '*') {
                        mapping.callback(data);
                    }
                }
            }
        });
        Object.defineProperty(this, "emit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event, value) => {
                this.root.postMessage({ type: event, message: value, clientId: this.clientId }, '*');
            }
        });
        Object.defineProperty(this, "on", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event, callback) => {
                this.callbacks.push({ event, callback });
            }
        });
        this.setupListen();
    }
}

import { Add, QuestionMark } from '@components/icons';
import { Translate } from '@components/translations';
import { ProductHelper } from '@core/helpers';
import { ProductActionTypes } from '@core/redux/products/actions';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { ApiService, ProductService } from '@core/services';
import { sessionKeys } from '@core/storage';
import { ProductCardStrategies } from '@core/strategies/ProductCard.strategies';
import { Builder, Products, Site, States } from '@core/types';
import { GetPackoutPopupDataRequest, GetPackoutProductDataResponse, Method } from '@core/types/api';
import { StackProduct, WallStorageBuilderType } from '@core/types/products';
import React, { FC } from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { connect, useDispatch } from 'react-redux';

interface IProps {
  inventory?: States.InventoryState;
  wallStorageBuilder?: States.WallStorageBuilderState;
  products?: States.ProductState;
  model: Products.StackProduct | Products.WallStorageBuilderProduct;
  disabledLayers?: Builder.LayerPosition[];
  site?: Site.ISite;
  onClick: (layer: Builder.LayerPosition) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  showInfoPopup: boolean;
}

const ProductCard: FC<IProps> = ({
  inventory,
  model,
  site,
  showInfoPopup,
  wallStorageBuilder,
  onClick,
  onDragStart,
  onDragEnd,
  products,
  disabledLayers,
}) => {
  const dispatch = useDispatch();
  const [_, drag, preview] = useDrag({
    item: { type: `${model.productType}`, object: model },
    begin: () => {
      onDragStart && onDragStart();
    },
    end: (item, monitor) => {
      onDragEnd && onDragEnd();
    },
  });

  const onInfoClick = async () => {
    if (!site) {
      return;
    }

    if (wallStorageBuilder) {
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_INFO_MODAL_OPEN', payload: true });
    } else {
      dispatch<ProductActionTypes>({ type: 'PRODUCT/SET_INFO_MODAL_OPEN', payload: true });
    }

    const params: GetPackoutPopupDataRequest = {
      agilityId: model.agilityId,
      cultureCode: site.cultureCode,
      includeUnpublished: true,
    };

    const response = await ApiService.request<GetPackoutProductDataResponse>({
      method: Method.GET,
      slug: 'get-popup-data',
      params,
      cacheKey: `${sessionKeys.infoPopup}|${site.cultureCode}|${model.agilityId}`,
    });

    if (wallStorageBuilder) {
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_INFO_MODAL', payload: response.popupData });
    } else {
      dispatch<ProductActionTypes>({ type: 'PRODUCT/SET_INFO_MODAL', payload: response.popupData });
    }
  };

  const sizeGridStyles: React.CSSProperties = {
    gridTemplateColumns: `repeat(${model.productWidth}, 13px)`,
    gridTemplateRows: `repeat(${model.productHeight}, 13px)`,
  };

  const stackType =
    inventory?.layers && products?.categories ? ProductService.getStackType(inventory.layers, products.categories) : Builder.StackType.default;

  const complicatedDisabledCondition = React.useMemo(() => {
    // If we're on wall storage builder, ignore //
    if (wallStorageBuilder !== undefined) {
      return false;
    }

    // If they're undefined ignore it //
    if (disabledLayers?.length === 0) {
      return false;
    }

    if (stackType === Builder.StackType.double) {
      // If only one is defined check if we're looking at bases //
      if (
        (model as Products.StackProduct).productType === Products.Type.base &&
        (ProductService.isLayerDisabled(disabledLayers, Builder.LayerPosition.front) ||
          ProductService.isLayerDisabled(disabledLayers, Builder.LayerPosition.back))
      ) {
        return true;
      }

      // Return the actual condition //
      return (
        ProductService.isLayerDisabled(disabledLayers, Builder.LayerPosition.front) &&
        ProductService.isLayerDisabled(disabledLayers, Builder.LayerPosition.back)
      );
    }

    if (stackType === Builder.StackType.layered) {
      if (model.productType === Products.Type.base) {
        return ProductService.isLayerDisabled(disabledLayers, Builder.LayerPosition.front);
      }

      // If only bottom is disabled still allow to add to top //
      return (
        ProductService.isLayerDisabled(disabledLayers, Builder.LayerPosition.front) &&
        ProductService.isLayerDisabled(disabledLayers, Builder.LayerPosition.top)
      );
    }

    return disabledLayers && disabledLayers.length > 0;
  }, [disabledLayers, wallStorageBuilder, model, stackType]);

  return (
    <>
      <div className={`product-card ${complicatedDisabledCondition ? 'disabled' : ''}`} ref={drag}>
        {/* <DragPreviewImage connect={preview} src={ProductHelper.getProductUrl(model)}></DragPreviewImage> */}
        <DragPreviewImage connect={preview} src={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}></DragPreviewImage>
        {ProductHelper.isWallStorageProduct(model) && model.productType === WallStorageBuilderType.Product && (
          <div className="product-card__size-grid" style={sizeGridStyles}>
            {new Array(model.productWidth).fill(null).map((i, x) => (
              <>
                {new Array(model.productHeight).fill(null).map((j, y) => (
                  <>
                    <div></div>
                  </>
                ))}
              </>
            ))}
          </div>
        )}
        <div className="product-card__image">
          <img alt={model.name} src={ProductHelper.getProductUrl(model, 500)} />
        </div>
        <h2 className="product-card__title">
          {model.name}{' '}
          {inventory?.layers &&
            products?.categories &&
            ProductCardStrategies.getProductCounts({
              layers: inventory.layers,
              model,
              stackType: ProductService.getStackType(inventory.layers, products?.categories),
              wallStorageBuilder,
            })}
          {showInfoPopup && (
            <div className="product-card__info" onClick={onInfoClick}>
              <QuestionMark />
            </div>
          )}
        </h2>
        {model.isInventoryOnly ? (
          <div className="product-card__icons">
            <div className={`product-card__add-wrapper`} onClick={() => onClick(Builder.LayerPosition.front)}>
              <div className="product-card__add">
                <Add />
              </div>
              <span>
                <Translate resourceString={'packout.add-to-inventory'} />
              </span>
            </div>
          </div>
        ) : (
          <div className="product-card__icons">
            {inventory?.layers &&
              products?.categories &&
              ProductCardStrategies.getProductButtons({
                disabledLayers,
                layers: inventory.layers,
                model,
                stackType: ProductService.getStackType(inventory.layers, products?.categories),
                onClick,
              })}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  inventory: state.inventory,
  products: state.products,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);

import { Minus, Plus, QuestionMark } from '@components/icons';
import Redo from '@components/icons/Redo';
import Undo from '@components/icons/Undo';
import { Translate } from '@components/translations';
import { AppActionCreators } from '@core/redux/app';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { RootState } from '@core/types/states';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';

export interface WallStorageBuilderHeaderProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
  setIntro: Function;
}

const WallStorageBuilderHeader: React.FC<WallStorageBuilderHeaderProps> = props => {
  const dispatch = useDispatch();
  const {
    past,
    future,
    present: { boundaries },
  } = useSelector((x: RootState) => x.wallStorageBuilder);

  return (
    <div className="builder__buttons">
      <div className="builder__buttons-inner">
        {boundaries && boundaries.highestX > -1 && (
          <>
            <div
              className="builder__button builder__button--fake builder__button--left builder__button--with-text"
              onClick={() =>
                dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_DIMENSIONS_MODAL', payload: true })
              }
            >
              <strong>
                <Translate resourceString="packout.dimensions" />: H{boundaries.boundsY}.00m x W{boundaries.boundsX}.00mm
              </strong>
            </div>
            {past && past.length > 0 && past[past.length - 1].boundaries.highestX > -1 && (
              <div className="builder__button builder__button--with-text" onClick={() => dispatch(ActionCreators.undo())}>
                <Undo />
                <Translate resourceString="undo" />
              </div>
            )}
            {future.length > 0 && (
              <div className="builder__button builder__button--with-text" onClick={() => dispatch(ActionCreators.redo())}>
                <Redo />
                <Translate resourceString="redo" />
              </div>
            )}
            <div
              className="builder__button builder__button--with-text"
              onClick={() =>
                dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_SHOW_RESTART_MODAL', payload: true })
              }
            >
              <Translate resourceString="restart" />
            </div>
          </>
        )}
        <div className="builder__button" onClick={() => props.setIntro(true)}>
          <QuestionMark />
        </div>
        {boundaries && boundaries.highestX > -1 && (
          <>
            <div className="builder__button" onClick={props.onZoomIn}>
              <Plus />
            </div>
            <div className="builder__button" onClick={props.onZoomOut}>
              <Minus />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setIntro: (open: boolean) => AppActionCreators.setIntro(open),
};

export default connect(null, mapDispatchToProps)(WallStorageBuilderHeader);

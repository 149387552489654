import React from 'react';

const Restart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.681" height="13.336" viewBox="0 0 10.681 13.336">
    <path
      id="e025"
      d="M5.341,2.686A5.148,5.148,0,0,1,9.12,4.248,5.111,5.111,0,0,1,10.681,8,5.122,5.122,0,0,1,9.1,11.774a5.163,5.163,0,0,1-3.763,1.562,5.163,5.163,0,0,1-3.763-1.562A5.122,5.122,0,0,1,0,8H1.343a3.852,3.852,0,0,0,1.171,2.826,3.852,3.852,0,0,0,2.826,1.171,3.852,3.852,0,0,0,2.826-1.171A3.852,3.852,0,0,0,9.338,8,3.852,3.852,0,0,0,8.167,5.169,3.852,3.852,0,0,0,5.341,4V6.684L2,3.342,5.341,0Z"
      fill="currentcolor"
    />
  </svg>
);

export default Restart;

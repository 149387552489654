import { Modal } from '@components/shared';
import { States } from '@core/types';
import { IIntroduction } from '@core/types/site';
import useIntroJs from '@hooks/useIntroJs';
import usePackoutServer from '@hooks/usePackoutServer';
import useWallStorageBuilder from '@hooks/useWallStorageBuilder';
import { AppActionCreators } from '@redux/app';
import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

interface IProps {
  app?: States.AppState;
  products?: States.ProductState;
  ensureIntroPopup: Function;
  setIntro?: Function;
}

const Introduction: FC<IProps> = ({ app, products, ensureIntroPopup, setIntro }) => {
  const { instance } = useIntroJs();
  const { isWSB } = useWallStorageBuilder();
  const { packoutServer } = usePackoutServer();

  const introduction: IIntroduction | null = useMemo(() => {
    if (app && app.site) {
      if (isWSB) {
        if (app.site.wallStorageIntroduction) {
          const intro = app.site.wallStorageIntroduction;
          // Check we actually have a value for each of the fields //
          if (Object.entries(intro).every(x => x[1] !== '')) {
            return intro;
          }
        }
      } else {
        return app.site.introduction;
      }
    }

    return null;
  }, [app]);

  useEffect(() => {
    if (!app || app.introOpen || packoutServer?.settings.hideIntro) {
      return;
    }

    ensureIntroPopup();
  }, []);

  if (!app || !app.site || !setIntro || !products || products.isLoading) {
    return null;
  }

  const handleSetIntro = (value: boolean) => {
    setIntro(value);

    if (value === false) {
      instance.start();
    }
  };

  return (
    <>
      {introduction && (
        <Modal visible={app.introOpen} onClose={() => handleSetIntro(false)}>
          <div className="intro">
            <div className="intro__inner">
              <h3>{introduction.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: introduction.description }}></p>

              <button className="btn btn--primary btn--inline btn--small" onClick={() => handleSetIntro(false)}>
                {introduction.buttonText}
              </button>
            </div>

            {introduction.imageUrl.length > 0 && (
              <div className="intro__bg">
                <img alt={introduction.title} src={introduction.imageUrl} />
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
  products: state.products,
});

const mapDispatchToProps = {
  setIntro: (open: boolean) => AppActionCreators.setIntro(open),
  ensureIntroPopup: () => AppActionCreators.ensureIntroPopup(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Introduction);

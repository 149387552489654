import React from 'react';

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
    <path
      d="M1.6,1.621A5.321,5.321,0,0,1,5.5,0,5.3,5.3,0,0,1,9.392,1.608,5.3,5.3,0,0,1,11,5.5,5.3,5.3,0,0,1,9.392,9.392,5.3,5.3,0,0,1,5.5,11,5.3,5.3,0,0,1,1.608,9.392,5.3,5.3,0,0,1,0,5.5,5.281,5.281,0,0,1,1.6,1.621ZM8.152,7.342,6.31,5.5,8.176,3.658a.184.184,0,0,0,0-.295l-.54-.516a.212.212,0,0,0-.147-.074.2.2,0,0,0-.123.074L5.5,4.665,3.634,2.848a.2.2,0,0,0-.123-.074.212.212,0,0,0-.147.074l-.516.516a.184.184,0,0,0,0,.295L4.714,5.5,2.848,7.366q-.049.025-.049.123a.2.2,0,0,0,.049.147l.516.54a.246.246,0,0,0,.295,0L5.5,6.31,7.366,8.152a.2.2,0,0,0,.123.074.212.212,0,0,0,.147-.074l.516-.516a.165.165,0,0,0,.074-.147A.212.212,0,0,0,8.152,7.342Z"
      fill="#db031d"
    />
  </svg>
);

export const CloseAlt = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
    <path
      d="M1.6,1.621A5.321,5.321,0,0,1,5.5,0,5.3,5.3,0,0,1,9.392,1.608,5.3,5.3,0,0,1,11,5.5,5.3,5.3,0,0,1,9.392,9.392,5.3,5.3,0,0,1,5.5,11,5.3,5.3,0,0,1,1.608,9.392,5.3,5.3,0,0,1,0,5.5,5.281,5.281,0,0,1,1.6,1.621ZM8.152,7.342,6.31,5.5,8.176,3.658a.184.184,0,0,0,0-.295l-.54-.516a.212.212,0,0,0-.147-.074.2.2,0,0,0-.123.074L5.5,4.665,3.634,2.848a.2.2,0,0,0-.123-.074.212.212,0,0,0-.147.074l-.516.516a.184.184,0,0,0,0,.295L4.714,5.5,2.848,7.366q-.049.025-.049.123a.2.2,0,0,0,.049.147l.516.54a.246.246,0,0,0,.295,0L5.5,6.31,7.366,8.152a.2.2,0,0,0,.123.074.212.212,0,0,0,.147-.074l.516-.516a.165.165,0,0,0,.074-.147A.212.212,0,0,0,8.152,7.342Z"
      fill="currentcolor"
    />
  </svg>
);

export default Close;

import { Site } from '@core/types';
import React, { FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  site: Site.ISite;
}

const SiteHelmet: FunctionComponent<Props> = ({ site }) => {
  const lang = site.cultureCode.split('-')[0];

  useEffect(() => {
    const head = document.querySelector('head');
    if (head) {
      const scriptTag = document.createElement('script');
      scriptTag.id = 'tagmanager';
      scriptTag.innerHTML = `
<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KNC365QJ');
<!-- End Google Tag Manager -->
      `;

      const existingTag = document.querySelector('script#tagmanager');
      if (existingTag) {
        existingTag.remove();
      }

      head.appendChild(scriptTag);
    }
  }, []);

  return (
    <Helmet>
      <html className={site.cultureCode.toLowerCase()} lang={lang} />

      <script id="#tagmanager" type="text/javascript"></script>
    </Helmet>
  );
};

export default SiteHelmet;

import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import Logo from '@img/svg/svg--logo.svg';
import { Site, Builder, Products, Api, Constants } from '@core/types';
import { LocalStorage, localKeys, SessionStorage, sessionKeys } from '@core/storage';
import { BuilderService, ProductService } from '@core/services';
import { IFetchTranslationsResponse } from '@core/types/api';
import { ProductCategory, StackProduct } from '@core/types/products';

interface Props {
  site: Site.ISite;
}

const Pdf: FunctionComponent<Props> = ({ site }) => {
  const [triggerChange, setTriggerChange] = useState<boolean>(false);
  const hasChanged = useRef<boolean>(false);
  const [layers, setLayers] = useState<Builder.BaseLayer[]>([]);
  const [invOnly, setInvOnly] = useState<Products.BaseProduct[]>([]);
  const [translations, setTranslations] = useState<Record<string, string>>();
  const categories = SessionStorage.get<ProductCategory<StackProduct>[]>(`${sessionKeys.products}|${site.cultureCode}`);

  // Used to bind events which trigger re-renders when the local storage is updated
  useEffect(() => {
    window.addEventListener('storage', () => {
      if (hasChanged.current === false) {
        hasChanged.current = true;
        setTriggerChange(true);
      } else {
        hasChanged.current = false;
        setTriggerChange(false);
      }
    });
  }, []);

  // Used to update our local state when the local storage has updated
  useEffect(() => {
    const newLayers = LocalStorage.get<Builder.BaseLayer[]>(localKeys.builder) || [];
    setLayers(newLayers);

    const newInvOnly = LocalStorage.get<Products.BaseProduct[]>(localKeys.inventoryOnly) || [];
    setInvOnly(newInvOnly);

    const translationsCacheKey = `${sessionKeys.translations}|${site.cultureCode}`;
    const response = SessionStorage.get<IFetchTranslationsResponse>(translationsCacheKey);

    setTranslations(response?.Localisations);
  }, [triggerChange]);

  // Checks for data
  if (categories === undefined || layers.length === 0 || translations === undefined) {
    return null;
  }

  const allProductQuantities: Record<number, number> = {};
  const allLayers = ProductService.getAllLayers(layers);
  Object.keys(allLayers).forEach(layerKey => {
    const layer = allLayers[Number(layerKey) as Builder.LayerPosition];
    const productsOnLayer = ProductService.getByLayers(layer, categories as ProductCategory<StackProduct>[]);
    productsOnLayer.forEach(product => {
      if (allProductQuantities[product.agilityId]) {
        allProductQuantities[product.agilityId]++;
      } else {
        allProductQuantities[product.agilityId] = 1;
      }
    });
  });

  const productsWithQuantities: Products.PdfProductQuantity[] = [];
  Object.keys(allProductQuantities).map(agilityId => {
    const product = ProductService.getByAgilityId(Number(agilityId), categories as ProductCategory<StackProduct>[]);
    if (product) {
      productsWithQuantities.push({
        product,
        quantity: allProductQuantities[Number(agilityId)],
      });
    }
  });

  const invOnlyProductQuantities: Products.PdfProductQuantity[] = invOnly.reduce<Products.PdfProductQuantity[]>((acc, cur) => {
    let clone = [...acc];
    const existingIndex = acc.findIndex(x => x.product.agilityId === cur.agilityId);
    if (existingIndex > -1) {
      clone[existingIndex].quantity = clone[existingIndex].quantity + 1;
    } else {
      clone.push({
        product: cur,
        quantity: 1,
      });
    }

    return clone;
  }, []);

  return (
    <div className="a4 pdf">
      <div className="pdf-wide pdf-header">
        <img className="pdf-header__logo" alt="" src={Logo} />
      </div>
      <div className="pdf-wide">
        <div className="product">
          <h2>{translations['packout.yoursystem']}</h2>
          <div className="product__split">
            <ul className="product__rows">
              {productsWithQuantities.map(group => (
                <li className="product-item" key={group.product.articleNumber}>
                  <div className="product-item__left">
                    <div className="product-item__article">{group.product.articleNumber} - </div>
                  </div>
                  <div className="product-item__right">
                    <div className="product-item__name">{group.product.name}</div>
                    <div className="product-item__quantity">
                      {translations['quantity']} - {group.quantity}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="product__image">
              <img src={Constants.PDF_IMAGE_REPLACEMENT} alt="Packout" />
            </div>
          </div>
          {invOnlyProductQuantities.length > 0 && (
            <>
              <h3>{translations['packout.inventory-only']}</h3>
              <ul className="product__rows">
                {invOnlyProductQuantities.map(group => (
                  <li className="product-item" key={group.product.articleNumber}>
                    <div className="product-item__left">
                      <div className="product-item__article">{group.product.articleNumber} - </div>
                    </div>
                    <div className="product-item__right">
                      <div className="product-item__name">{group.product.name}</div>
                      <div className="product-item__quantity">
                        {translations['quantity']} - {group.quantity}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pdf;

import { Cross } from '@components/icons';
import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

interface IProps {
  visible: boolean;
  hideClose?: boolean;
  onClose?: () => void;
}

const Modal: FC<IProps> = ({ children, visible, hideClose, onClose }) => (
  <CSSTransition timeout={200} classNames="fade-in" mountOnEnter={true} unmountOnExit={true} in={visible}>
    <div
      className="modal"
      onClick={() => {
        if (hideClose === undefined || hideClose === false) {
          onClose && onClose();
        }
      }}
    >
      <div className="modal__inner" onClick={e => e.stopPropagation()}>
        {children}

        {(hideClose === undefined || hideClose === false) && (
          <button className="modal__close" onClick={onClose}>
            <Cross />
          </button>
        )}
      </div>
    </div>
  </CSSTransition>
);

export default Modal;

import { ProductHelper } from '@core/helpers';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { WallStorageBuilderProductCell, WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import React from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { transformWidth } from './WallStorageBuilderHelper';

export interface WallStorageBuilderProductProps {
  product: WallStorageBuilderProductCell;
}

const WallStorageBuilderProduct: React.FC<WallStorageBuilderProductProps> = props => {
  const dispatch = useDispatch();
  const { activeMountingPlates } = useSelector((x: RootState) => x.wallStorageBuilder.present);

  const [collectedProps, drag, preview] = useDrag({
    item: { type: `${WallStorageBuilderType.Product}_MOVE`, object: props.product },
    begin: () => {
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_SELECTED_MOUNTING_PLATE', payload: [] });
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_HOVERING_PRODUCT', payload: props.product });
    },
    end: () => {
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_SELECTED_MOUNTING_PLATE', payload: [] });
      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_HOVERING_PRODUCT', payload: null });
    },
  });

  const width = transformWidth(props.product.productWidth);

  const productStyles: React.CSSProperties = {
    gridArea: `${props.product.y} / ${props.product.x} / ${props.product.y + props.product.productHeight} / ${props.product.x +
      width}`,
  };

  const styles =
    props.product.productType === WallStorageBuilderType.Product
      ? {
          top: `${props.product.offsetY}px`,
          left: `${props.product.offsetX}px`,
          transform: `scale(${props.product.scale})`,
        }
      : undefined;

  return (
    <div
      ref={drag}
      className="wsb__product"
      style={productStyles}
      onClick={e => {
        if (!props.product.mountCellGuid) {
          return;
        }

        dispatch<WallStorageBuilderActionTypes>({
          type: 'WALL_STORAGE_BUILDER/SET_SELECTED_MOUNTING_PLATE',
          payload: activeMountingPlates.indexOf(props.product.mountCellGuid) > -1 ? [] : [props.product.mountCellGuid],
        });
      }}
    >
      <DragPreviewImage
        connect={preview}
        src={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
      ></DragPreviewImage>
      <img src={ProductHelper.getProductUrl(props.product, 500)} style={styles} alt={props.product.name} />
    </div>
  );
};

export default WallStorageBuilderProduct;

import { AppActionTypes } from '@core/redux/app/actions';
import { Builder, Products } from '@core/types';
import { BaseProduct, WallStorageBuilderProduct } from '@core/types/products';
import { InventoryState, ProductState } from '@core/types/states';
import PackoutServer from '@packout/api/src/PackoutServer';
import ImageTransform from '@tti/image-transform';
import { Dispatch } from 'redux';
import { ga } from './ga';

class ProductHelper {
  transformImageUrl(imageUrl?: string, width?: number, ignoreRelativeImages?: boolean): string {
    if (!imageUrl) {
      return '';
    }

    let url = imageUrl ?? '';

    if (process.env.REACT_APP_RELATIVE_IMAGES === 'TRUE' && !ignoreRelativeImages && !imageUrl.startsWith('https')) {
      url = `${process.env.REACT_APP_BASE_URL}${imageUrl}`;
    }

    if (width) {
      url = new ImageTransform(url).width(width).toString();
    }

    return url;
  }

  getProductUrl(product: Products.BaseProduct, width?: number, disableUsingListImageUrl?: boolean, isTopHalf?: boolean): string {
    return this.transformImageUrl(
      !disableUsingListImageUrl && product.listImageUrl !== ''
        ? product.listImageUrl
        : isTopHalf && product.imageTopUrl !== undefined
        ? product.imageTopUrl
        : product.imageUrl,
      width,
    );
  }

  isProductCell(
    product: Products.WallStorageBuilderProduct | Products.WallStorageBuilderProductCell,
  ): product is Products.WallStorageBuilderProductCell {
    if ((product as Products.WallStorageBuilderProductCell).cellGuid) {
      return true;
    }

    return false;
  }

  isWallStorageProduct(product: BaseProduct): product is WallStorageBuilderProduct {
    if ((product as WallStorageBuilderProduct).scale !== undefined) {
      return true;
    }

    return false;
  }

  collectArticleNumbers(
    packoutServer: PackoutServer | null,
    products: ProductState,
    inventory: InventoryState,
    callback: (articleNumbers: string[]) => void,
  ) {
    if (!packoutServer) {
      return;
    }

    const allProducts = products.categories.flatMap(x => x.products);
    const articleNumbers: string[] = [];

    for (const layer of inventory.layers) {
      switch (layer.type) {
        case Builder.LayerType.full: {
          const fullLayer = layer as Builder.FullLayer;

          const product = allProducts.find(x => x.agilityId === fullLayer.agilityId);

          if (product) {
            articleNumbers.push(product.articleNumber);
          }
          break;
        }

        case Builder.LayerType.half: {
          const halfLayer = layer as Builder.HalfLayer;
          const layerAgilityIds = [...halfLayer.leftAgilityIds, ...halfLayer.rightAgilityIds];

          for (const agilityId of layerAgilityIds) {
            const product = allProducts.find(x => x.agilityId === agilityId);

            if (product) {
              articleNumbers.push(product.articleNumber);
            }
          }
          break;
        }
      }
    }

    callback(articleNumbers);
  }
}

export default new ProductHelper();

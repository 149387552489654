import { BuilderService } from '@core/services';
import { LocalStorage, localKeys } from '@core/storage';
import { Builder, Products, States } from '@core/types';
import InventoryActionTypes, { ADD_PRODUCT, CLEAR_PRODUCTS, REMOVE_PRODUCT, SUBMIT, SUBMIT_SUCCESS } from './actions';

const initialState: States.InventoryState = {
  layers: LocalStorage.get(localKeys.builder) || [],
  inventoryOnlyProducts: LocalStorage.get(localKeys.inventoryOnly) || [],
  isSubmitting: false,
  hasSubmitted: false,
};

export function inventoryReducer(state: States.InventoryState = initialState, action: InventoryActionTypes): States.InventoryState {
  switch (action.type) {
    case ADD_PRODUCT: {
      let newState = { ...state };
      const { product, addType, categories, position } = action.payload;

      const layerPosition = position ?? Builder.LayerPosition.front;

      if (product.isInventoryOnly) {
        // Add to the inventory but not the stack //
        newState.inventoryOnlyProducts.push(product);
        LocalStorage.set(localKeys.inventoryOnly, newState.inventoryOnlyProducts);
        return newState;
      }

      switch (product.productWidth) {
        case Products.Width.full: {
          const layer: Builder.FullLayer = {
            type: Builder.LayerType.full,
            position: layerPosition,
            agilityId: product.agilityId,
          };

          newState.layers.push(layer);
          break;
        }

        case Products.Width.half: {
          // Check the last layer see if there is any empty spot
          const scopedLayers = BuilderService.getLayersByType(newState.layers, layerPosition);
          const lastSpace = BuilderService.getLastAvailableSpace(product, scopedLayers, categories, addType);
          const bagLeft = BuilderService.getLastBagRecursive(scopedLayers, categories, Builder.DropType.left);
          const bagRight = BuilderService.getLastBagRecursive(scopedLayers, categories, Builder.DropType.right);

          if (lastSpace === undefined) {
            const canAddLeft = !bagLeft && (addType === Builder.AddType.left || addType === Builder.AddType.auto);
            const canAddRight = !bagRight && addType === Builder.AddType.right;

            if (!canAddLeft && !canAddRight) {
              break;
            }

            const newLayer: Builder.HalfLayer = {
              type: Builder.LayerType.half,
              position: layerPosition,
              leftAgilityIds: canAddLeft ? [product.agilityId] : [],
              rightAgilityIds: canAddRight && !canAddLeft ? [product.agilityId] : [],
            };

            newState.layers.push(newLayer);
            break;
          }

          const lastLayer = scopedLayers[lastSpace.index] as Builder.HalfLayer;

          switch (lastSpace.type) {
            case Builder.AddTypeHalf.left: {
              lastLayer.leftAgilityIds.push(product.agilityId);
              break;
            }

            case Builder.AddTypeHalf.right: {
              lastLayer.rightAgilityIds.push(product.agilityId);
              break;
            }

            default:
              break;
          }
          break;
        }

        default: {
          break;
        }
      }

      // Update the local storage
      LocalStorage.set(localKeys.builder, newState.layers);
      return newState;
    }

    case REMOVE_PRODUCT: {
      const { layerIndex, productIndex, removeType, position, isInventoryOnly } = action.payload;
      const layerPosition = position ?? Builder.LayerPosition.front;

      const layers = BuilderService.getLayersByType(state.layers, layerPosition);

      if (isInventoryOnly) {
        const inventoryOnlyProducts = [...state.inventoryOnlyProducts];
        inventoryOnlyProducts.splice(productIndex, 1);

        LocalStorage.set(localKeys.inventoryOnly, inventoryOnlyProducts);

        return {
          ...state,
          inventoryOnlyProducts,
        };
      }

      switch (removeType) {
        case Builder.RemoveType.full: {
          layers.splice(layerIndex, 1);
          break;
        }

        case Builder.RemoveType.left: {
          const layer = layers[layerIndex] as Builder.HalfLayer;

          // remove the product from the layer
          if (layer.leftAgilityIds.length > 0) {
            layer.leftAgilityIds.splice(productIndex, 1);
          }

          // if the layer is empty, remove the whole layer
          if (layer.leftAgilityIds.length === 0 && layer.rightAgilityIds.length === 0) {
            layers.splice(layerIndex, 1);
          }
          break;
        }

        case Builder.RemoveType.right: {
          const layer = layers[layerIndex] as Builder.HalfLayer;

          // remove the product from the layer
          if (layer.rightAgilityIds.length > 0) {
            layer.rightAgilityIds.splice(productIndex, 1);
          }

          // if the layer is empty, remove the whole layer
          if (layer.leftAgilityIds.length === 0 && layer.rightAgilityIds.length === 0) {
            layers.splice(layerIndex, 1);
          }
          break;
        }

        default:
          break;
      }

      const allOtherLayers = state.layers.filter(x => x.position !== position);
      const newState = [...allOtherLayers, ...layers];

      // Update the local storage
      LocalStorage.set(localKeys.builder, [...newState]);

      // filter out any empty layer
      return {
        ...state,
        layers: [...newState],
      };
    }

    case CLEAR_PRODUCTS: {
      const newState = { ...state };
      const { preserveSession } = action.payload;

      newState.layers = [];
      newState.inventoryOnlyProducts = [];

      // Update the local storage
      LocalStorage.set(localKeys.builder, newState.layers);
      LocalStorage.set(localKeys.inventoryOnly, newState.inventoryOnlyProducts);

      return newState;
    }

    case SUBMIT: {
      const newState = { ...state };

      newState.isSubmitting = true;

      return newState;
    }

    case SUBMIT_SUCCESS: {
      const newState = { ...state };

      // Clear local storage
      LocalStorage.delete(localKeys.builder);
      LocalStorage.delete(localKeys.builderBack);

      newState.isSubmitting = false;
      newState.hasSubmitted = true;

      return newState;
    }

    default:
      return state;
  }
}

export default inventoryReducer;

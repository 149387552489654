import { Products } from '@core/types';
import { PackoutProductPopup } from '@core/types/api';
import { BaseProduct, ProductType } from '@core/types/products';

export const FETCH_PRODUCTS = 'PRODUCTS/FETCH_PRODUCTS';
export const FETCH_PRODUCTS_SUCCESS = 'PRODUCTS/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'PRODUCTS/FETCH_PRODUCTS_ERROR';
export const SET_INFO_MODAL = 'PRODUCT/SET_INFO_MODAL';
export const SET_INFO_MODAL_OPEN = 'PRODUCT/SET_INFO_MODAL_OPEN';

export interface FetchProducts {
  type: typeof FETCH_PRODUCTS;
}

export interface FetchProductsSuccess {
  type: typeof FETCH_PRODUCTS_SUCCESS;
  payload: {
    categories: Products.ProductCategory<BaseProduct>[];
    type: ProductType;
  };
}

export interface FetchProductsError {
  type: typeof FETCH_PRODUCTS_ERROR;
  payload: {
    error: string;
  };
}

export interface SetInfoModal {
  type: typeof SET_INFO_MODAL;
  payload: PackoutProductPopup | null;
}

export interface SetInfoModalOpen {
  type: typeof SET_INFO_MODAL_OPEN;
  payload: boolean;
}

export type ProductActionTypes = FetchProducts | FetchProductsSuccess | FetchProductsError | SetInfoModal | SetInfoModalOpen;

import React, { useState, createContext, FunctionComponent, useCallback } from 'react';
import { IToast, IToastContext, IToastProvider } from './models';
import ToastContainer from './ToastContainer';

// @ts-ignore
const initialState: IToastContext = {};

const ToastContext = createContext<IToastContext>(initialState);

let toastId = 1;

export const ToastProvider: FunctionComponent<IToastProvider> = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const addToast = useCallback(
    (content, resourceString) => {
      const newId = toastId++;
      const newToasts = [...toasts];

      newToasts.push({ id: newId, content, resourceString });

      setToasts(newToasts);
    },
    [toasts, setToasts],
  );

  const removeToast = useCallback(
    id => {
      const newToasts = [...toasts];
      setToasts(newToasts.filter(t => t.id !== id));
    },
    [toasts, setToasts],
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext };
export default ToastProvider;

import React from "react";

const Add = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.456"
    height="25.456"
    viewBox="0 0 25.456 25.456"
  >
    <path
      id="f128"
      d="M2.612,2.652A8.708,8.708,0,0,1,9,0a8.675,8.675,0,0,1,6.368,2.632A8.675,8.675,0,0,1,18,9a8.675,8.675,0,0,1-2.632,6.368A8.675,8.675,0,0,1,9,18a8.675,8.675,0,0,1-6.368-2.632A8.675,8.675,0,0,1,0,9,8.642,8.642,0,0,1,2.612,2.652Zm10.728,9.362L10.326,9l3.054-3.013a.3.3,0,0,0,0-.482L12.5,4.661a.347.347,0,0,0-.241-.121.329.329,0,0,0-.2.121L9,7.634,5.946,4.661a.329.329,0,0,0-.2-.121.347.347,0,0,0-.241.121L4.661,5.5a.3.3,0,0,0,0,.482L7.714,9,4.661,12.054q-.08.04-.08.2a.327.327,0,0,0,.08.241l.844.884a.4.4,0,0,0,.482,0L9,10.326l3.054,3.013a.329.329,0,0,0,.2.121.347.347,0,0,0,.241-.121l.844-.844a.27.27,0,0,0,.121-.241A.347.347,0,0,0,13.339,12.013Z"
      transform="translate(0 12.728) rotate(-45)"
      fill="currentcolor"
      opacity="0.4"
    />
  </svg>
);

export default Add;

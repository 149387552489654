import { ga } from '@core/helpers/ga';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { WallStorageBuilderProductCell, WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import { useToast } from '@hooks/useToast';
import classNames from 'classnames';
import React from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { transformWidth } from './WallStorageBuilderHelper';

export interface WallStorageBuilderMountProps {
  x: number;
  y: number;
  relativeX: number;
  relativeY: number;
  product: WallStorageBuilderProductCell;
}

const WallStorageBuilderMount: React.FC<WallStorageBuilderMountProps> = props => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const { hoveringProduct } = useSelector((x: RootState) => x.wallStorageBuilder.present);
  const { packoutServer } = usePackoutServer();

  const [{ isHovering }, dropRef] = useDrop({
    accept: [`${WallStorageBuilderType.Product}`, `${WallStorageBuilderType.Product}_MOVE`],
    canDrop: (item, monitor) => {
      return true;
    },
    drop: (item, monitor) => {
      const product = (item as any).object as WallStorageBuilderProductCell;

      switch (item.type) {
        case `${WallStorageBuilderType.Product}`: {
          product.x = props.x;
          product.y = props.y;
          product.mountCellGuid = props.product.cellGuid;
          product.relativeX = props.relativeX;
          product.relativeY = props.relativeY;

          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/ADD', payload: [product] });
          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
          addToast(product.name, 'packout.productadded');
          ga('packoutwall', 'product-added', { articleNumber: product.articleNumber });
          if (packoutServer) {
            packoutServer.emit('product-added', product.articleNumber);
          }
          break;
        }

        case `${WallStorageBuilderType.Product}_MOVE`: {
          product.mountCellGuid = props.product.cellGuid;
          product.relativeX = props.relativeX;
          product.relativeY = props.relativeY;

          dispatch<WallStorageBuilderActionTypes>({
            type: 'WALL_STORAGE_BUILDER/MOVE_PRODUCT',
            payload: {
              cellGuid: product.cellGuid,
              mountCellGuid: props.product.cellGuid,
              x: props.x,
              y: props.y,
              relativeX: props.relativeX,
              relativeY: props.relativeY,
            },
          });
          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
          dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
          break;
        }
      }
    },
    collect: monitor => ({
      isHovering: monitor.isOver(),
    }),
  });

  if (!hoveringProduct || hoveringProduct.productType !== WallStorageBuilderType.Product) {
    return null;
  }

  const mountStyles: React.CSSProperties = {
    gridArea: `${props.y} / ${props.x} / ${props.y + hoveringProduct.productHeight} / ${props.x + transformWidth(hoveringProduct.productWidth)}`,
  };

  const mountClassNames = classNames({
    wsb__mount: true,
    active: isHovering,
  });

  return (
    <div ref={dropRef} className={mountClassNames} style={mountStyles} data-id={props.product.agilityId}>
      {/* {props.relativeX},{props.relativeY} */}
    </div>
  );
};

export default WallStorageBuilderMount;

import { Site, States } from '@core/types';
import useCheckPageCulture, { CheckPageCultureItem } from '@hooks/useCheckPageCulture';
import useCookie from '@hooks/useCookie';
import usePackoutServer from '@hooks/usePackoutServer';
import RoundFlagSprite from '@img/svg/flag-sprite.svg';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';

const copyPattern = /.*(?<LINK_START>\[)(?<LINK_TEXT>.*)(?<LINK_END>\])/;

enum VisibilityStates {
  Unset = 0,
  True = 1,
  False = 2,
  MissingTranslations = 3,
}

interface GeolocatioSnackbarProps {
  site: Site.ISite;
}

const GeolocationSnackbar: FunctionComponent<GeolocatioSnackbarProps> = ({ site }) => {
  const [isVisible, setIsVisible] = useCookie(`alternate-culture-packout--${site.cultureCode}`, VisibilityStates.Unset);
  const { alternateCultures, translations } = useCheckPageCulture(site.cultureCode || '', site.builderRedirectNodeId || -1);
  const { packoutServer } = usePackoutServer();

  useEffect(() => {
    // Return if visible has been set to false previously
    if (isVisible === VisibilityStates.False) {
      return;
    }

    // Set visible if we have an alternate culture and visible hasn't been set yet
    if (alternateCultures.length > 0) {
      // Check Translations before we show the popup
      if (translations['custom.culturewarning.copy'] !== null && translations['custom.yes'] !== null && translations['custom.dismiss'] !== null) {
        setIsVisible(VisibilityStates.True);
      } else {
        setIsVisible(VisibilityStates.MissingTranslations);
      }
    }
  }, [alternateCultures]);

  const renderCopy = (culture: CheckPageCultureItem): ReactNode => {
    let copy = translations['custom.culturewarning.copy'][culture.cultureCode] || '';

    const matches = copy.match(copyPattern);

    if (!matches || !matches.groups || matches.length !== 4) {
      return null;
    }
    copy = copy.replace(matches.groups.LINK_START, `<a href="${culture.url}">`);
    copy = copy.replace(matches.groups.LINK_END, '</a>');

    return <div className="geolocation-snackbar__consent-text" dangerouslySetInnerHTML={{ __html: copy }}></div>;
  };

  const renderMessage = (): ReactNode => {
    const culture = alternateCultures[0];

    return (
      <div className="geolocation-snackbar__wrapper">
        <div className="geolocation-snackbar__consent">
          <div className="geolocation-snackbar__flag">
            <svg>
              <use href={`${RoundFlagSprite}#${culture.cultureCode}`}></use>
            </svg>
          </div>

          {renderCopy(culture)}

          <div className="geolocation-snackbar__buttons">
            <a className="btn btn--black" href={culture.url}>
              {translations['custom.yes'][culture.cultureCode] || ''}
            </a>

            <div className="btn btn--white btn--underline" onClick={() => setIsVisible(VisibilityStates.False)}>
              {translations['custom.dismiss'][culture.cultureCode] || ''}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (packoutServer?.settings.hideLanguageWarning) {
    return null;
  }

  return (
    <div className={`geolocation-snackbar ${isVisible === VisibilityStates.True && alternateCultures.length > 0 ? 'visible' : ''}`}>
      {alternateCultures.length > 0 && renderMessage()}
    </div>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
});

// const mapDispatchToProps = {
// };

export default connect(mapStateToProps, null)(GeolocationSnackbar);

import React from 'react';

interface CrossProps {
  colour?: string;
}

const Cross: React.FC<CrossProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      id="CLOSE_ICON"
      data-name="CLOSE ICON"
      d="M16,1.271,14.729,0,8,6.729,1.271,0,0,1.271,6.729,8,0,14.729,1.271,16,8,9.271,14.729,16,16,14.729,9.271,8Z"
      fill={props.colour ? props.colour : '#fff'}
    />
  </svg>
);

export default Cross;

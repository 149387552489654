import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Plus } from '@components/icons';
import { Translate } from '@components/translations';
import { AppActionCreators } from '@redux/app';
import { States } from '@core/types';
import { CustomIntroID } from '@core/introJs';
import useIntroJs from '@hooks/useIntroJs';

interface IProps {
  app: States.AppState;
  setNav: Function;
}

const Footer: FC<IProps> = ({ app, setNav }) => {
  const { index } = useIntroJs();

  useEffect(() => {
    if (index === CustomIntroID.TwoMobile) {
      setNav(true);
      setTimeout(() => {
        // Now manually move the stupid tooltip up because it can't track the position apparently //
        const tooltipHighlight = document.querySelector('.introjs-helperLayer');
        const tooltipContent = document.querySelector('.introjs-tooltipReferenceLayer');
        const basesDropdown = document.querySelector("div[data-mobile-id='step-2-mobile']");

        if (basesDropdown && tooltipHighlight && tooltipContent) {
          const topPosition = basesDropdown.getBoundingClientRect().top;
          const highlightStyle = tooltipHighlight.getAttribute('style');
          const contentStyle = tooltipContent.getAttribute('style');
          if (highlightStyle && contentStyle) {
            const highlightTop = highlightStyle.split(';').filter(x => !x.includes('top'));
            highlightTop.push(`top: ${topPosition - 12}px`);
            const contentTop = contentStyle.split(';').filter(x => !x.includes('top'));
            contentTop.push(`top: ${topPosition - 12}px`);

            tooltipHighlight.setAttribute('style', highlightTop.join(';'));
            tooltipContent.setAttribute('style', contentTop.join(';'));
          }
        }
      }, 350);
    } else {
      setNav(false);
    }
  }, [index]);

  return (
    <div className="builder__footer">
      <button data-mobile-id={CustomIntroID.OneMobile} type="button" className="btn btn--small add-products" onClick={() => setNav(!app.navOpen)}>
        <span className="add-products__icon">
          <Plus />
        </span>
        <Translate resourceString="AddProducts" />
      </button>
    </div>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
});

const mapDispatchToProps = {
  setNav: (open: boolean) => AppActionCreators.setNav(open),
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

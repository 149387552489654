import { Add, Chevron, Packout, QuestionMark } from '@components/icons';
import { CloseAlt } from '@components/icons/Close';
import { Loader, Modal } from '@components/shared';
import PlayButton from '@components/shared/PlayButton';
import { Translate } from '@components/translations';
import ProductHelper from '@core/helpers/ProductHelper';
import { ga } from '@core/helpers/ga';
import { WallStorageBuilderActionTypes } from '@core/redux/wall-storage-builder/actions';
import { GridService } from '@core/services';
import { PackoutProductPopupTableData } from '@core/types/api';
import { WallStorageBuilderProductCell, WallStorageBuilderType } from '@core/types/products';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import { useToast } from '@hooks/useToast';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider, { Settings } from 'react-slick';
import short from 'short-uuid';

const WallStorageInfoPopup: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { packoutServer } = usePackoutServer();
  const wallStorageCategories = useSelector((x: RootState) => x.products.wallStorageCategories);
  const { infoModal, infoModalOpen, products, gridRows, gridColumns, boundaries } = useSelector((x: RootState) => x.wallStorageBuilder.present);
  const [playingVideo, setPlayingVideo] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const mountingPlates = React.useMemo(() => products.filter(x => x.productType === WallStorageBuilderType.MountingPlate), [products]);
  const mountedProducts = React.useMemo(() => products.filter(x => x.productType === WallStorageBuilderType.Product), [products]);

  useEffect(() => {
    if (videoRef.current) {
      if (playingVideo) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [playingVideo]);

  const handleAdd = () => {
    const product = wallStorageCategories.flatMap(x => x.products).find(x => x.articleNumber === infoModal?.articleNumber);
    let cell: WallStorageBuilderProductCell | null = null;

    if (!product) {
      return;
    }

    switch (product.productType) {
      case WallStorageBuilderType.MountingPlate: {
        const position = GridService.getNextMountingPlateAvailablePosition(mountingPlates, product, boundaries, gridColumns, gridRows);

        if (!position) {
          break;
        }

        cell = {
          ...product,
          cellGuid: short.uuid(),
          x: position.x,
          y: position.y,
        };

        break;
      }

      case WallStorageBuilderType.Product: {
        const result = GridService.getNextProductAvailablePosition(mountingPlates, mountedProducts, product);

        if (!result) {
          break;
        }

        cell = {
          ...product,
          cellGuid: short.uuid(),
          x: result.x,
          y: result.y,
          relativeX: result.relativeX,
          relativeY: result.relativeY,
          mountCellGuid: result.mountCellGuid,
        };

        break;
      }
    }

    if (cell === null) {
      addToast('', 'packout.addproduct.noroom');
      return;
    }

    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/ADD', payload: [cell] });
    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/RECALCULATE_BOUNDS' });
    dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/TRIGGER_SNAPSHOT' });
    addToast(product.name, 'packout.productadded');
    ga('packoutwall', 'product-added', { articleNumber: product.articleNumber });
    if (packoutServer) {
      packoutServer.emit('product-added', product.articleNumber);
    }
  };

  const modifyTableDataByKey = (data: PackoutProductPopupTableData) => {
    // Horrible and hacky but their data isn't accurate to the design and doesn't give enough detail... //
    switch (data.label) {
      case 'packout.dimensions':
        return (
          data.value
            .split(' x ')
            .map((x, i) => `${['H', 'W', 'D'][i]}${x}`)
            .join(' x ') + ' mm'
        );
      case 'packout.capacity':
        return data.value + 'kg';
      default:
        return data.value;
    }
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const sliderBigRef = useRef<Slider>(null);
  const settingsBig: Settings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    dots: false,
    swipeToSlide: false,
    centerMode: false,
    infinite: false,
  };

  return (
    <Modal hideClose={true} visible={infoModalOpen}>
      <div className="product-info-modal">
        <div className="product-info">
          {infoModal ? (
            <>
              <div className="product-info__header">
                <div className="product-info__title">
                  <QuestionMark />
                  <span>
                    <Translate resourceString="packout.infopopup.title" />
                  </span>
                </div>
                <div>
                  <div
                    className="product-info__close"
                    onClick={() => {
                      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_INFO_MODAL_OPEN', payload: false });
                      dispatch<WallStorageBuilderActionTypes>({ type: 'WALL_STORAGE_BUILDER/SET_INFO_MODAL', payload: null });
                    }}
                  >
                    <Translate resourceString="close" />
                    <CloseAlt />
                  </div>
                </div>
              </div>
              <div className="product-info__split">
                {infoModal.assets && (
                  <div className="product-info__carousel">
                    <div className="product-info__carousel-main">
                      <Slider ref={sliderBigRef} {...settingsBig} afterChange={c => setActiveIndex(c)}>
                        {infoModal.assets?.length > 0 &&
                          infoModal.assets.map((asset, i) => (
                            <div className="carousel-slide" key={`WallStorageInfoPopup_${i}_${asset.assetNumber}`}>
                              <img
                                src={ProductHelper.transformImageUrl(asset.assetUrl, 500, true)}
                                alt={asset.assetNumber}
                                onClick={() => sliderBigRef.current && sliderBigRef.current.slickGoTo(i)}
                              />
                            </div>
                          ))}
                      </Slider>
                    </div>
                    <div className="product-info__carousel-thumbnails">
                      <div className="product-info__carousel-arrow" onClick={() => sliderBigRef.current && sliderBigRef.current.slickPrev()}>
                        <Chevron />
                      </div>
                      <div className="product-info__thumbnails-wrapper">
                        {infoModal.assets?.length > 0 &&
                          infoModal.assets.map((asset, i) => (
                            <div className="thumbnail" data-active={activeIndex === i} key={`WallStorageInfoPopup_${i}_${asset.assetNumber}`}>
                              <img
                                src={ProductHelper.transformImageUrl(asset.assetUrl, 100, true)}
                                alt={asset.assetNumber}
                                onClick={() => sliderBigRef.current && sliderBigRef.current.slickGoTo(i)}
                              />
                            </div>
                          ))}
                      </div>
                      <div className="product-info__carousel-arrow" onClick={() => sliderBigRef.current && sliderBigRef.current.slickNext()}>
                        <Chevron />
                      </div>
                    </div>
                  </div>
                )}
                <div className="product-info__main" data-no-carousel={!infoModal.assets}>
                  <div className="product-info__main-header">
                    {infoModal.isNew && (
                      <div className="product-info__main-header-new">
                        <Translate resourceString="packout.new" />
                      </div>
                    )}
                    <Packout />
                  </div>
                  <div className="product-info__main-title-and-model">
                    <div className="product-info__main-title">{infoModal.productName}</div>
                  </div>
                  <div className="product-info__main-features">
                    <div className="product-info__main-table">
                      <table>
                        <tbody>
                          {infoModal.tableData.map(data => (
                            <tr key={`InfoPopup_${data.label}`}>
                              <td>
                                <Translate resourceString={data.label} />
                              </td>
                              <td>{modifyTableDataByKey(data)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="product-info__main-add" onClick={handleAdd}>
                      <Add />
                      <span>
                        <Translate resourceString="packout.infopopup.product.add" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {infoModal.videoUrl && (
                <div className="product-info__installation-guide">
                  <h1>
                    <Translate resourceString="packout.infopopup.video.title" />
                  </h1>
                  <div className="product-info__installation-guide-video" data-play-button onClick={() => setPlayingVideo(!playingVideo)}>
                    {!playingVideo && <PlayButton />}
                    <video src={infoModal.videoUrl} autoPlay={playingVideo} loop ref={videoRef} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <Loader showContainer={false} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WallStorageInfoPopup;

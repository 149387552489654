import { Add, Chevron, Packout, QuestionMark } from '@components/icons';
import { CloseAlt } from '@components/icons/Close';
import { Loader, Modal } from '@components/shared';
import PlayButton from '@components/shared/PlayButton';
import { Translate } from '@components/translations';
import ProductHelper from '@core/helpers/ProductHelper';
import { ga } from '@core/helpers/ga';
import { InventoryActionCreators } from '@core/redux/inventory';
import { ProductActionTypes } from '@core/redux/products/actions';
import { BuilderService, ProductService } from '@core/services';
import { Builder, Products } from '@core/types';
import { PackoutProductPopupTableData } from '@core/types/api';
import { StackProduct } from '@core/types/products';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import { useToast } from '@hooks/useToast';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Slider, { Settings } from 'react-slick';

const InfoPopup: FunctionComponent<{ addProduct: Function }> = ({ addProduct }) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { packoutServer } = usePackoutServer();
  const { infoModal, infoModalOpen, categories } = useSelector((x: RootState) => x.products);
  const { layers } = useSelector((x: RootState) => x.inventory);
  const [playingVideo, setPlayingVideo] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      if (playingVideo) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [playingVideo]);

  const hasDoubleBase = ProductService.getByLayers(layers, categories).some(
    layer => layer.productType === Products.Type.base && layer.productBaseType === Products.BaseType.double,
  );

  const getCanDrop = React.useCallback(
    (back?: boolean) => {
      const scopedLayers = BuilderService.getLayersByType(layers, back ? Builder.LayerPosition.back : Builder.LayerPosition.front);

      if (back && scopedLayers.length === 0) {
        return true;
      }

      const matchingProduct = categories.flatMap(x => x.products).find(x => x.articleNumber === infoModal?.articleNumber);
      if (matchingProduct) {
        return BuilderService.canDrop(matchingProduct, categories, scopedLayers, Builder.DropType.full);
      }

      return false;
    },
    [categories, infoModal, layers],
  );

  const canDropFront = React.useMemo(() => getCanDrop(), [getCanDrop]);
  const canDropBack = React.useMemo(() => (hasDoubleBase ? getCanDrop(true) : false), [getCanDrop, hasDoubleBase]);

  const handleAdd = (addToBack?: boolean) => {
    const product = categories.flatMap(x => x.products).find(x => x.articleNumber === infoModal?.articleNumber);

    if (!product) {
      return;
    }

    addProduct(
      product,
      categories,
      addToBack ? Builder.LayerPosition.back : Builder.LayerPosition.front,
      packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
    );
    addToast(product.name, 'packout.productadded');
    ga('packoutwall', 'product-added', { articleNumber: product.articleNumber });
    if (packoutServer) {
      packoutServer.emit('product-added', product.articleNumber);
    }
  };

  const modifyTableDataByKey = (data: PackoutProductPopupTableData) => {
    // Horrible and hacky but their data isn't accurate to the design and doesn't give enough detail... //
    switch (data.label) {
      case 'packout.dimensions':
        return (
          data.value
            .split(' x ')
            .map((x, i) => `${['H', 'W', 'D'][i]}${x}`)
            .join(' x ') + ' mm'
        );
      case 'packout.capacity':
        return data.value + 'kg';
      default:
        return data.value;
    }
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const sliderBigRef = useRef<Slider>(null);
  const settingsBig: Settings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    dots: false,
    swipeToSlide: false,
    centerMode: false,
    infinite: false,
  };

  return (
    <Modal hideClose={true} visible={infoModalOpen}>
      <div className="product-info-modal">
        <div className="product-info">
          {infoModal ? (
            <>
              <div className="product-info__header">
                <div className="product-info__title">
                  <QuestionMark />
                  <span>
                    <Translate resourceString="packout.infopopup.title" />
                  </span>
                </div>
                <div>
                  <div
                    className="product-info__close"
                    onClick={() => {
                      dispatch<ProductActionTypes>({ type: 'PRODUCT/SET_INFO_MODAL_OPEN', payload: false });
                      dispatch<ProductActionTypes>({ type: 'PRODUCT/SET_INFO_MODAL', payload: null });
                    }}
                  >
                    <Translate resourceString="close" />
                    <CloseAlt />
                  </div>
                </div>
              </div>
              <div className="product-info__split">
                {infoModal.assets && (
                  <div className="product-info__carousel">
                    <div className="product-info__carousel-main">
                      <Slider ref={sliderBigRef} {...settingsBig} afterChange={c => setActiveIndex(c)}>
                        {infoModal.assets?.length > 0 &&
                          infoModal.assets.map((asset, i) => (
                            <div className="carousel-slide" key={`InfoPopup_${i}_${asset.assetNumber}`}>
                              <img
                                src={ProductHelper.transformImageUrl(asset.assetUrl, 500, true)}
                                alt={asset.assetNumber}
                                onClick={() => sliderBigRef.current && sliderBigRef.current.slickGoTo(i)}
                              />
                            </div>
                          ))}
                      </Slider>
                    </div>
                    <div className="product-info__carousel-thumbnails">
                      <div className="product-info__carousel-arrow" onClick={() => sliderBigRef.current && sliderBigRef.current.slickPrev()}>
                        <Chevron />
                      </div>
                      <div className="product-info__thumbnails-wrapper">
                        {infoModal.assets?.length > 0 &&
                          infoModal.assets.map((asset, i) => (
                            <div className="thumbnail" data-active={activeIndex === i} key={`InfoPopup_${i}_${asset.assetNumber}`}>
                              <img
                                src={ProductHelper.transformImageUrl(asset.assetUrl, 100, true)}
                                alt={asset.assetNumber}
                                onClick={() => sliderBigRef.current && sliderBigRef.current.slickGoTo(i)}
                              />
                            </div>
                          ))}
                      </div>
                      <div className="product-info__carousel-arrow" onClick={() => sliderBigRef.current && sliderBigRef.current.slickNext()}>
                        <Chevron />
                      </div>
                    </div>
                  </div>
                )}
                <div className="product-info__main" data-no-carousel={!infoModal.assets}>
                  <div className="product-info__main-header">
                    {infoModal.isNew && (
                      <div className="product-info__main-header-new">
                        <Translate resourceString="packout.new" />
                      </div>
                    )}
                    <Packout />
                  </div>
                  <div className="product-info__main-title-and-model">
                    <div className="product-info__main-title">{infoModal.productName}</div>
                  </div>
                  <div className="product-info__main-features">
                    <div className="product-info__main-table">
                      <table>
                        <tbody>
                          {infoModal.tableData.map(data => (
                            <tr key={`InfoPopup_${data.label}`}>
                              <td>
                                <Translate resourceString={data.label} />
                              </td>
                              <td>{modifyTableDataByKey(data)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {canDropFront && (
                      <div className="product-info__main-add" onClick={() => handleAdd(false)}>
                        <Add />
                        <span>
                          <Translate resourceString={hasDoubleBase ? 'packout.infopopup.product.add-to-front' : 'packout.infopopup.product.add'} />
                        </span>
                      </div>
                    )}
                    {hasDoubleBase && canDropBack && (
                      <>
                        {canDropFront && <br />}
                        <div className="product-info__main-add" onClick={() => handleAdd(true)}>
                          <Add />
                          <span>
                            <Translate resourceString="packout.infopopup.product.add-to-back" />
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {infoModal.videoUrl && (
                <div className="product-info__installation-guide">
                  <h1>
                    <Translate resourceString="packout.infopopup.video.title" />
                  </h1>
                  <div className="product-info__installation-guide-video" data-play-button onClick={() => setPlayingVideo(!playingVideo)}>
                    {!playingVideo && <PlayButton />}
                    <video src={infoModal.videoUrl} autoPlay={playingVideo} loop ref={videoRef} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <Loader showContainer={false} />
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  addProduct: (
    product: Products.StackProduct,
    categories: Products.ProductCategory<StackProduct>[],
    position?: Builder.LayerPosition,
    preserveSession?: boolean | undefined,
  ) => InventoryActionCreators.addProduct(product, categories, undefined, position, preserveSession),
};

export default connect(null, mapDispatchToProps)(InfoPopup);
